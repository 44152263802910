import {
  Box,
  Drawer,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ClaimRequest from "./ClaimRequest";
import ClaimList from "./ClaimList";
import CloseIcon from "@mui/icons-material/Close";
import {
  rejectClaimedItem,
  resolveClaimedItem,
} from "../../services/aggregation";
import { enqueueSnackbar } from "notistack";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../constants";

const ClaimDetails = (props: any) => {
  const { showClaimListing, handleClose, claimDetails } = props;
  const [selectedClaim, setSelectedClaim] = useState<any | null>(null);
  const [showClaimDetails, setShowClaimDetails] = useState(false);
  const [showConfirmDialog, setShowConfirmDialog] = useState(false);
  const [claimToAction, setClaimToAction] = useState<any | null>(null);
  const [actionType, setActionType] = useState<"resolve" | "reject" | null>(
    null
  );
  const [claims, setClaims] = useState(claimDetails);

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  useEffect(() => {
    setClaims(claimDetails);
  }, [claimDetails]);

  const handleSelectUser = (user: any) => {
    const claim = claims.find((c: any) => c._userClaimBy._id === user._id);
    setSelectedClaim(claim);
    setShowClaimDetails(true);
  };

  const handleCloseClaimDetails = () => {
    setShowClaimDetails(false);
    setSelectedClaim(null);
  };

  const handleResolve = async (claim: any) => {
    setClaimToAction(claim);
    setActionType("resolve");
    setShowConfirmDialog(true);
  };

  const handleReject = async (claim: any) => {
    setClaimToAction(claim);
    setActionType("reject");
    setShowConfirmDialog(true);
  };

  const handleConfirmAction = async () => {
    try {
      if (actionType === "resolve") {
        await resolveClaimedItem(
          claimToAction._item,
          claimToAction._userClaimBy._id
        );
        enqueueSnackbar(staticTexts.claimRequestResolvedSuccessfully, {
          variant: "success",
        });
        window.location.reload();
      } else if (actionType === "reject") {
        await rejectClaimedItem(claimToAction._id);
        enqueueSnackbar(staticTexts.claimRequestRejectedSuccessfully, {
          variant: "success",
        });
        setClaims(
          claims.filter((claim: any) => claim._id !== claimToAction._id)
        );
      }

      setShowConfirmDialog(false);
      handleCloseClaimDetails();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      setClaimToAction(null);
      setActionType(null);
    }
  };

  const handleCancelAction = () => {
    setShowConfirmDialog(false);
    setClaimToAction(null);
    setActionType(null);
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={showClaimListing}
        onClose={handleClose}
        sx={{
          overflowY: "scroll",
          "& .MuiDrawer-paper": {
            width: "40%",
            minWidth: "40%",
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Box
          sx={{ width: "100%", height: "100vh" }}
          display="flex"
          role="presentation"
          padding="0px 0px 0px 16px"
        >
          <ClaimList
            claims={claims}
            onSelectUser={handleSelectUser}
            className="h-full"
            handleListItemClick={() => {}}
          />
        </Box>
      </Drawer>

      <Drawer
        anchor={"right"}
        open={showClaimDetails}
        onClose={handleCloseClaimDetails}
        sx={{
          overflowY: "scroll",
          "& .MuiDrawer-paper": {
            width: "40%",
          },
        }}
      >
        <IconButton
          onClick={handleCloseClaimDetails}
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        {selectedClaim && (
          <ClaimRequest
            claim={selectedClaim}
            onResolve={handleResolve}
            onReject={handleReject}
          />
        )}
      </Drawer>

      <Dialog open={showConfirmDialog} onClose={handleCancelAction}>
        <DialogTitle>
          {actionType === "resolve"
            ? staticTexts.resolveClaimRequest
            : staticTexts.rejectClaimRequest}
        </DialogTitle>
        <DialogContent>
          {actionType === "resolve"
            ? staticTexts.areYouSureToResolveClaimRequest
            : staticTexts.areYouSureToRejectClaimRequest}
        </DialogContent>
        <DialogActions>
          <Button
            onClick={handleCancelAction}
            color="primary"
            variant="outlined"
          >
            {staticTexts.cancel}
          </Button>
          <Button
            onClick={handleConfirmAction}
            color={actionType === "resolve" ? "primary" : "error"}
            variant="outlined"
          >
            {staticTexts.confirm}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default ClaimDetails;
