import { Switch } from "@mui/material";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { withPermission } from "../../components/hoc";
import { useSelector, useDispatch } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { updateAdminProfile, updateConfiguration } from "../../services/admin";

const Configuration = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const dispatch = useDispatch();

  const profile = useSelector((state: any) => state.profile);

  const userPermissions = useSelector((state: any) => state.permissions);

  const isEditIconDisabled = !userPermissions.includes(
    PERMISSIONS.EDIT_CONFIGURATION
  );

  const handleToggle = async (e: any) => {
    const newValue = e.target.checked;
    await updateConfiguration({
      value: newValue,
    });

    dispatch({
      type: "SET_PROFILE_DETAILS",
      payload: {
        ...profile,
        isSignupNeededVerification: newValue,
      },
    });

    enqueueSnackbar(staticTexts.configurationUpdatedSuccessfully, {
      variant: "success",
    });
  };

  return (
    <div>
      {staticTexts.adminVerification}
      <Switch
        checked={profile.isSignupNeededVerification}
        onChange={handleToggle}
        disabled={isEditIconDisabled}
      />
    </div>
  );
};

export default withPermission(Configuration, PERMISSIONS.VIEW_CONFIGURATION);
