import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { withPermission } from "../../components/hoc";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { getRoles } from "../../services/access-control";
import { enqueueSnackbar } from "notistack";
import { useDispatch, useSelector } from "react-redux";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const AccessControl = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const redirectionLinks = ["agents", "roles"];
  const tabs = [
    { label: staticTexts.agents, index: 0 },
    { label: staticTexts.roles, index: 1 },
  ];

  const fetchRoles = async () => {
    try {
      const roles = (await getRoles({})) as any;
      dispatch({
        type: "SET_ROLE_DETAILS",
        payload: roles.roles,
      });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    fetchRoles();
  }, [dispatch]);

  useEffect(() => {
    setValue(redirectionLinks.indexOf(location.pathname.split("/")[2]));
  }, [location.pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              className="tabs-space"
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default withPermission(AccessControl, PERMISSIONS.VIEW_ACCESS_CONTROL);
