import { reducer } from '../reducers';

import { configureStore, createImmutableStateInvariantMiddleware } from '@reduxjs/toolkit';

const store = configureStore({
  reducer,
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(createImmutableStateInvariantMiddleware()),
});

export default store;
