import React from "react";
import { Typography, Avatar, Box, useTheme } from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import PhoneIcon from "@mui/icons-material/Phone";
import { User } from "../../../interfaces";
import { formatPhone } from "../../../services/utils";

interface UserInfoProps {
  user: User;
  title: string;
}

const UserInfo: React.FC<UserInfoProps> = ({ user, title }) => {
  const theme = useTheme();

  return (
    <Box
      sx={{
        backgroundColor: theme.palette.background.paper,
        padding: 2,
        borderRadius: 2,
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
        display: "flex",
        flexDirection: "column",
        gap: 2,
      }}
    >
      <Typography
        variant="h6"
        color={theme.palette.text.primary}
        textAlign="center"
        fontWeight="bold"
      >
        {title}
      </Typography>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 2,
        }}
      >
        {user?.profile ? (
          <Avatar
            src={user.profile}
            alt="Profile"
            sx={{
              width: 37,
              height: 37,
              border: `2px solid ${theme.palette.primary.main}`,
            }}
          />
        ) : (
          <AccountCircleIcon
            sx={{
              width: 45,
              height: 45,
              color: theme.palette.primary.main,
            }}
          />
        )}
        <Box>
          <Typography
            variant="subtitle1"
            color={theme.palette.text.primary}
            fontWeight="medium"
          >
            {user.name}
          </Typography>
          <Typography
            variant="body2"
            color={theme.palette.text.secondary}
            sx={{ wordBreak: "break-word" }}
          >
            {user.email}
          </Typography>
        </Box>
      </Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          gap: 1,
          backgroundColor: theme.palette.background.default,
          padding: 1,
          borderRadius: 1,
          boxShadow: "0px 1px 4px rgba(0, 0, 0, 0.1)",
        }}
      >
        {user.phone && (
          <>
            <PhoneIcon sx={{ color: theme.palette.primary.main }} />
            <Typography
              variant="body2"
              color={theme.palette.text.secondary}
              sx={{ wordBreak: "break-word" }}
            >
              {formatPhone(user.phone.prefix, user.phone.number)}
            </Typography>
          </>
        )}
      </Box>
    </Box>
  );
};

export default UserInfo;
