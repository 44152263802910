import { ChangeEvent, useEffect, useReducer, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Header, CustomTable, WarningDialog } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from "./manage";
import { getStaticTexts, PERMISSIONS } from "../../../constants";
import {
  deleteRoles,
  getRoles,
  updateRoleStatus,
} from "../../../services/access-control";
import { enqueueSnackbar } from "notistack";
import { handleCharacterLimit } from "../../../services/common";
import Loader from "../../../components/Loader";
import { useSelector } from "react-redux";

interface state {
  deleteWarning: boolean;
  multiDeleteWarning: boolean;
  _role: string;
  list: {
    all: JSX.Element;
    id: number;
    _id: string;
    name: JSX.Element;
    description: string;
    status: JSX.Element;
    action: JSX.Element;
  }[];
  selectAll: string[];
  loading: boolean;
  manage: {
    isOpen: boolean;
    type: "new" | "view" | "edit";
    role: any;
  };
  statusFilter: string;
  totalPages: number;
  searchQuery: string;
  page: number;
}

const Roles = () => {
  const rolesReducer = (state: state, action: any): state => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
        case 'UPDATE_LIST':
          const roleIds = action.payload;
          const filteredList = state.list.filter((role: any) => !(roleIds || []).includes(role._id));
          return {
            ...state,
            list: filteredList,
          };
        case "UPDATE_CHECKBOX": {
          const { id, isChecked } = action.payload;
    
          // Update the `selectAll` array
          const updatedSelectAll = isChecked
            ? [...state.selectAll, id]
            : state.selectAll.filter((ele) => ele !== id);
    
          // Update the list to reflect the checkbox state
          const updatedList = state.list.map((roles: any) => ({
            ...roles,
            all: (
              <Checkbox
                onChange={(e) => handleMultipleSelect(e, state.selectAll, roles._id)}
                checked={updatedSelectAll.includes(roles._id)}
              />
            ),
          }));
          return {
            ...state,
            selectAll: updatedSelectAll,
            list: updatedList,
          };
        }
        case 'TOGGLE_STATUS':
          const { id, status } = action.payload;
          const updatedList = state.list.map((user: any) =>
            user._id === id
              ? {
                  ...user,
                  status: (
                    <Switch
                      checked={status === "ACTIVE"}
                      disabled={isEditIconDisabled}
                      onChange={(e) => {
                        handleStatusChange(
                          user._id,
                          e.target.checked ? "ACTIVE" : "INACTIVE",
                        );
                      }}
                    />
                  ),
                }
              : user
          );
          return {
            ...state,
            list: updatedList,
          };
        
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const userPermissions = useSelector((state: any) => state.permissions);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(
    PERMISSIONS.EDIT_ACCESS_CONTROL
  );

  const isDeleteIconDisabled = !userPermissions.includes(
    PERMISSIONS.DELETE_ACCESS_CONTROL
  );

  const fetchRoles = async (searchQuery: string = "", page: number = 1) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        text: searchQuery,
      };

      if (state.statusFilter && state.statusFilter !== "ALL") {
        params.status = state.statusFilter;
      }

      const roles = (await getRoles(params)) as any;

      if (!roles || !roles.roles?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: roles.meta.totalPages },
      });

      const list = roles.roles.map((role: any, index: number) =>
        createRow(
          state.selectAll,
          role,
          index,
          handleManage,
          handleWarningDialog,
          handleMultipleSelect,
          page
        )
      );

      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const [state, dispatch] = useReducer(rolesReducer, {
    deleteWarning: false,
    multiDeleteWarning: false,
    _role: "",
    list: [],
    selectAll: [],
    loading: true,
    manage: {
      isOpen: false,
      type: "new",
      role: null,
    },
    searchQuery: "",
    page: 1,
    statusFilter: "",
    totalPages: 1,
  });

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchRoles(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchRoles(state.searchQuery, page);
  };

  useEffect(() => {
    fetchRoles(state.searchQuery, state.page);
  }, [state.statusFilter]);

  const handleManage = (type: "new" | "edit" | "view", role: any = null) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: !state.manage.isOpen,
          role,
        },
      },
    });
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      await updateRoleStatus(id, { status });
      dispatch({ type: "UPDATE_STATUS", _id: id, status });
      enqueueSnackbar(staticTexts.roleStatusUpdatedSuccessfully, {
        variant: "success",
      });
     dispatch({ type: 'TOGGLE_STATUS', payload: { id, status } });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      dispatch({ type: "UPDATE_FIELDS", payload:{loading : false}})
    }
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const allIds = state.list.map((item: any) => item._id);

    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        selectAll: checked ? allIds : [],
      },
    });

    const updatedList = state.list.map(
      (item: { _id: string; [key: string]: any }) => ({
        ...item,
        all: (
          <Checkbox
            onChange={(e) =>
              handleMultipleSelect(e, checked ? allIds : [], item._id)
            }
            checked={checked}
          />
        ),
      })
    );

    dispatch({ type: "LOADED", list: updatedList });
  };

  const handleMultipleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    selectAll: string[],
    id: string
  ) => {
    const isChecked = e.target.checked;
    dispatch({
      type: "UPDATE_CHECKBOX",
      payload: { id, isChecked },
    });
  };

  const handleDeleteRole = async (roleIds: Array<string>) => {
    try {
      await deleteRoles(roleIds);
      enqueueSnackbar(staticTexts.roleDeletedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: 'UPDATE_LIST', payload: roleIds });
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDelete = async (roleId: string) => {
    await handleDeleteRole([roleId]);
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: false,
        _role: "",
      },
    });
  };

  const handleMultiDelete = async (agentIds: Array<string>) => {
    if (agentIds.length) {
      await handleDeleteRole(agentIds);
      dispatch({
        type: "UPDATE_FIELDS",
        payload: {
          multiDeleteWarning: false,
        },
      });
    }
  };

  const handleWarningDialog = (_role: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: !state.deleteWarning,
        _role,
      },
    });
  };

  const handleMutlipleWarningDialog = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        multiDeleteWarning: !state.multiDeleteWarning,
        selectAll: state.selectAll,
      },
    });
  };

  const statusFilter = [
    {
      label: staticTexts.all,
      value: "ALL",
    },
    {
      label: staticTexts.active,
      value: "ACTIVE",
    },
    {
      label: staticTexts.inactive,
      value: "INACTIVE",
    },
  ];

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={
            state.selectAll?.length > 0 &&
            state.list?.length === state.selectAll?.length
          }
          indeterminate={
            state.selectAll?.length > 0 &&
            state.list?.length !== state.selectAll?.length
          }
        />
      ),
    },
    {
      id: "id",
      label: staticTexts.sNo,
    },
    {
      id: "name",
      label: staticTexts.name,
    },
    {
      id: "description",
      label: staticTexts.description,
    },
    {
      id: "status",
      label: staticTexts.status,
    },
    {
      id: "action",
      label: staticTexts.action,
    },
  ];

  const createRow = (
    selectAll: string[],
    role: any,
    index: number,
    onEdit: any,
    onDelete: any,
    onMultiDelete: any,
    page: number
  ) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, role._id)}
        checked={selectAll.includes(role._id)}
      />
    );

    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => onEdit("view", role)}
        sx={{ textTransform: "capitalize" }}
      >
        {role.name}
      </Typography>
    );

    // const description = (
    //   <Typography
    //     className="cursor-pointer"
    //     color="primary"
    //     variant="body1"
    //     onClick={() => onEdit("view", role)}
    //     sx={{ textTransform: "capitalize" }}
    //   >
    //     {role.description}
    //   </Typography>
    // );

    const status = (
      <Switch
        checked={role.status === "ACTIVE"}
        disabled={isEditIconDisabled}
        onChange={(e) => {
          handleStatusChange(
            role._id,
            e.target.checked ? "ACTIVE" : "INACTIVE"
          );
        }}
      />
    );

    const action = (
      <>
        <Tooltip title={staticTexts.edit}>
          <IconButton
            onClick={() => onEdit("edit", role)}
            color="primary"
            disabled={isEditIconDisabled}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title={staticTexts.delete}>
          <IconButton
            onClick={() => onDelete(role._id)}
            color="error"
            disabled={isDeleteIconDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: (page - 1) * itemsPerPage + index + 1,
      _id: role._id,
      name,
      description: handleCharacterLimit(role.description, 30),
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      {state.loading && <Loader />}
      <Header
        isDeleteIconDisabled={isDeleteIconDisabled}
        isEditIconDisabled={isEditIconDisabled}
        searchPlaceholder={staticTexts.searchByRoleName}
        onSearch={handleSearch}
        onBtnClick={() => handleManage("new")}
        onDelete={(e) => handleMutlipleWarningDialog()}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
        btnText={staticTexts.createNewRole}
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === "") {
                return staticTexts.statusFilter;
              }
              if (selected === "ALL") {
                return staticTexts.all;
              }
              return selected === "ALL"
                ? staticTexts.all
                : selected === "ACTIVE"
                ? staticTexts.active
                : staticTexts.inactive;
            }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_FIELDS",
                payload: { statusFilter: e.target.value, page: 1 },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.addRolesToSeeDataHere}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        role={state.manage.role}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleWarningDialog(state._role || "")}
        onConfirm={() => handleDelete(state._role)}
        title={staticTexts.deleteRole}
        description={staticTexts.areYouSureYouWantToDeleteThisRole}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMutlipleWarningDialog()}
        onConfirm={() => handleMultiDelete(state.selectAll)}
        title={staticTexts.deleteRoles}
        description={staticTexts.areYouSureYouWantToDeleteThisRoles}
      />
    </>
  );
};

export default Roles;
