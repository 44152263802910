import "./style.scss";
import { useEffect, useState } from "react";
import {
  Box,
  FormControl,
  Grid,
  Grid2,
  MenuItem,
  Select,
  Typography,
  useTheme,
} from "@mui/material";
import Tag from "./components/tags";
import { BarGraph, CustomTable } from "../../components";
import { Link } from "react-router-dom";
import { withPermission } from "../../components/hoc";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { getAnalytics, getCustomerAnalytics } from "../../services/dashboard";
import { enqueueSnackbar } from "notistack";
import { getCustomers } from "../../services/common";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
const Dashboard = () => {
  const [state, setState] = useState({ search: "LAST_WEEK" });

  const [loading, setLoading] = useState(true);

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const [totalLostItemsCount, setTotalLostItemsCount] = useState(0);
  const [totalFoundItemsCount, setTotalFoundItemsCount] = useState(0);
  const [openLostItemsCount, setOpenLostItemsCount] = useState(0);
  const [openFoundItemsCount, setOpenFoundItemsCount] = useState(0);
  const [totalNormalCustomersCount, setTotalNormalCustomersCount] = useState(0);
  const [totalBusinessCustomersCount, setTotalBusinessCustomersCount] =
    useState(0);
  const [lastMonthLostItemsCount, setLastMonthLostItemsCount] = useState(0);
  const [lastMonthFoundItemsCount, setLastMonthFoundItemsCount] = useState(0);
  const [totalResolvedItemsCount, setTotalResolvedItemsCount] = useState(0);
  const [totalAgentsCount, setTotalAgentsCount] = useState(0);

  const theme = useTheme();

  const [rows, setRows] = useState([]);

  const handleTagsChange = async (name: string) => {
    setState((prevState) => {
      return {
        ...prevState,
        selectedTag: name,
      };
    });
  };

  const fetchCustomers = async () => {
    try {
      const user = (await getCustomers({ limit: 5 })) as any;
      const rows = user.customers?.map((customer: any, index: number) => ({
        id: index + 1,
        profile: customer?.profile ? (
          <img
            src={customer.profile}
            alt="Profile"
            style={{
              width: 30,
              height: 30,
              objectFit: "cover",
              borderRadius: "50%",
              border: `2px solid ${theme.palette.primary.main}`,
            }}
          />
        ) : (
          <AccountCircleIcon
            sx={{
              width: 37,
              height: 37,
              color: theme.palette.primary.main,
              marginRight: "1px",
            }}
          />
        ),
        name: customer?.name,
        email: customer?.email,
        phone:
        language === 'ar'
          ? '+' + customer?.phone?.prefix + ' ' + customer?.phone?.number.split('').reverse().join('')
          : '+' + customer?.phone?.prefix+ ' ' + customer?.phone?.number,
      }));
      setRows(rows);
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const processAnalyticsData = (analytics: any[], type: string) => {
    if (!analytics)
      return Array(
        type === "LAST_MONTH" ? 31 : type === "LAST_YEAR" ? 12 : 7
      ).fill(0);

    if (type === "LAST_WEEK") {
      const weekData = Array(7).fill(0);
      analytics.forEach((item) => {
        const date = new Date(item.date);
        const dayIndex = date.getDay();
        const adjustedIndex = dayIndex === 0 ? 6 : dayIndex - 1; // Convert Sunday (0) to 6, and shift others back by 1
        weekData[adjustedIndex] = Math.round(item.count);
      });
      return weekData;
    } else if (type === "LAST_MONTH") {
      const monthData = Array(31).fill(0);
      analytics.forEach((item) => {
        const date = new Date(item.date);
        const dayOfMonth = date.getDate() - 1; // Arrays are 0-based
        monthData[dayOfMonth] = Math.round(item.count);
      });
      return monthData;
    } else if (type === "LAST_YEAR") {
      const yearData = Array(12).fill(0);
      analytics.forEach((item) => {
        const date = new Date(item.date);
        const month = date.getMonth();
        yearData[month] = Math.round(item.count);
      });
      return yearData;
    }
    return Array(7).fill(0);
  };

  // const fetchCustomerAnalytics = async (type: string) => {
  //   try {
  //     const analytics = (await getCustomerAnalytics({ type })) as any;
  //     const processedData = processAnalyticsData(analytics, type);
  //     setCustomerCount(processedData);
  //   } catch (error: any) {
  //     setLoading(false);
  //     enqueueSnackbar(
  //       error.response.data.message ||
  //         error.message ||
  //         staticTexts.somethingWentWrong,
  //       {
  //         variant: "error",
  //       }
  //     );
  //   }
  // };

  const fetchAnalytics = async () => {
    try {
      const analytics = (await getAnalytics()) as any;
      setTotalLostItemsCount(analytics?.totalLostItemsCount);
      setTotalFoundItemsCount(analytics?.totalFoundItemsCount);
      setOpenLostItemsCount(analytics?.openLostItemsCount);
      setOpenFoundItemsCount(analytics?.openFoundItemsCount);
      setTotalNormalCustomersCount(analytics?.totalNormalCustomersCount);
      setTotalBusinessCustomersCount(analytics?.totalBusinessCustomersCount);
      setLastMonthLostItemsCount(analytics?.lastMonthLostItemsCount);
      setLastMonthFoundItemsCount(analytics?.lastMonthFoundItemsCount);
      setTotalResolvedItemsCount(analytics?.totalResolvedItemsCount);
      setTotalAgentsCount(analytics?.totalAgentsCount);
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    Promise.all([
      fetchAnalytics(),
      fetchCustomers(),
      // fetchCustomerAnalytics(state.search),
    ]).finally(() => {
      setLoading(false);
    });
  }, []);

  const columns = [
    {
      id: "id",
      label: staticTexts.sNo,
    },
    {
      id: "profile",
      label: staticTexts.profile,
    },
    {
      id: "name",
      label: staticTexts.name,
    },
    {
      id: "email",
      label: staticTexts.email,
    },
    {
      id: "phone",
      label: staticTexts.phone,
    },
  ];

  return (
    <div>
      {loading && <Loader />}
      <Box className="tags" marginBottom="30px">
        <Typography className="head" variant="body1" marginBottom="10px">
          {staticTexts.overview}
        </Typography>
        <Box
          className="overview-tabs"
          display="flex"
          flexWrap="wrap"
          border="1px solid #dee2e6"
        >
          <Box
            display="flex"
            width="100%"
            justifyContent="space-between"
            borderBottom="1px solid #dee2e6"
          >
            <Tag
              name={staticTexts.totalLostItems}
              select=""
              count={totalLostItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.totalFoundItems}
              select=""
              count={totalFoundItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.openLostItems}
              select=""
              count={openLostItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.openFoundItems}
              select=""
              count={openFoundItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.totalNormalCustomers}
              select=""
              count={totalNormalCustomersCount}
              onClick={handleTagsChange}
              style={{ borderRight: "none" }}
            />
          </Box>
          <Box display="flex" width="100%" justifyContent="space-between">
            <Tag
              name={staticTexts.totalBusinessCustomers}
              select=""
              count={totalBusinessCustomersCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.lastMonthLostItems}
              select=""
              count={lastMonthLostItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.lastMonthFoundItems}
              select=""
              count={lastMonthFoundItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.totalResolvedItems}
              select=""
              count={totalResolvedItemsCount}
              onClick={handleTagsChange}
            />
            <Tag
              name={staticTexts.totalAgents}
              select=""
              count={totalAgentsCount}
              onClick={handleTagsChange}
              style={{ borderRight: "none" }}
            />
          </Box>
        </Box>
      </Box>

      <Box
        className="center"
        marginBottom="18px"
        justifyContent="space-between"
      >
        <Typography className="recent-applications m-0" variant="h6">
          {staticTexts.recentCustomer}
        </Typography>
        <Link
          className="more-details underline-none"
          to="/customers/individual-users"
        >
          {staticTexts.moreDetails}
        </Link>
      </Box>
      <CustomTable columns={columns} rows={rows} height={"h-fit"} />
    </div>
  );
};

export default withPermission(Dashboard, PERMISSIONS.VIEW_DASHBOARD);
