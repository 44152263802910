import "./App.scss";
import "./assets/css/bootstrap/style.scss";
import { RouterProvider } from "react-router-dom";
import { ThemeProvider } from "@mui/material";
import theme from "./assets/theme";
import routes from "./routes";
import { enqueueSnackbar, SnackbarProvider } from "notistack";
import { getUserProfile } from "./services/admin";
import { useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getStaticTexts } from "./constants";
import { getCategories } from "./services/aggregation";
import createCache from '@emotion/cache';
import { CacheProvider } from '@emotion/react';
import rtlPlugin from 'stylis-plugin-rtl';
function App() {
  const token = localStorage.getItem("token");
  const dispatch = useDispatch();

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const direction = language === 'ar' ? 'rtl' : 'ltr';
  const staticTexts = getStaticTexts(language);

  const cacheRtl = useMemo(
    () =>
      createCache({
        key: 'muirtl',
        stylisPlugins: [rtlPlugin],
      }),
    []
  );


  const fetchCategories = async () => {
    try {
      const categories = (await getCategories({})) as any;

      if (!categories || !categories.categories) {
        dispatch({
          type: "SET_CATEGORY_DETAILS",
          payload: [{ _id: "ALL", name: staticTexts.all }],
        });
        return;
      }
      dispatch({
        type: "SET_CATEGORY_DETAILS",
        payload: [
          { _id: "ALL", name: staticTexts.all },
          ...categories?.categories,
        ],
      });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  const fetchUserProfile = async () => {
    if (token) {
      try {
        const user = (await getUserProfile()) as any;

        dispatch({
          type: "SET_PROFILE_DETAILS",
          payload: {
            name: user.name,
            email: user.email,
            profile: user.profile,
            isSignupNeededVerification: user.isSignupNeededVerification,
            phone: {
              prefix: user.phone.prefix,
              number: user.phone.number,
            },
            coverProfile: user.coverProfile,
            type: user.type,
            defaultLanguage: user.defaultLanguage,
          },
        });
        dispatch({
          type: "SET_USER_PERMISSIONS",
          payload: user.permissions,
        });

        if (user.permissions?.includes("category.read")) {
          fetchCategories();
        }
      } catch (error: any) {
        enqueueSnackbar(
          error.response || error.message || staticTexts.somethingWentWrong,
          {
            variant: "error",
          }
        );
      }
    }
  };

  useEffect(() => {
    if (token) {
      fetchUserProfile();
    }
  }, [token]);

  return (
    <ThemeProvider theme={theme}>
      <SnackbarProvider
        autoHideDuration={2000}
        maxSnack={1}
        anchorOrigin={{
          vertical: "top",
          horizontal: "right",
        }}
      >
        {/* <div dir={direction}>
        <RouterProvider router={routes} />
        </div>
        */}
          {direction === 'rtl' ? (
        <CacheProvider value={cacheRtl}>
           <div dir={direction}>
          <RouterProvider router={routes} />
          </div>
        </CacheProvider>
      ) : (
        <RouterProvider router={routes} />
      )}
      </SnackbarProvider>
    </ThemeProvider>
  );
}

export default App;
