import { combineReducers } from "redux";
import { permissionReducer } from "./permissions";
import { profileReducer } from "./profile";
import { categoryReducer } from "./category";


export const reducer = combineReducers({
  permissions: permissionReducer,
  profile: profileReducer,
  category: categoryReducer
});
