import "./style.scss";
import { useEffect, useMemo, useState } from "react";
import {
  Divider,
  List,
  ListItemIcon,
  ListItemText,
  Avatar,
  Typography,
} from "@mui/material";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import Logo from "../assets/images/logo.png";

import {
  Dashboard as DashboardIcon,
  ArrowBackIos as ArrowBackIosIcon,
  SupervisedUserCircle as SupervisedUserCircleIcon,
  ManageAccounts as ManageAccountsIcon,
  Settings as SettingsIcon,
  ExpandLess as ExpandLessIcon,
  ExitToApp as ExitToAppIcon,
  Category,
  QuestionAnswer,
  Chat,
  HelpOutline,
  Security,
  FlagCircle as ReportIcon,
} from "@mui/icons-material";
import ListItem from "./components/layout-list-item";
import { WarningDialog } from "../components";
import { getStaticTexts, PERMISSIONS } from "../constants";
import { PermissionGuard } from "../guards";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";
import { logout } from "../services/common";
import rtlPlugin from 'stylis-plugin-rtl';


const Layout = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const direction = language === 'ar' ? "rtl" : "ltr";

  const { pathname } = useLocation();
  const navigate = useNavigate();

  const [state, setState] = useState({
    collapse: false,
    menuOption: false,
    logoutWarning: false,
  });

  const userPermissions = useSelector((state: any) => state.permissions);

  // console.log(
  //   "checkpppppp",
  //   userPermissions.includes(PERMISSIONS.VIEW_BUSINESS_CUSTOMER)
  // );

  const userProfile = useSelector((state: any) => state.profile);

  let title = "";

  const name = pathname.split("/")[1];

  switch (name) {
    case "dashboard":
      title = staticTexts.dashboard;
      break;
    case "customers":
      title = staticTexts.customers;
      break;
    case "lost-found-items":
      title = staticTexts["lost-found-items"];
      break;
    case "settings":
      title = staticTexts.settings;
      break;
    case "items-category":
      title = staticTexts["items-category"];
      break;
    case "questionnaire":
      title = staticTexts.questionnaire;
      break;
    case "chat":
      title = staticTexts.chat;
      break;
    case "access-control":
      title = staticTexts["access-control"];
      break;
    case "report":
      title = staticTexts.reports;
      break;
    case "configurations":
      title = staticTexts.configurations;
      break;
    default:
      title = "";
  }

  useEffect(() => {
    if (pathname === "/") {
      navigate("/dashboard");
    }
  }, [pathname, navigate]);

  const changeSidebarSize = () => {
    const leftSide = document.getElementById("left") as HTMLElement;
    const rightSide = document.getElementById("right") as HTMLElement;
    const logo = document.getElementById("logo") as HTMLElement;
    const collapseArrow = document.getElementById("collapse") as HTMLElement;
    const collapseText = document.getElementsByClassName(
      "collapse-text"
    ) as HTMLCollectionOf<HTMLElement>;
    const collapseListItem = document.getElementsByClassName(
      "collapse-ListItem"
    ) as HTMLCollectionOf<HTMLElement>;
    const nav_items_box = document.getElementsByClassName(
      "nav-items-box"
    ) as HTMLCollectionOf<HTMLElement>;

    const isCollapsed = !state.collapse;

    leftSide.style.width = isCollapsed ? "60px" : "200px";
    rightSide.style.marginInlineStart = isCollapsed
      ? "calc(60px + 16px)"
      : "calc(200px + 16px)";
    logo.style.display = isCollapsed ? "none" : "flex";
    collapseArrow.style.margin = isCollapsed ? "20px 0px" : "0px";
    collapseArrow.style.width = isCollapsed ? "40px" : "45px";
    collapseArrow.style.height = isCollapsed ? "40px" : "37px";
    collapseArrow.style.transform = isCollapsed
      ? "rotate(180deg)"
      : "rotate(0deg)";
    nav_items_box[0].style.height = isCollapsed
      ? "calc(100% - 95px)"
      : "calc(100% - 110px)";

    Array.from(collapseText).forEach((item: HTMLElement) => {
      item.style.display = isCollapsed ? "none" : "initial";
    });

    Array.from(collapseListItem).forEach((item: HTMLElement) => {
      item.style.paddingInlineStart = "18px";
    });

    setState((prevState) => ({
      ...prevState,
      collapse: isCollapsed,
    }));
  };

  const handleProfileMenu = () => {
    setState((prevState) => ({
      ...prevState,
      menuOption: !prevState.menuOption,
    }));
  };

  const handleLogoutConfirm = async () => {
    try {
      await logout();
      localStorage.removeItem("token");
      enqueueSnackbar(staticTexts.logoutSuccessfully, { variant: "success" });
      navigate("/login");
    } catch (error) {
      enqueueSnackbar(staticTexts.somethingWentWrong, { variant: "error" });
    }
    setState((prev) => ({
      ...prev,
      logoutWarning: false,
    }));
  };

  const handleLogoutCancel = () => {
    setState((prev) => ({
      ...prev,
      logoutWarning: false,
    }));
  };

  const navItems = [
    {
      label: staticTexts.dashboard,
      navigateLink: "/dashboard",
      Icon: DashboardIcon,
      requiredPermission: PERMISSIONS.VIEW_DASHBOARD,
    },
    {
      label: staticTexts.customers,
      navigateLink: userPermissions.includes(
        PERMISSIONS.VIEW_INDIVIDUAL_CUSTOMER
      )
        ? "/customers/individual-users"
        : userPermissions.includes(PERMISSIONS.VIEW_BUSINESS_CUSTOMER)
        ? "/customers/business-users"
        : "",
      Icon: SupervisedUserCircleIcon,
      requiredPermission: userPermissions.includes(
        PERMISSIONS.VIEW_INDIVIDUAL_CUSTOMER
      )
        ? PERMISSIONS.VIEW_INDIVIDUAL_CUSTOMER
        : PERMISSIONS.VIEW_BUSINESS_CUSTOMER,
    },
    // {
    //   label: "Customers",
    //   navigateLink: "/customers/business-users",
    //   Icon: SupervisedUserCircleIcon,
    //   requiredPermission: PERMISSIONS.VIEW_BUSINESS_CUSTOMER,
    // },
    {
      label: staticTexts.category,
      navigateLink: "/items-category",
      Icon: Category,
      requiredPermission: PERMISSIONS.VIEW_ITEMS_CATEGORY,
    },
    {
      label: staticTexts.questionnaire,
      navigateLink: "/questionnaire",
      Icon: QuestionAnswer,
      requiredPermission: PERMISSIONS.VIEW_QUESTIONNAIRE,
    },
    {
      label: staticTexts.chat,
      navigateLink: "/chat",
      Icon: Chat,
      requiredPermission: PERMISSIONS.VIEW_CHAT,
    },
    {
      label: staticTexts["lost/FoundItems"],
      navigateLink: userPermissions.includes(PERMISSIONS.VIEW_LOST_ITEMS)
        ? "/lost-found-items/lost-items"
        : userPermissions.includes(PERMISSIONS.VIEW_FOUND_ITEMS)
        ? "/lost-found-items/found-items"
        : userPermissions.includes(PERMISSIONS.VIEW_RESOLVED_ITEMS)
        ? "/lost-found-items/resolved-items"
        : "",
      Icon: HelpOutline,
      requiredPermission: userPermissions.includes(PERMISSIONS.VIEW_LOST_ITEMS)
        ? PERMISSIONS.VIEW_LOST_ITEMS
        : userPermissions.includes(PERMISSIONS.VIEW_FOUND_ITEMS)
        ? PERMISSIONS.VIEW_FOUND_ITEMS
        : PERMISSIONS.VIEW_RESOLVED_ITEMS,
    },
    // {
    //   label: "Lost/Found Items",
    //   navigateLink: "/lost-found-items/found-items",
    //   Icon: HelpOutline,
    //   requiredPermission: PERMISSIONS.VIEW_FOUND_ITEMS,
    // },
    // {
    //   label: "Lost/Found Items",
    //   navigateLink: "/lost-found-items/resolved-items",
    //   Icon: HelpOutline,
    //   requiredPermission: PERMISSIONS.VIEW_RESOLVED_ITEMS,
    // },
    {
      label: staticTexts.accessControl,
      navigateLink: "/access-control/agents",
      Icon: Security,
      requiredPermission: PERMISSIONS.VIEW_ACCESS_CONTROL,
    },
    {
      label: staticTexts.reports,
      navigateLink: "/report",
      Icon: ReportIcon,
      requiredPermission: PERMISSIONS.VIEW_REPORT,
    },
    {
      label: staticTexts.configurations,
      navigateLink: "/configurations",
      Icon: ManageAccountsIcon,
      requiredPermission: PERMISSIONS.VIEW_CONFIGURATION,
    },
  ];

  return (
    <>
      {/* left sidebar  */}
      <div id="left" className="left-side-nav" style={direction === 'rtl' ? { right: 0 } : { left: 0 }}>
        <div className="center">
          <div id="logo" className="logo-box">
            <img src={Logo} alt="logo" />
          </div>
          <div
            id="collapse"
            className="collapse-arrow"
            onClick={changeSidebarSize}
          >
            <ArrowBackIosIcon className="collapse-arrow-icon w-100 h-100" />
          </div>
        </div>

        <div className="nav-items-box">
          {/* navigation buttons  */}
          <div style={{ overflowY: "auto", overflowX: "hidden" }}>
            <List>
              {navItems.map((item, index) => {
                const { label, navigateLink, Icon, requiredPermission } = item;
                return (
                  <PermissionGuard key={index} permission={requiredPermission}>
                    <ListItem
                      navigateLink={navigateLink}
                      label={label}
                      Icon={Icon}
                    />
                  </PermissionGuard>
                );
              })}
            </List>
          </div>

          {/* profile buttons */}
          <div>
            <Divider className="divider-color" />
            <div>
              <div
                className={`profile-transition ${
                  state.menuOption ? "margin-top-30" : "profile-buttons-hide"
                }`}
              >
                <div
                  className="profileButtonWrap"
                  id="setting-icon"
                  onClick={() =>
                   navigate('/settings/profile')
                  }
                >
                  <ListItemIcon className="navIcons">
                    <SettingsIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="collapse-text"
                    primary={staticTexts.settings}
                    classes={{ primary: "listItem-text" }}
                  />
                </div>

                <div
                  className="profileButtonWrap"
                  id="logout-icon"
                  onClick={() =>
                    setState((prev) => ({
                      ...prev,
                      logoutWarning: !prev.logoutWarning,
                    }))
                  }
                >
                  <ListItemIcon className="navIcons">
                    <ExitToAppIcon />
                  </ListItemIcon>
                  <ListItemText
                    className="collapse-text"
                    primary={staticTexts.logout}
                    classes={{ primary: "listItem-text" }}
                  />
                </div>
              </div>

              <div
                id="profile"
                className={
                  state.menuOption
                    ? "rotate-icon-down active-profile profile"
                    : "rotate-icon-up profile"
                }
                onClick={handleProfileMenu}
              >
                <div className="profile-name-box">
                  <Avatar className="profile-avatar" src={userProfile.profile}>
                    {userProfile.name ? userProfile.name.charAt(0) : ""}
                  </Avatar>
                  <div className="name collapse-text">{userProfile.name}</div>
                </div>
                <ExpandLessIcon className="collapse-text" />
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* right side content  */}
      <div id="right" className="right-side-content">
        <div className="top-nav">
          <Typography variant="h5">{title}</Typography>
        </div>
        <Outlet />
      </div>

      {/* Logout warning  */}
      <WarningDialog
        isOpen={state.logoutWarning}
        onClose={handleLogoutCancel}
        onConfirm={handleLogoutConfirm}
        title={staticTexts.logout}
        description={staticTexts.areYouSureDoYouWantToLogout}
      />
    </>
  );
};

export default Layout;
