import { FC, useReducer } from "react";
import { WarningDialog } from "../../../../components";
import { TextField, Typography } from "@mui/material";
import { getStaticTexts } from "../../../../constants";
import { useSelector } from "react-redux";

interface props {
  isOpen: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  userId: string;
  updateStatusCustomer: (
    userId: string,
    status: string,
    comment?: string
  ) => void;
}

const RejectApproval: FC<props> = ({
  isOpen = true,
  onClose,
  userId,
  updateStatusCustomer,
}) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const approveUserReducee = (state: any, action: any) => {
    switch (action.type) {
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(approveUserReducee, {
    showRejectButton: true,
    rejectRequest: false,
    comment: "",
  });

  const onReject = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { rejectRequest: true, showRejectButton: false },
    });
  };

  const handleCommentChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { comment: event.target.value },
    });
  };

  const onApprove = () => {
    if (state.rejectRequest) {
      updateStatusCustomer(userId, "REJECTED", state.comment);
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { rejectRequest: false, showRejectButton: true, comment: "" },
      });
      onClose();
    } else {
      updateStatusCustomer(userId, "ACTIVE");
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { rejectRequest: false, showRejectButton: true, comment: "" },
      });
      onClose();
    }
  };

  return (
    <WarningDialog
      isOpen={isOpen}
      onClose={() => {
        dispatch({
          type: "UPDATE_FIELDS",
          payload: {
            rejectRequest: false,
            showRejectButton: true,
            comment: "",
          },
        });
        onClose();
      }}
      onConfirm={onApprove}
      showRejectButton={state.showRejectButton}
      onReject={onReject}
      title={!state.rejectRequest ? staticTexts.requestApproveModalHeader : ""}
      description={
        !state.rejectRequest ? staticTexts.requestApproveModalDescription : ""
      }
    >
      {state.rejectRequest && (
        <TextField
          label="Explain Reason"
          sx={{ margin: "12px 0" }}
          variant="outlined"
          multiline
          rows={4}
          fullWidth
          value={state.comment}
          onChange={handleCommentChange}
          required
        />
      )}
    </WarningDialog>
  );
};

export default RejectApproval;
