import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { Autocomplete, Box, Checkbox, TextField, Typography, Tooltip, IconButton } from '@mui/material';
import {CustomTable, WarningDialog} from '../../../components';
import { useParams } from 'react-router-dom';
import Manage from './manage';
import DeleteIcon from '@mui/icons-material/Delete';

const top100Films = [
  { label: 'Loki', year: 1994 },
  { label: 'Hulk', year: 1972 },
  { label: 'Tony', year: 1974 },
];

type params = {
  duration: string
}

interface state {
  manage: {
    isOpen: boolean,
  };
  list: {
    id: number;
    payment_id: JSX.Element;
    trip_id: string;
    total_amount: number;
    payment_date: string;
    payment_method: string;
    status: string;
    actions: JSX.Element;
  }[],
  // deleteWarning: boolean
}

const PaymentList = () => {
  const param = useParams<params>();
  const [state, setState] = useState<state>({
    manage: {
      isOpen: false
    },
    list: [],
  });

  const [deleteWarning, setDeleteWarning] = useState(false);

  const handleDelete = useCallback(() => {
    setDeleteWarning(!deleteWarning)
  },[deleteWarning]);

  const handleManage = useCallback(() => {
    setState(prev => {
      return {
        ...prev,
        manage: {
          ...prev.manage,
          isOpen: !prev.manage.isOpen
        }
      }
    });
  },[]);

  useEffect(() => {
    const data = [
      {
        paymentId: 'EP-PAY-22-0011',
        tripId: 'EP-TRI-22-0001',
        amount: 1234,
        date: '02 May, 2023 06:36 PM',
        method: 'UPI',
        status: 'success'
      },
      {
        paymentId: 'EP-PAY-22-0002',
        tripId: 'EP-TRI-22-0022',
        amount: 7678,
        date: '02 May, 2023 06:36 PM',
        method: 'Debit Card',
        status: 'success'
      },
      {
        paymentId: 'EP-PAY-22-0021',
        tripId: 'EP-TRI-22-0031',
        amount: 9786,
        date: '02 Apr, 2023 06:36 PM',
        method: 'Net Banking',
        status: 'failed',
      },
    ];

    

    const list = data.map((ele, index) => {
      return createRow(ele, index, handleManage, handleDelete)
    })
    setState((prevState) => ({ ...prevState, list: list }));
  }, [handleManage, handleDelete]);


  const onPageChange = (e: ChangeEvent<unknown>) => {

  }

  const onDelete = () => {

  }
  const columns = [
    {
      id: 'id',
      label: 'S No.'
    },
    {
      id: 'payment_id',
      label: 'Payment ID'
    },
    {
      id: 'trip_id',
      label: 'Trip ID'
    },
    {
      id: 'total_amount',
      label: 'Total amount'
    },
    {
      id: 'payment_date',
      label: 'Payment Date'
    },
    {
      id: 'payment_method',
      label: 'Payment Method'
    },
    (param.duration === 'all' && {
      id: 'status',
      label: 'Status'
    }),
    {
      id: "actions",
      label: "Action"
    }
  ];

  const createRow = (payment: any, index: number, showDetail: any, onDelete: any) => {
    const payment_id =
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={showDetail}
      >
        {payment.paymentId}
      </Typography>
    const actions =
      <Tooltip title="delete">
        <IconButton onClick={() => onDelete('index')}>
          <DeleteIcon color='error' />
        </IconButton>
      </Tooltip>
    return {
      id: index + 1,
      payment_id,
      trip_id: payment.tripId,
      total_amount: payment.amount,
      payment_date: payment.date,
      payment_method: payment.method,
      status: payment.status,
      actions: actions
    }
  }

  return (
    <>
      <Box className="my-2" paddingY="10px">
        <Autocomplete
          multiple
          sx={{ width: "30%" }}
          options={top100Films}
          disableCloseOnSelect
          getOptionLabel={(option) => option.label}
          renderOption={(props, option, { selected }) => (
            <li {...props}>
              <Checkbox style={{ marginRight: 8 }} checked={selected} />
              {option.label}
            </li>
          )}
          renderInput={(params) => (
            <TextField {...params} label="Users" placeholder="Search by name" />
          )}
        />
      </Box>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 252px)"
          errorMessage="Do Transaction to see the data here"
          pagination={{
            page: 3,
            totalPages: 5,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <Manage isOpen={state.manage.isOpen} onClose={handleManage} />
      <WarningDialog
        isOpen={deleteWarning}
        title="Delete Payment"
        description="Are you sure you want to delete this payment"
        onClose={handleDelete}
        onConfirm={onDelete}
      />
    </>
  );
}

export default PaymentList