import { FC, useEffect } from "react";
import { CustomDialog } from "../../../components";
import { Box, Grid, TextField } from "@mui/material";
import { getStaticTexts } from "../../../constants";
import { ManageItemsCategory } from "../../../interfaces/items-category";
import { createCategory, updateCategory } from "../../../services/aggregation";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

const Manage: FC<ManageItemsCategory> = ({
  isOpen,
  type,
  category,
  isDisabled = true,
  onClose,
}) => {
  isDisabled = type === "view" ? true : false;
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      categoryName: "",
      description: "",
    },
  });

  useEffect(() => {
    setValue("categoryName", category?.name || "");
    setValue("description", category?.description || "");
  }, [type, category, setValue]);

  const handleCreateCategory = async () => {
    try {
      const { categoryName, description } = getValues();
      await createCategory({
        name: categoryName,
        description: description,
      });
      onClose();
      enqueueSnackbar(staticTexts.categoryCreatedSuccessfully, {
        variant: "success",
      });
      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateCategory = async () => {
    try {
      const { categoryName, description } = getValues();

      const payload = {
        name: categoryName,
        description: description,
      };
      await updateCategory(category._id, payload);
      enqueueSnackbar(staticTexts.categoryUpdatedSuccessfully, {
        variant: "success",
      });
      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitButton = async () => {
    try {
      if (type === "new") {
        await handleCreateCategory();
      } else {
        await handleUpdateCategory();
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitButton)}>
      <CustomDialog
        title={staticTexts.categoryDetail}
        disabled={isDisabled}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSubmit(handleSubmitButton)}
      >
        <Box sx={{ mt: 1 }}>
          {" "}
          {/* Reduced top margin */}
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Controller
                name="categoryName"
                control={control}
                rules={{
                  required: staticTexts.nameIsRequired,
                }}
                render={({ field }) => (
                  <TextField
                    label={staticTexts.categoryName}
                    error={!!errors.categoryName}
                    helperText={errors.categoryName?.message || " "}
                    {...field}
                    fullWidth
                    size="small"
                    margin="none"
                    disabled={isDisabled}
                  />
                )}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <Controller
                name="description"
                control={control}
                rules={{
                  required: staticTexts.descriptionIsRequired,
                }}
                render={({ field }) => (
                  <TextField
                    label={staticTexts.description}
                    error={!!errors.description}
                    helperText={errors.description?.message || " "}
                    {...field}
                    fullWidth
                    size="small"
                    margin="none"
                    disabled={isDisabled}
                  />
                )}
              />
            </Grid>
          </Grid>
        </Box>
      </CustomDialog>
    </form>
  );
};

export default Manage;
