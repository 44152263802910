import { StringDecoder } from "node:string_decoder";
import { httpRequest } from "../Network";

export const createCategory = async (payload: any) => new Promise(async (resolve, reject) => {

  try {
    const create = await httpRequest('POST', '/aggregation/admin/category', payload) as any;
    resolve(create?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateCategory = async (categoryId: string, payload: any) => new Promise(async (resolve, reject) => {

  try {
    const update = await httpRequest('PUT', `/aggregation/admin/category/${categoryId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateCategoryStatus = async (categoryId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/aggregation/admin/update-status-category/${categoryId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const deleteCategory = async (categoryId: string) => new Promise(async (resolve, reject) => {

  try {
    const deleteCategory = await httpRequest('DELETE', `/aggregation/admin/category/${categoryId}`) as any;
    resolve(deleteCategory?.data);
  } catch (error) {
    reject(error);
  }
});


export const deleteCategories = async (categoryId: Array<string>) => new Promise(async (resolve, reject) => {

  try {
    const deleteCategory = await httpRequest('DELETE', `/aggregation/admin/category`, { categoryIds: categoryId }) as any;
    resolve(deleteCategory?.data);
  } catch (error) {
    reject(error);
  }
});

export const getCategories = async (params: any) => new Promise(async (resolve, reject) => {

  try {
    const categories = await httpRequest('GET', '/aggregation/admin/categories', {}, params) as any;
    resolve(categories?.data);
  } catch (error) {
    reject(error);
  }
});

export const getItems = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const items = await httpRequest('GET', '/aggregation/admin/items', {}, params) as any;
    resolve(items?.data);
  } catch (error) {
    reject(error);
  }
});

export const getChatItems = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const items = await httpRequest('GET', '/aggregation/admin/found-items-list', {}, params) as any;
    resolve(items?.data);
  } catch (error) {
    reject(error);
  }
});

export const getResolvedItems = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const items = await httpRequest('GET', '/aggregation/admin/resolved-items', {}, params) as any;
    resolve(items?.data);
  } catch (error) {
    reject(error);
  }
})

export const updateItemStatus = async (itemId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/aggregation/admin/update-status-item/${itemId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const resolveItem = async (itemId: string) => new Promise(async (resolve, reject) => {
  try {
    const resolveItem = await httpRequest('PUT', `/aggregation/admin/item-resolve/${itemId}`) as any;
    resolve(resolveItem?.data);
  } catch (error) {
    reject(error);
  }
});

export const resolveClaimedItem = async (itemId: string, claimedUserId: string) => new Promise(async (resolve, reject) => {
  try {
    const resolveItem = await httpRequest('PUT', `/aggregation/admin/item-resolved/${itemId}/${claimedUserId}`) as any;
    resolve(resolveItem?.data);
  } catch (error) {
    reject(error);
  }
});

export const rejectClaimedItem = async (claimId: string) => new Promise(async (resolve, reject) => {
  try {
    const rejectItem = await httpRequest('PUT', `/aggregation/admin/reject-claim-request/${claimId}`) as any;
    resolve(rejectItem?.data);
  } catch (error) {
    reject(error);
  }
});

export const createQuestionnaire = async (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const create = await httpRequest('POST', '/aggregation/admin/question', payload) as any;
    resolve(create?.data);
  } catch (error) {
    reject(error);
  }
});

export const getQuestionnaire = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const questionnaire = await httpRequest('GET', '/aggregation/admin/questions', {}, params) as any;
    resolve(questionnaire?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateQuestionnaireStatus = async (questionnaireId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/aggregation/admin/update-status-question/${questionnaireId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateQuestionnaire = async (questionnaireId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/aggregation/admin/question/${questionnaireId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const deleteQuestionnaire = async (questionnaireIds: Array<string>) => new Promise(async (resolve, reject) => {
  try {
    const deleteQuestionnaire = await httpRequest('DELETE', `/aggregation/admin/question`, questionnaireIds) as any;
    resolve(deleteQuestionnaire?.data);
  } catch (error) {
    reject(error);
  }
});

export const getClaimRequestOnFoundItem = async (itemId: string, params: any) => new Promise(async (resolve, reject) => {
  try {
    const claimRequest = await httpRequest('GET', `/aggregation/admin//claim-request-on-found-item/${itemId}`, {}, params) as any;
    resolve(claimRequest?.data);
  } catch (error) {
    reject(error);
  }
});