import React from "react";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Divider,
  Box,
  Grid,
} from "@mui/material";

import { ClaimRequest as ClaimRequestType } from "../../../interfaces";
import UserInfo from "../UserInfo";
import LocationInfo from "../LocationInfo";
import QuestionList from "../QuestionList";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../../constants";

interface ClaimRequestProps {
  claim: ClaimRequestType;
  onResolve: (claim: any) => void;
  onReject: (claim: any) => void;
}

const ClaimRequest: React.FC<ClaimRequestProps> = ({
  claim,
  onResolve,
  onReject,
}) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  return (
    <Box
      sx={{
        width: "100%",
        padding: 2,
        backgroundColor: "background.default",
        borderRadius: 2,
        boxShadow: "0px 2px 8px rgba(0, 0, 0, 0.1)",
      }}
    >
      <Card
        sx={{
          overflowY: "auto",
          boxShadow: "none",
          padding: 2,
          margin: "auto",
          backgroundColor: "background.paper",
        }}
      >
        <CardContent>
          {/* Header */}
          <Typography
            variant="h5"
            component="h2"
            color="primary"
            textAlign="center"
            fontWeight="bold"
          >
            {staticTexts.claimRequest}
          </Typography>

          {/* Claimed By Section */}
          <Divider sx={{ my: 2 }} />
          <UserInfo user={claim._userClaimBy} title={staticTexts.claimedBy} />

          {/* Claimed To Section */}
          <Divider sx={{ my: 2 }} />
          <UserInfo user={claim._userClaimTo} title={staticTexts.claimedTo} />

          {/* Description Section */}
          <Divider sx={{ my: 2 }} />
          <Typography
            variant="h6"
            fontWeight="bold"
            sx={{ marginBottom: 1, color: "text.primary" }}
          >
            {staticTexts.description}
          </Typography>
          <Typography
            variant="body2"
            sx={{
              wordBreak: "break-word",
              color: "text.secondary",
              mb: 2,
            }}
          >
            {claim.description}
          </Typography>

          {/* Images Section */}
          {claim.images && claim.images.length > 0 && (
            <>
              <Typography
                variant="h6"
                fontWeight="bold"
                sx={{ marginTop: 2, marginBottom: 1 }}
              >
                {staticTexts.images}
              </Typography>
              <Grid container spacing={2}>
                {(claim.images || []).map((image, index) => (
                  <Grid item xs={6} sm={4} md={3} key={index}>
                    <Box
                      component="img"
                      src={image}
                      alt={`Claim evidence ${index + 1}`}
                      sx={{
                        width: "100%",
                        borderRadius: 2,
                        boxShadow: "0px 2px 6px rgba(0, 0, 0, 0.15)",
                        objectFit: "cover",
                      }}
                    />
                  </Grid>
                ))}
              </Grid>
            </>
          )}

          {/* Location Info */}
          <LocationInfo
            location={(claim as any).location}
            eventDate={claim.createdAt}
          />

          {/* Question List */}
          <Divider sx={{ my: 2 }} />

          {claim.questions && claim.questions.length > 0 && (
            <>
              <QuestionList questions={claim.questions} />
              <Divider sx={{ my: 3 }} />
            </>
          )}

          {/* Action Buttons */}
          <Box
            sx={{
              display: "flex",
              justifyContent: "space-between",
              gap: 2,
            }}
          >
            <Button
              variant="contained"
              color="primary"
              fullWidth
              onClick={() => onResolve(claim)}
            >
              {staticTexts.resolveClaim}
            </Button>
            <Button
              variant="outlined"
              color="error"
              fullWidth
              onClick={() => onReject(claim)}
            >
              {staticTexts.rejectClaim}
            </Button>
          </Box>
        </CardContent>
      </Card>
    </Box>
  );
};

export default ClaimRequest;
