import { FC, useEffect, useReducer, useCallback } from "react";
import { CustomDialog, CustomTable } from "../../../../components";
import { Box, Checkbox, Grid2, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import {
  createRole,
  getPermissions,
  updateRole,
} from "../../../../services/access-control";
import { getStaticTexts } from "../../../../constants";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";

interface props {
  isOpen: boolean;
  type: "new" | "edit" | "view";
  isDisabled?: boolean;
  onClose: () => void;
  role: any;
}

const Manage: FC<props> = ({ isOpen, type, role, onClose }) => {
  const isDisabled = type === "view" ? true : false;

  const manageAgentReducer = (state: any, action: any) => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_PERMISSION":
        if (action.checked) {
          let newPermissions = [
            ...state.selectedPermissions,
            {
              permission: action.permission,
              type: action.permissionType,
              checked: action.checked,
            },
          ];

          // If write or delete permission is checked, automatically check read permission
          if (
            action.permissionType === "write" ||
            action.permissionType === "delete"
          ) {
            // Check if read permission already exists
            const hasReadPermission = state.selectedPermissions.some(
              (p: any) =>
                p.permission === action.permission && p.type === "read"
            );

            if (!hasReadPermission) {
              newPermissions.push({
                permission: action.permission,
                type: "read",
                checked: true,
              });
            }
          }

          return {
            ...state,
            selectedPermissions: newPermissions,
            list: state.list.map((item: any) => {
              if (item.id === action.permission) {
                const updatedItem = {
                  ...item,
                  [action.permissionType]: (
                    <Checkbox
                      onChange={(e) =>
                        handlePermissionChange(
                          action.permission,
                          action.permissionType,
                          e.target.checked
                        )
                      }
                      checked={true}
                    />
                  ),
                };

                // If write or delete is checked, force read to be checked and disabled
                if (
                  action.permissionType === "write" ||
                  action.permissionType === "delete"
                ) {
                  updatedItem.read = (
                    <Checkbox
                      onChange={(e) =>
                        handlePermissionChange(
                          action.permission,
                          "read",
                          e.target.checked
                        )
                      }
                      checked={true}
                      disabled={true}
                    />
                  );
                }

                return updatedItem;
              }
              return item;
            }),
          };
        } else {
          // Prevent unchecking read if write or delete is checked
          if (action.permissionType === "read") {
            const hasWriteOrDelete = state.selectedPermissions.some(
              (p: any) =>
                p.permission === action.permission &&
                (p.type === "write" || p.type === "delete") &&
                p.checked
            );
            if (hasWriteOrDelete) {
              return state;
            }
          }

          return {
            ...state,
            selectedPermissions: state.selectedPermissions.filter(
              (p: any) =>
                !(
                  p.permission === action.permission &&
                  p.type === action.permissionType
                )
            ),
            list: state.list.map((item: any) => {
              if (item.id === action.permission) {
                const updatedItem = {
                  ...item,
                  [action.permissionType]: (
                    <Checkbox
                      onChange={(e) =>
                        handlePermissionChange(
                          action.permission,
                          action.permissionType,
                          e.target.checked
                        )
                      }
                      checked={false}
                    />
                  ),
                };

                // Only enable read checkbox if neither write nor delete is checked
                if (
                  action.permissionType === "write" ||
                  action.permissionType === "delete"
                ) {
                  const otherPermissionChecked = state.selectedPermissions.some(
                    (p: any) =>
                      p.permission === action.permission &&
                      ((action.permissionType === "write" &&
                        p.type === "delete") ||
                        (action.permissionType === "delete" &&
                          p.type === "write")) &&
                      p.checked
                  );

                  if (!otherPermissionChecked) {
                    updatedItem.read = (
                      <Checkbox
                        onChange={(e) =>
                          handlePermissionChange(
                            action.permission,
                            "read",
                            e.target.checked
                          )
                        }
                        checked={state.selectedPermissions.some(
                          (p: any) =>
                            p.permission === action.permission &&
                            p.type === "read" &&
                            p.checked
                        )}
                        disabled={false}
                      />
                    );
                  }
                }

                return updatedItem;
              }
              return item;
            }),
          };
        }
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(manageAgentReducer, {
    manage: {
      type: "new",
      isOpen: false,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    list: [],
    selectAll: [],
    loading: false,
    selectedPermissions: [],
    permissions: [],
  });

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      description: "",
    },
  });

  useEffect(() => {
    if (type === "view" || type === "edit") {
      setValue("name", role?.name);
      setValue("description", role?.description);
    }
  }, [type, role]);

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticText = getStaticTexts(language);

  const createRow = (permission: any, existingPermissions?: string[]) => {
    const permissionId = permission.value[0];
    const hasWriteOrDelete = existingPermissions?.some(
      (p) => p === `${permissionId}.write` || p === `${permissionId}.delete`
    );

    return {
      id: permissionId,
      title: permission.title,
      read: (
        <Checkbox
          disabled={isDisabled || hasWriteOrDelete}
          onChange={(e) =>
            handlePermissionChange(permissionId, "read", e.target.checked)
          }
          checked={
            existingPermissions?.includes(`${permissionId}.read`) ||
            state.selectedPermissions.some(
              (p: any) => p.permission === permissionId && p.type === "read"
            )
          }
        />
      ),
      write: (
        <Checkbox
          disabled={isDisabled}
          onChange={(e) =>
            handlePermissionChange(permissionId, "write", e.target.checked)
          }
          checked={
            existingPermissions?.includes(`${permissionId}.write`) ||
            state.selectedPermissions.some(
              (p: any) => p.permission === permissionId && p.type === "write"
            )
          }
        />
      ),
      delete: (
        <Checkbox
          disabled={isDisabled}
          onChange={(e) =>
            handlePermissionChange(permissionId, "delete", e.target.checked)
          }
          checked={
            existingPermissions?.includes(`${permissionId}.delete`) ||
            state.selectedPermissions.some(
              (p: any) => p.permission === permissionId && p.type === "delete"
            )
          }
        />
      ),
    };
  };

  const fetchPermissions = useCallback(async () => {
    try {
      const permissions = (await getPermissions()) as any;

      if (!permissions || !permissions?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }

      // If editing or viewing an existing role, use its permissions
      const existingPermissions =
        type === "edit" || type === "view" ? role?.permissions : [];

      const permissionsList = permissions.map((permission: any) =>
        createRow(permission, existingPermissions)
      );

      dispatch({ type: "LOADED", list: permissionsList });
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { permissions: permissions },
      });

      // Populate selectedPermissions for edit/view mode
      if ((type === "edit" || type === "view") && role?.permissions) {
        const selectedPerms: any[] = [];
        role?.permissions.forEach((perm: any) => {
          const [permission, permType] = perm.split(".");
          if (permType) {
            selectedPerms.push({
              permission,
              type: permType,
              checked: true,
            });
          }
        });

        dispatch({
          type: "UPDATE_FIELDS",
          payload: {
            selectedPermissions: selectedPerms,
          },
        });
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  }, [staticText.somethingWentWrong, type, role]);

  useEffect(() => {
    fetchPermissions();
  }, [fetchPermissions]);

  const handlePermissionChange = (
    permission: any,
    type: string,
    checked: boolean
  ) => {
    dispatch({
      type: "UPDATE_PERMISSION",
      permission: permission,
      permissionType: type,
      checked,
    });
  };

  const handleConfirm = async () => {
    try {
      const name = getValues("name");
      const description = getValues("description");

      if (!state.selectedPermissions.length) {
        enqueueSnackbar(staticText.pleaseSelectAtLeastOnePermission, {
          variant: "error",
        });
        return;
      }

      const selectedPermissions = state.list.reduce(
        (acc: any[], permission: any) => {
          const permissionValue = [];
          if (
            state.selectedPermissions.some(
              (p: any) => p.permission === permission.id && p.type === "read"
            )
          )
            permissionValue.push(`${permission.id}.read`);
          if (
            state.selectedPermissions.some(
              (p: any) => p.permission === permission.id && p.type === "write"
            )
          )
            permissionValue.push(`${permission.id}.write`);
          if (
            state.selectedPermissions.some(
              (p: any) => p.permission === permission.id && p.type === "delete"
            )
          )
            permissionValue.push(`${permission.id}.delete`);

          if (permissionValue.length) {
            acc.push({
              title: permission.title,
              value: permissionValue,
            });
          }
          return acc;
        },
        []
      );

      const payload = {
        name,
        description,
        permissions: selectedPermissions,
      };

      await createRole(payload);

      enqueueSnackbar(staticText.roleCreatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateRole = async () => {
    try {
      const name = getValues("name");
      const description = getValues("description");

      const selectedPermissions = state.list.reduce(
        (acc: any[], permission: any) => {
          const permissionValue = [];
          if (
            state.selectedPermissions.some(
              (p: any) => p.permission === permission.id && p.type === "read"
            )
          )
            permissionValue.push(`${permission.id}.read`);
          if (
            state.selectedPermissions.some(
              (p: any) => p.permission === permission.id && p.type === "write"
            )
          )
            permissionValue.push(`${permission.id}.write`);
          if (
            state.selectedPermissions.some(
              (p: any) => p.permission === permission.id && p.type === "delete"
            )
          )
            permissionValue.push(`${permission.id}.delete`);

          if (permissionValue.length) {
            acc.push({
              title: permission.title,
              value: permissionValue,
            });
          }
          return acc;
        },
        []
      );

      const payload = {
        name,
        description,
        permissions: selectedPermissions,
      };

      await updateRole(role._id, payload);

      enqueueSnackbar(staticText.roleUpdatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitButton = async () => {
    try {
      if (type === "new") {
        await handleConfirm();
      } else {
        await handleUpdateRole();
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticText.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  const columns = [
    {
      id: "title",
      label: staticText.title,
    },
    {
      id: "read",
      label: staticText.read,
    },
    {
      id: "write",
      label: staticText.write,
    },
    {
      id: "delete",
      label: staticText.delete,
    },
  ];

  return (
    <form onSubmit={handleSubmit(handleSubmitButton)}>
      <CustomDialog
        title={staticText.roleDetail}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSubmit(handleSubmitButton)}
        disabled={isDisabled}
      >
        <Grid2 container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Controller
              name="name"
              control={control}
              rules={{
                required: staticText.nameIsRequired,
              }}
              render={({ field }) => (
                <TextField
                  label={staticText.name}
                  {...field}
                  disabled={isDisabled}
                  error={!!errors.name}
                  helperText={errors.name?.message || " "}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Controller
              name="description"
              control={control}
              rules={{
                required: staticText.descriptionIsRequired,
              }}
              render={({ field }) => (
                <TextField
                  label={staticText.description}
                  {...field}
                  disabled={isDisabled}
                  error={!!errors.description}
                  helperText={errors.description?.message || " "}
                />
              )}
            />
          </Grid2>
        </Grid2>
        <Box marginTop="10px">
          <Box paddingY="8px" fontWeight={600}>
            {staticText.permissions}
          </Box>
          <CustomTable
            columns={columns}
            rows={state.list}
            height="auto"
            errorMessage={staticText.addPermissionsToSeeDataHere}
          />
        </Box>
      </CustomDialog>
    </form>
  );
};

export default Manage;
