import { ChangeEvent, useState, useEffect, useReducer } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { CustomTable, Header, WarningDialog } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from "./manage";
import { getStaticTexts, PERMISSIONS } from "../../../constants";
import {
  deleteAgents,
  getAgents,
  updateAgentStatus,
} from "../../../services/access-control";
import { enqueueSnackbar } from "notistack";
import { handleCharacterLimit } from "../../../services/common";
import Loader from "../../../components/Loader";
import { withPermission } from "../../../components/hoc";
import Details from "./details";
import { useSelector } from "react-redux";

interface state {
  manage: {
    isOpen: boolean;
    type: "new" | "edit" | "view";
    agent: any;
  };
  deleteWarning: boolean;
  multiDeleteWarning: boolean;
  _agent: string;
  list: {
    all: JSX.Element;
    id: number;
    _id: string;
    name: JSX.Element;
    email: string;
    phone: string;
    preference: string;
    status: JSX.Element;
    action: JSX.Element;
  }[];
  selectAll: string[];
  loading: boolean;
  searchQuery: string;
  page: number;
  statusFilter: string;
  totalPages: number;
}

const Agents = () => {
  const agentReducer = (state: state, action: any): state => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
        case "UPDATE_CHECKBOX": {
          const { id, isChecked } = action.payload;
    
          // Update the `selectAll` array
          const updatedSelectAll = isChecked
            ? [...state.selectAll, id]
            : state.selectAll.filter((ele) => ele !== id);
    
          // Update the list to reflect the checkbox state
          const updatedList = state.list.map((category: any) => ({
            ...category,
            all: (
              <Checkbox
                onChange={(e) => handleMultipleSelect(e, state.selectAll, category._id)}
                checked={updatedSelectAll.includes(category._id)}
              />
            ),
          }));
          return {
            ...state,
            selectAll: updatedSelectAll,
            list: updatedList,
          };
        }
        case 'UPDATE_LIST':
          const userIds = action.payload;
          const filteredList = state.list.filter((user: any) => !(userIds || []).includes(user._id));
          return {
            ...state,
            list: filteredList,
          };
        case 'TOGGLE_STATUS':
          const { id, status } = action.payload;
          const updatedList = state.list.map((user: any) =>
            user._id === id
              ? {
                  ...user,
                  status: (
                    <Switch
                      checked={status === "ACTIVE"}
                      disabled={isEditIconDisabled}
                      onChange={(e) => {
                        handleStatusChange(
                          user._id,
                          e.target.checked ? "ACTIVE" : "INACTIVE",
                        );
                      }}
                    />
                  ),
                }
              : user
          );
          return {
            ...state,
            list: updatedList,
          };
        
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const userPermissions = useSelector((state: any) => state.permissions);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(
    PERMISSIONS.EDIT_ACCESS_CONTROL
  );

  const isDeleteIconDisabled = !userPermissions.includes(
    PERMISSIONS.DELETE_ACCESS_CONTROL
  );

  const fetchAgents = async (searchQuery: string = "", page: number = 1) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        text: searchQuery,
      };

      if (state.statusFilter && state.statusFilter !== "ALL") {
        params.status = state.statusFilter;
      }

      const agents = (await getAgents(params)) as any;

      if (!agents || !agents.agents?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: agents.meta.totalPages },
      });

      const list = agents.agents.map((user: any, index: number) =>
        createRow(
          state.selectAll,
          user,
          index,
          handleManage,
          handleWarningDialog,
          handleMultipleSelect,
          page
        )
      );

      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const [state, dispatch] = useReducer(agentReducer, {
    manage: {
      type: "new",
      isOpen: false,
      agent: null,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _agent: "",
    list: [],
    selectAll: [],
    loading: true,
    searchQuery: "",
    page: 1,
    statusFilter: "",
    totalPages: 1,
  });

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchAgents(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchAgents(state.searchQuery, page);
  };

  useEffect(() => {
    fetchAgents(state.searchQuery, state.page);
  }, [state.statusFilter]);

  const handleManage = (type: "new" | "edit" | "view", agent: any = null) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: true,
          agent,
        },
      },
    });
  };

  const handleClose = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          isOpen: false,
        },
      },
    });
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      await updateAgentStatus(id, { status });
      dispatch({ type: "UPDATE_STATUS", _id: id, status });
      enqueueSnackbar(staticTexts.agentStatusUpdatedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: "TOGGLE_STATUS", payload : { id, status } });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      dispatch({ type: "UPDATE_FIELDS", payload: { loading : false}})
    }
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const allIds = state.list.map((agent: any) => agent._id);

    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        selectAll: checked ? allIds : [],
      },
    });

    const updatedList = state.list.map(
      (agent: { _id: string; [key: string]: any }) => ({
        ...agent,
        all: (
          <Checkbox
            onChange={(e) =>
              handleMultipleSelect(e, checked ? allIds : [], agent._id)
            }
            checked={checked}
          />
        ),
      })
    );

    dispatch({ type: "LOADED", list: updatedList });
  };

  const handleMultipleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    selectAll: string[],
    id: string
  ) => {
    const isChecked = e.target.checked;
    dispatch({
      type: "UPDATE_CHECKBOX",
      payload: { id, isChecked },
    });
  };

  const handleDeleteAgent = async (agentIds: Array<string>) => {
    try {
      await deleteAgents(agentIds);
      enqueueSnackbar(staticTexts.agentDeletedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: 'UPDATE_LIST', payload: agentIds });
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDelete = async (agentId: string) => {
    await handleDeleteAgent([agentId]);
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: false,
        _user: "",
      },
    });
  };

  const handleMultiDelete = async (agentIds: Array<string> = []) => {
    if (agentIds.length) {
      await handleDeleteAgent(agentIds);
      dispatch({
        type: "UPDATE_FIELDS",
        payload: {
          multiDeleteWarning: false,
        },
      });
    }
  };

  const handleWarningDialog = (_agent: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: !state.deleteWarning,
        _agent,
      },
    });
  };

  const handleMutlipleWarningDialog = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        multiDeleteWarning: !state.multiDeleteWarning,
        selectAll: state.selectAll,
      },
    });
  };

  const statusFilter = [
    {
      label: staticTexts.all,
      value: "ALL",
    },
    {
      label: staticTexts.active,
      value: "ACTIVE",
    },
    {
      label: staticTexts.inactive,
      value: "INACTIVE",
    },
  ];

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={
            state.selectAll?.length > 0 &&
            state.list?.length === state.selectAll?.length
          }
          indeterminate={
            state.selectAll?.length > 0 &&
            state.list?.length !== state.selectAll?.length
          }
        />
      ),
    },
    {
      id: "id",
      label: staticTexts.sNo,
    },
    {
      id: "name",
      label: staticTexts.name,
    },
    {
      id: "email",
      label: staticTexts.email,
    },
    {
      id: "phone",
      label: staticTexts.phone,
    },
    {
      id: "status",
      label: staticTexts.status,
    },
    {
      id: "action",
      label: staticTexts.action,
    },
  ];

  const createRow = (
    selectAll: string[],
    agent: any,
    index: number,
    onEdit: any,
    onDelete: any,
    onMultiDelete: any,
    page: number
  ) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, agent._id)}
        checked={selectAll.includes(agent._id) ? true : false}
      />
    );

    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => onEdit("view", agent)}
        sx={{ textTransform: "capitalize" }}
      >
        {agent.name}
      </Typography>
    );

    const status = (
      <Switch
        checked={agent.status === "ACTIVE"}
        disabled={isEditIconDisabled}
        onChange={(e) => {
          handleStatusChange(
            agent._id,
            e.target.checked ? "ACTIVE" : "INACTIVE"
          );
        }}
      />
    );

    const action = (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onEdit("edit", agent)}
            color="primary"
            disabled={isEditIconDisabled}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => onDelete(agent?._id)}
            color="error"
            disabled={isDeleteIconDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: (page - 1) * itemsPerPage + index + 1,
      _id: agent._id,
      name,
      email: handleCharacterLimit(agent.email, 30),
      phone: "+" + agent.phone.prefix + " " + agent.phone.number,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      {state.loading && <Loader />}
      <Header
        isDeleteIconDisabled={isDeleteIconDisabled}
        isEditIconDisabled={isEditIconDisabled}
        searchPlaceholder={staticTexts.searchByAgentNameEmailAndPhone}
        onSearch={handleSearch}
        onBtnClick={() => handleManage("new")}
        onDelete={(e) => handleMutlipleWarningDialog()}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
        btnText={staticTexts.addNewAgent}
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === "") {
                return staticTexts.statusFilter;
              }
              if (selected === "ALL") {
                return staticTexts.all;
              }
              return selected === "ALL"
                ? staticTexts.all
                : selected === "ACTIVE"
                ? staticTexts.active
                : staticTexts.inactive;
            }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_FIELDS",
                payload: { statusFilter: e.target.value, page: 1 },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.addAgentToSeeTheDataHere}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        agent={state.manage.agent}
        onClose={handleClose}
        onSave={() => {
          fetchAgents(state.searchQuery, state.page);
          handleClose();
        }}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleWarningDialog(state._agent || "")}
        onConfirm={() => handleDelete(state._agent)}
        title={staticTexts.deleteAgent}
        description={staticTexts.areYouSureYouWantToDeleteThisAgent}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMutlipleWarningDialog()}
        onConfirm={() => handleMultiDelete(state.selectAll)}
        title={staticTexts.deleteAgents}
        description={staticTexts.areYouSureYouWantToDeleteAllSelectedAgents}
      />
    </>
  );
};

export default withPermission(Agents, PERMISSIONS.VIEW_ACCESS_CONTROL);
