import React, { ChangeEvent, memo } from "react";
import {
  TextField,
  MenuItem,
  InputAdornment,
  Select,
  SelectChangeEvent,
  Box,
} from "@mui/material";
import { Controller, Control, FieldError } from "react-hook-form";
import countries from "../../../assets/json/countries.json";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../constants";
import { useSelector } from "react-redux";

interface PhoneNumberInputProps {
  label?: string;
  className?: string;
  name: string;
  helperText?: string;
  countryCode?: string;
  phoneNumber?: string;
  control: Control<any>;
  error?: FieldError;
  isDisabled?: boolean;
  validationRules?: Record<string, any>;
  handlePhoneNumberChange?: (event: ChangeEvent<HTMLInputElement>) => void;
  handleCountryCodeChange?: (event: SelectChangeEvent<string>) => void;
}

const PhoneNumberInputComponent: React.FC<PhoneNumberInputProps> = ({
  label = "Phone Number",
  className,
  name,
  helperText,
  countryCode,
  control,
  error,
  isDisabled = false,
  validationRules = {},
  handleCountryCodeChange,
}) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  // Determine the direction based on the language
  const isRtl = ["ar", "he", "fa", "ur"].includes(language); // Add other RTL languages if needed

  return (
    <Controller
      name={name}
      control={control}
      rules={{
        required: `${label} is required`,
        ...validationRules,
      }}
      render={({ field }) => (
        <TextField
          {...field}
          name={name}
          label={label}
          className={className}
          onChange={(e) => {
            const value = e.target.value;
            const numericValue = value.replace(/[^0-9]/g, "");
            field.onChange(numericValue);
            if (value !== numericValue) {
              enqueueSnackbar(staticTexts.onlyNumbersAllowed, {
                variant: "error",
              });
            }
          }}
          variant="outlined"
          fullWidth
          placeholder="00000 00000"
          size="small"
          error={!!error}
          helperText={error?.message || helperText}
          disabled={isDisabled}
          InputProps={{
            startAdornment: !isRtl && (
              <InputAdornment position="start">
                <Select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  variant="standard"
                  disabled={isDisabled}
                  disableUnderline
                  sx={{
                    marginInlineEnd: "8px",
                    maxHeight: "150px",
                    "& .MuiSelect-select": {
                      paddingTop: "8px",
                      color: "#4A403A",
                      fontSize: "14px",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {countries.map((country: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={`+${country.dial_code}`}
                      onClick={() =>
                        handleCountryCodeChange?.({
                          target: { value: `+${country.dial_code}` },
                        } as SelectChangeEvent<string>)
                      }
                    >
                      {`+${country.dial_code}`}
                    </MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),
            endAdornment: isRtl && (
              <InputAdornment position="end">
                <Select
                  value={countryCode}
                  onChange={handleCountryCodeChange}
                  variant="standard"
                  disabled={isDisabled}
                  disableUnderline
                  sx={{
                    marginInlineStart: "8px",
                    maxHeight: "150px",
                    "& .MuiSelect-select": {
                      paddingTop: "8px",
                      color: "#4A403A",
                      fontSize: "14px",
                    },
                  }}
                  MenuProps={{
                    PaperProps: {
                      style: {
                        maxHeight: "200px",
                        overflowY: "auto",
                      },
                    },
                  }}
                >
                  {countries.map((country: any, index: any) => (
                    <MenuItem
                      key={index}
                      value={`+${country.dial_code}`}
                      onClick={() =>
                        handleCountryCodeChange?.({
                          target: { value: `+${country.dial_code}` },
                        } as SelectChangeEvent<string>)
                      }
                    >
                      {`+${country.dial_code}`}
                    </MenuItem>
                  ))}
                </Select>
              </InputAdornment>
            ),
          }}
          inputProps={{
            dir: isRtl ? "rtl" : "ltr", // Adjust text direction
          }}
        />
      )}
    />
  );
};

export const PhoneNumberInput = memo(PhoneNumberInputComponent);
