import React, { useState, useEffect, useRef } from "react";
import {
  AppBar,
  Avatar,
  Box,
  Divider,
  IconButton,
  Toolbar,
  Typography,
  Paper,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import CloseIcon from "@mui/icons-material/Close";
import { useTheme } from "@mui/material/styles";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../../constants";

interface User {
  id: number;
  claimedUserName: string;
  claimedUserProfile: string;
  lastMessage: string;
  _userClaimBy: string;
}

interface ChatWindowProps {
  user: User;
  onBack: () => void;
  className?: string;
  messages?: Array<any>;
  isLoading: boolean;
  onScroll: (event: React.UIEvent<HTMLDivElement>) => void;
}

const ChatWindow: React.FC<ChatWindowProps> = ({
  user,
  onBack,
  className,
  messages,
  onScroll,
}) => {
  const messagesEndRef = useRef<HTMLDivElement | null>(null);
  const theme = useTheme();
  const scrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const isScrollingRef = useRef(false);
  const lastScrollTopRef = useRef(0);
  const [shouldAutoScroll, setShouldAutoScroll] = useState(true);
  const isFirstRender = useRef(true);
  const [loading, setLoading] = useState(false);
  const scrollContainerRef = useRef<HTMLDivElement | null>(null);

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  // Scroll to bottom on initial render and when messages change
  useEffect(() => {
    if (messagesEndRef.current && scrollContainerRef.current) {
      if (isFirstRender.current) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
        isFirstRender.current = false;
      } else if (shouldAutoScroll) {
        scrollContainerRef.current.scrollTop =
          scrollContainerRef.current.scrollHeight;
      }
    }
  }, [messages, shouldAutoScroll]);

  const formatTime = (date: Date) => {
    const now = new Date();
    const diffInMs = now.getTime() - date.getTime();
    const diffInMinutes = Math.floor(diffInMs / 60000);

    if (diffInMinutes < 1) return staticTexts.justNow;
    if (diffInMinutes < 60) return `${diffInMinutes} ${staticTexts.minago}`;

    const diffInHours = Math.floor(diffInMinutes / 60);
    if (diffInHours < 24) return `${diffInHours} ${staticTexts.hrago}`;

    return date
      .toLocaleTimeString([], {
        hour: "2-digit",
        minute: "2-digit",
        hour12: true,
      })
      .toUpperCase();
  };

  const formatDate = (date: Date) => {
    const now = new Date();
    const isToday = date.toDateString() === now.toDateString();
    const yesterday = new Date();
    yesterday.setDate(now.getDate() - 1);
    const isYesterday = date.toDateString() === yesterday.toDateString();

    if (isToday) return staticTexts.today;
    if (isYesterday) return staticTexts.yesterday;

    return date.toLocaleDateString();
  };

  const groupedMessages = (messages || []).reduce(
    (groups: Record<string, typeof messages>, message: any) => {
      const dateKey = formatDate(new Date(message.createdAt));
      if (!groups[dateKey]) {
        groups[dateKey] = [];
      }
      groups[dateKey]?.push(message);
      return groups;
    },
    {}
  );

  const handleScroll = (event: React.UIEvent<HTMLDivElement>) => {
    if (!event.currentTarget) return;

    const { scrollTop, scrollHeight, clientHeight } = event.currentTarget;

    // Check if user is near bottom
    const isNearBottom = scrollHeight - scrollTop - clientHeight < 100;
    setShouldAutoScroll(isNearBottom);

    // Trigger scroll up event to load more messages when user reaches top
    if (scrollTop === 0 && !loading) {
      setLoading(true);
      onScroll(event);
      setTimeout(() => setLoading(false), 1000); // Reset loading after 1 second
    }
  };

  const loadMoreMessages = () => {
    setLoading(false);
  };

  return (
    <Paper
      elevation={0}
      className={`${className} flex flex-col`}
      sx={{
        width: "600px",
        height: "100vh",
        backgroundColor: "#F8F9FA",
        position: "relative",
      }}
    >
      {/* Header */}
      <AppBar
        position="sticky"
        sx={{
          backgroundColor: "#FFF7E6",
          boxShadow: "0 1px 3px rgba(0,0,0,0.1)",
          color: "#4A403A",
          borderBottom: "1px solid #E9ECEF",
          top: 0,
          zIndex: 1,
        }}
      >
        <Toolbar sx={{ minHeight: "64px !important" }}>
          {user?.claimedUserProfile ? (
            <Avatar
              src={user.claimedUserProfile}
              alt="user-profile-img"
              sx={{
                width: 40,
                height: 40,
                marginRight: 2,
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: 40,
                height: 40,
                marginRight: 2,
                color: theme.palette.primary.main,
              }}
            />
          )}
          <Box sx={{ flex: 1 }}>
            <Typography variant="subtitle1" fontWeight={600}>
              {user?.claimedUserName || "User Name"}
            </Typography>
          </Box>
          <IconButton onClick={onBack} size="small">
            <CloseIcon />
          </IconButton>
        </Toolbar>
      </AppBar>

      {/* Messages */}
      <Box
        ref={scrollContainerRef}
        className="flex-1 overflow-y-auto"
        sx={{
          padding: "20px",
          backgroundImage:
            "linear-gradient(rgba(255,255,255,0.7), rgba(255,255,255,0.7))",
          height: "calc(100vh - 64px)",
          overflowY: "auto",
          scrollBehavior: "smooth",
        }}
        onScroll={handleScroll}
      >
        {Object.entries(groupedMessages).map(([date, messages]) => (
          <Box key={date} sx={{ mb: 3 }}>
            <Box display="flex" justifyContent="center" mb={2}>
              <Typography
                variant="caption"
                sx={{
                  backgroundColor: "rgba(0,0,0,0.05)",
                  color: "text.secondary",
                  px: 2,
                  py: 0.5,
                  borderRadius: 12,
                  fontWeight: 500,
                }}
              >
                {date}
              </Typography>
            </Box>

            {(messages || []).map((msg, index) => {
              const isUserMessage = msg._sender === user._userClaimBy;
              return (
                <Box
                  key={index}
                  sx={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: isUserMessage ? "flex-end" : "flex-start",
                    mb: 2,
                  }}
                >
                  <Box
                    sx={{
                      maxWidth: "70%",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: isUserMessage ? "flex-end" : "flex-start",
                    }}
                  >
                    <Paper
                      elevation={0}
                      sx={{
                        backgroundColor: isUserMessage ? "#FFF7E6" : "#FFFFFF",
                        color: "inherit",
                        px: 2,
                        py: 1.5,
                        borderRadius: isUserMessage
                          ? "18px 18px 4px 18px"
                          : "18px 18px 18px 4px",
                        boxShadow: "0 1px 2px rgba(0,0,0,0.1)",
                      }}
                    >
                      {msg.type === "IMAGE" ? (
                        <Box sx={{ mb: 2 }}>
                          <img
                            src={msg.value.url}
                            alt="Message Image"
                            style={{
                              maxWidth: "150px",
                              maxHeight: "150px",
                              objectFit: "cover",
                              borderRadius: "8px",
                              marginBottom: msg.value.text ? "8px" : "0",
                            }}
                          />
                          {msg.value.text && (
                            <Typography
                              variant="body1"
                              sx={{ fontSize: "0.9375rem", lineHeight: 1.5 }}
                            >
                              {msg.value.text}
                            </Typography>
                          )}
                        </Box>
                      ) : (
                        <Typography
                          variant="body1"
                          sx={{ fontSize: "0.9375rem", lineHeight: 1.5 }}
                        >
                          {msg.value}
                        </Typography>
                      )}
                    </Paper>

                    <Typography
                      variant="caption"
                      sx={{
                        color: "text.secondary",
                        fontSize: "0.75rem",
                        mt: 0.5,
                        mx: 1,
                      }}
                    >
                      {formatTime(new Date(msg.createdAt))}
                    </Typography>
                  </Box>
                </Box>
              );
            })}
            <div ref={messagesEndRef} />
          </Box>
        ))}
        {loading && (
          <Typography variant="caption">
            {staticTexts.loadingMessages}
          </Typography>
        )}
      </Box>
    </Paper>
  );
};

export default ChatWindow;
