import { FC, ChangeEvent, useEffect, useState } from "react";
import { CustomDialog } from "../../../../components";
import { Box, Grid2, TextField, Typography, useTheme } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { upload, validateEmail } from "../../../../services/common";
import { getStaticTexts } from "../../../../constants";
import { Controller, useForm, FieldError, set } from "react-hook-form";
import { PhoneNumberInput } from "../../../../components/mui/PhoneNumberInput";
import { createCustomer, updateCustomer } from "../../../../services/customer";
import {
  Image as ImageIcon,
  AccountCircle as AccountCircleIcon,
} from "@mui/icons-material";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";

interface props {
  isOpen: boolean;
  type: "new" | "edit" | "view";
  user: any;
  onClose: () => void;
  onSave: () => void;
}

const Manage: FC<props> = ({ isOpen, type, user, onClose, onSave }) => {
  const isDisabled = type === "view" ? true : false;
  const theme = useTheme();
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const [profileImageUrl, setProfileImageUrl] = useState(user?.profile || "");
  const [coverProfileImageUrl, setCoverProfileImageUrl] = useState(
    user?.coverProfile || ""
  );
  const [countryCode, setCountryCode] = useState(
    `+${user?.phone?.prefix}` || "+966"
  );

  const [loading, setLoading] = useState(false);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      prefix: "",
      profile: "",
      coverProfile: "",
      address: "",
      description: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleSave = () => {
    onSave();
    onClose();
  };

  const uploadImage = async (
    e: ChangeEvent<HTMLInputElement> | any,
    type: "profile" | "coverProfile"
  ) => {
    try {
      setLoading(true);
      const files = e.target.files;

      const maxSize = 3 * 1024 * 1024; // 3MB
      const maxDimension = 1024; // Max width/height in pixels

      if (!files || !files[0]) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile, {
          variant: "error",
        });
        return;
      }

      const fileSize = files[0].size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan3MB, {
          variant: "error",
        });
        return;
      }

      // Create a promise to handle image processing
      const processImage = () => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          const objectUrl = URL.createObjectURL(files[0]);

          img.onload = async () => {
            URL.revokeObjectURL(objectUrl);

            try {
              if (img.width > maxDimension || img.height > maxDimension) {
                // Create canvas to resize image
                const canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;

                if (width > height) {
                  if (width > maxDimension) {
                    height = Math.round((height * maxDimension) / width);
                    width = maxDimension;
                  }
                } else {
                  if (height > maxDimension) {
                    width = Math.round((width * maxDimension) / height);
                    height = maxDimension;
                  }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext("2d");
                ctx?.drawImage(img, 0, 0, width, height);

                // Convert canvas to blob
                canvas.toBlob(
                  async (blob) => {
                    if (blob) {
                      const profileUrl = await upload(blob);
                      if (profileUrl) {
                        if (type === "coverProfile") {
                          setCoverProfileImageUrl(profileUrl);
                          setValue("coverProfile", profileUrl);
                        } else {
                          setProfileImageUrl(profileUrl);
                          setValue("profile", profileUrl);
                        }
                        resolve(true);
                      }
                    } else {
                      reject(new Error("Failed to create blob"));
                    }
                  },
                  "image/jpeg",
                  0.9
                );
              } else {
                const profileUrl = await upload(files[0]);
                if (profileUrl) {
                  if (type === "coverProfile") {
                    setCoverProfileImageUrl(profileUrl);
                    setValue("coverProfile", profileUrl);
                  } else {
                    setProfileImageUrl(profileUrl);
                    setValue("profile", profileUrl);
                  }
                  resolve(true);
                }
              }
            } catch (error) {
              reject(error);
            }
          };

          img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject(new Error("Failed to load image"));
          };

          img.src = objectUrl;
        });
      };

      await processImage();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    if (type === "view" || type === "edit") {
      setValue("prefix", `+${user?.phone?.prefix}`);
      setValue("phone", user?.phone?.number);
      setValue("name", user?.name);
      setValue("email", user?.email);
      setValue("address", user?.address);
      setValue("description", user?.description);
      setValue("profile", user?.profile);
      setValue("coverProfile", user?.coverProfile);
      setCountryCode(`+${user?.phone?.prefix}`);
      setCoverProfileImageUrl(user?.coverProfile || "");
      setProfileImageUrl(user?.profile || "");
    }
  }, [type, user]);

  const handleCreateCustomer = async () => {
    try {
      if (!validateEmail(getValues("email"))) {
        enqueueSnackbar(staticTexts.invalidEmail, {
          variant: "error",
        });
        return;
      }

      const payload = {
        name: getValues("name"),
        email: getValues("email"),
        phone: {
          prefix: getValues("prefix").replace(/^\++/, ""),
          number: getValues("phone"),
        },
        address: getValues("address"),
        description: getValues("description"),
        profile: getValues("profile"),
        coverProfile: getValues("coverProfile"),
        password: getValues("password"),
        confirmPassword: getValues("confirmPassword"),
        type: "BUSINESS",
      };

      await createCustomer(payload);

      enqueueSnackbar(staticTexts.customerCreatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const payload = {
        name: getValues("name"),
        email: getValues("email"),
        phone: {
          prefix: getValues("prefix").replace(/^\++/, ""),
          number: getValues("phone"),
        },
        description: getValues("description"),
        address: getValues("address"),
        profile: getValues("profile"),
        coverProfile: getValues("coverProfile"),
      };

      (await updateCustomer(user._id, payload)) as any;

      enqueueSnackbar(staticTexts.customerProfileUpdatedSuccessfully, {
        variant: "success",
      });

      handleSave();
      // window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitButton = async (data: any) => {
    try {
      if (type === "new") {
        await handleCreateCustomer();
      } else {
        await handleUpdateProfile();
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit(handleSubmitButton)}>
        <CustomDialog
          title={staticTexts.userDetails}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleSubmit(handleSubmitButton)}
          disabled={isDisabled}
        >
          <Box>
            {(type === "edit" || type === "new") && (
              <>
                <Grid2 container spacing={4}>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box className="center" sx={{ mb: 2 }}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            position: "relative",
                            width: 100,
                            height: 100,
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                          component="label"
                        >
                          {profileImageUrl ? (
                            <>
                              <img
                                src={profileImageUrl}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  width: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "4px 0",
                                }}
                              >
                                Edit
                              </div>
                            </>
                          ) : (
                            <AccountCircleIcon
                              sx={{
                                width: 100,
                                height: 100,
                                color: theme.palette.primary.main,
                              }}
                            />
                          )}
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, "profile")}
                          />
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        align="center"
                      >
                        {staticTexts.uploadProfileImage}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Box
                      sx={{
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                      }}
                    >
                      <Box className="center" sx={{ mb: 2 }}>
                        <Box
                          sx={{
                            cursor: "pointer",
                            position: "relative",
                            width: 100,
                            height: 100,
                            borderRadius: "50%",
                            overflow: "hidden",
                          }}
                          component="label"
                        >
                          {coverProfileImageUrl ? (
                            <>
                              <img
                                src={coverProfileImageUrl}
                                alt="Profile"
                                style={{
                                  width: "100%",
                                  height: "100%",
                                  objectFit: "cover",
                                }}
                              />
                              <div
                                style={{
                                  position: "absolute",
                                  bottom: 0,
                                  width: "100%",
                                  backgroundColor: "rgba(0, 0, 0, 0.5)",
                                  color: "white",
                                  textAlign: "center",
                                  fontSize: "12px",
                                  padding: "4px 0",
                                }}
                              >
                                Edit
                              </div>
                            </>
                          ) : (
                            <ImageIcon
                              sx={{
                                width: 100,
                                height: 100,
                                color: theme.palette.primary.main,
                              }}
                            />
                          )}
                          <input
                            hidden
                            accept="image/*"
                            type="file"
                            onChange={(e) => uploadImage(e, "coverProfile")}
                          />
                        </Box>
                      </Box>
                      <Typography
                        variant="body1"
                        color="text.secondary"
                        align="center"
                      >
                        {staticTexts.uploadCoverImage}
                      </Typography>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: staticTexts.nameIsRequired,
                      }}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.name}
                          error={!!errors.name}
                          helperText={errors.name?.message || " "}
                          {...field}
                          sx={{ marginBottom: "-30px" }}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: staticTexts.emailIsRequired,
                      }}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.email}
                          error={!!errors.email}
                          helperText={errors.email?.message || " "}
                          sx={{ marginBottom: "-30px" }}
                          {...field}
                          disabled={isDisabled}
                          type="email"
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="address"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.address}
                          {...field}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="description"
                      control={control}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.description}
                          {...field}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <PhoneNumberInput
                      name="phone"
                      label={staticTexts.phone}
                      helperText={errors.phone?.message as string}
                      countryCode={countryCode}
                      control={control}
                      error={errors.phone as FieldError}
                      handleCountryCodeChange={(e) => {
                        setValue("prefix", e.target.value);
                        setCountryCode(e.target.value);
                      }}
                      validationRules={{
                        required: staticTexts.phoneIsRequired,
                      }}
                    />
                  </Grid2>
                  {type === "new" && (
                    <>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <Controller
                          name="password"
                          control={control}
                          rules={{
                            required: staticTexts.passwordIsRequired,
                            minLength: {
                              value: 8,
                              message:
                                staticTexts.passwordMustBeAtLeast8Characters,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              label={staticTexts.password}
                              type="password"
                              error={!!errors.password}
                              helperText={errors.password?.message || " "}
                              sx={{ marginBottom: "-30px" }}
                              {...field}
                              disabled={isDisabled}
                            />
                          )}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <Controller
                          name="confirmPassword"
                          control={control}
                          rules={{
                            required: staticTexts.confirmPasswordIsRequired,
                            minLength: {
                              value: 8,
                              message:
                                staticTexts.passwordMustBeAtLeast8Characters,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              label={staticTexts.confirmPassword}
                              type="password"
                              error={!!errors.confirmPassword}
                              helperText={
                                errors.confirmPassword?.message || " "
                              }
                              {...field}
                              disabled={isDisabled}
                            />
                          )}
                        />
                      </Grid2>
                    </>
                  )}
                </Grid2>
              </>
            )}
          </Box>
        </CustomDialog>
      </form>
    </>
  );
};

export default Manage;
