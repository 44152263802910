import { ChangeEvent, useEffect, useReducer, useState } from "react";
import { Header, CustomTable, WarningDialog } from "../../../components";
import {
  Avatar,
  Box,
  Button,
  FormControl,
  Grid2,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { getStaticTexts, PERMISSIONS } from "../../../constants";
import { FoundItemsState } from "../../../interfaces";
import {
  getItems,
  resolveItem,
  updateItemStatus,
} from "../../../services/aggregation";
import { enqueueSnackbar } from "notistack";
import { handleCharacterLimit } from "../../../services/common";
import { useSelector } from "react-redux";
import Loader from "../../../components/Loader";
import { Manage } from "./manage";
import { withPermission } from "../../../components/hoc";
import ChatDetails from "../../../components/ChatDetails";
import ClaimDetails from "../../../components/ClaimDetails";
import { getClaimRequestOnFoundItem } from "../../../services/claim";
import { getChatListing } from "../../../services/chats";
import { formatDate } from "../../../services/utils";
const FoundItems = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticText = getStaticTexts(language);
  const [showChatListing, setShowChatListing] = useState(false);
  const [showClaimListing, setShowClaimListing] = useState(false);
  const [claimDetails, setClaimDetails] = useState([]);
  const userPermissions = useSelector((state: any) => state.permissions);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(
    PERMISSIONS.EDIT_FOUND_ITEMS
  );
  const isDeleteIconDisabled = !userPermissions.includes(
    PERMISSIONS.DELETE_FOUND_ITEMS
  );

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout>();
  const categories = useSelector((state: any) => state.category);
  const foundItemReducer = (state: FoundItemsState, action: any) => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
        case 'TOGGLE_STATUS':
          const { id, status } = action.payload;
          const updatedList = state.list.map((user: any) =>
            user._id === id
              ? {
                  ...user,
                  status: (
                    <Switch
                      checked={status === "ACTIVE"}
                      disabled={isEditIconDisabled}
                      onChange={(e) => {
                        handleStatusChange(
                          user._id,
                          e.target.checked ? "ACTIVE" : "INACTIVE",
                        );
                      }}
                    />
                  ),
                }
              : user
          );

          return {
            ...state,
            list: updatedList,
          };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(foundItemReducer, {
    _item: "",
    list: [],
    selectAll: [],
    manage: {
      isOpen: false,
      type: "view",
      item: null,
    },
    loading: true,
    searchQuery: "",
    page: 1,
    statusFilter: "",
    totalPages: 1,
    categoryFilter: "",
    claimToUserId: "",
  });

  const handleManage = (type: "new" | "edit" | "view", item: any = null) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: !state.manage.isOpen,
          item,
        },
      },
    });
  };

  const handleResolve = async (itemId: string) => {
    try {
      await resolveItem(itemId);
      enqueueSnackbar(staticText.itemResolvedSuccessfully, {
        variant: "success",
      });
      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleWarningDialog = (_item: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        resolveWarning: !state.resolveWarning,
        _item,
      },
    });
  };

  const fetchFoundItems = async (
    searchQuery: string = "",
    page: number = 1
  ) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        text: searchQuery,
        type: "FOUND",
      };

      if (state.statusFilter !== "ALL") {
        params.status = state.statusFilter;
      }

      if (state.categoryFilter !== "ALL") {
        params.categoryId = state.categoryFilter;
      }

      const foundItems = (await getItems(params)) as any;

      if (!foundItems || !foundItems.items?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }

      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: foundItems.meta.totalPages },
      });

      const list = foundItems.items.map((item: any, index: number) => {
        return createRow(item, index, handleManage, handleWarningDialog, page);
      });
      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  useEffect(() => {
    fetchFoundItems(state.searchQuery, state.page);
  }, [state.statusFilter, state.categoryFilter]);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchFoundItems(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchFoundItems(state.searchQuery, page);
  };

  const handleClose = () => {
    setShowChatListing(!showChatListing);
  };

  const closeClaimDetails = () => {
    setShowClaimListing(!showClaimListing);
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      await updateItemStatus(id, { status });
      dispatch({ type: "UPDATE_STATUS", _id: id, status });
      enqueueSnackbar(staticText.itemStatusUpdatedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: "TOGGLE_STATUS", payload: { id, status } });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      dispatch({ type: 'UPDATE_FIELDS', payload: { loading: false } });
    }
  };

  const columns = [
    {
      id: "id",
      label: staticText.sNo,
    },
    {
      id: "name",
      label: staticText.itemName,
    },
    {
      id: "image",
      label: staticText.image,
    },
    {
      id: "location",
      label: staticText.location,
    },
    {
      id: "date",
      label: staticText.foundDate,
    },
    {
      id: "claimCount",
      label: staticText.claimCount,
      align: "center",
    },
    {
      id: "status",
      label: staticText.status,
    },
    {
      id: "action",
      label: staticText.action,
    },
  ];

  const statusFilter = [
    {
      label: staticText.all,
      value: "ALL",
    },
    {
      label: staticText.active,
      value: "ACTIVE",
    },
    {
      label: staticText.inactive,
      value: "INACTIVE",
    },
  ];

  const createRow = (
    item: any,
    index: number,
    onEdit: any,
    onResolve: any,
    page: number
  ) => {
    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={(e: any) => {
          e.stopPropagation();
          dispatch({
            type: "UPDATE_FIELDS",
            payload: {
              claimToUserId: item._user._id,
              _item: item._id,
            },
          });
          onEdit("view", item);
        }}
        sx={{ textTransform: "capitalize" }}
      >
        {item.name}
      </Typography>
    );

    const image =
      item.images?.length > 0 ? (
        <Avatar src={item.images[0]} alt="item" />
      ) : (
        <Avatar sx={{ backgroundColor: "#FFE0B5" }}>
          <CameraAltIcon sx={{ color: "#4A403A" }} />
        </Avatar>
      );

    const status = (
      <Switch
        checked={item.status === "ACTIVE"}
        disabled={isEditIconDisabled}
        onChange={(e) => {
          handleStatusChange(
            item._id,
            e.target.checked ? "ACTIVE" : "INACTIVE"
          );
        }}
      />
    );

    const action = (
      <>
        <Tooltip title={staticText.resolve}>
          <Button
            disabled={isEditIconDisabled}
            variant="outlined"
            color="primary"
            size="small"
            sx={{
              minWidth: "auto",
              padding: "4px 8px",
              textTransform: "capitalize",
            }}
            onClick={() => {
              onResolve(item._id);
            }}
          >
            {staticText.resolve}
          </Button>
        </Tooltip>
      </>
    );
    return {
      id: (page - 1) * itemsPerPage + index + 1,
      name,
      image,
      location: handleCharacterLimit(item.location?.name || "", 20),
      date: formatDate(item.eventDate),
      claimCount: (
        <Typography align="center">{item.claimCount || 0}</Typography>
      ),
      status,
      action,
      _id: item._id,
    };
  };

  const fetchChatDetails = async (payload: object) => {
    try {
      const list = await getChatListing(payload);
      if (!list || !list.length) {
        enqueueSnackbar(staticText.noChatsFound, { variant: "info" });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { userList: list, loading: false },
      });
      setShowChatListing(true);
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const getClaimRequest = async () => {
    try {
      const response = await getClaimRequestOnFoundItem(
        state?.manage?.item?._id
      );
      setClaimDetails(response);
      if (!response || !response.length) {
        enqueueSnackbar(staticText.noClaimsFound, { variant: "info" });
        return;
      }
      setShowClaimListing(true);
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticText.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <>
      {state.loading && <Loader />}
      <Header
        isEditIconDisabled={isEditIconDisabled}
        isDeleteIconDisabled={isDeleteIconDisabled}
        searchPlaceholder={staticText.searchByItemName}
        onSearch={handleSearch}
        searchInput
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === "") {
                return staticText.statusFilter;
              }
              if (selected === "ALL") {
                return staticText.all;
              }
              return selected === "ALL"
                ? staticText.all
                : selected === "ACTIVE"
                ? staticText.active
                : staticText.inactive;
            }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_FIELDS",
                payload: { statusFilter: e.target.value },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {categories && categories.length && (
          <FormControl className="ml-2" size="small">
            <Select
              size="small"
              displayEmpty
              value={state.categoryFilter}
              renderValue={(selected) => {
                if (!selected || selected === "") {
                  return staticText.categoryFilter;
                }
                if (selected === "all") {
                  return staticText.all;
                }
                const selectedCategory = categories?.find(
                  (cat: any) => cat._id === selected
                );
                return selectedCategory?.name
                  .split(" ")
                  .map(
                    (word: string) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ");
              }}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_FIELDS",
                  payload: { categoryFilter: e.target.value, page: 1 },
                })
              }
            >
              {(categories || []).map((category: any) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name
                    .split(" ")
                    .map(
                      (word: string) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticText.itemsNotAvailable}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <Manage
        open={state.manage.isOpen}
        onClose={() => handleManage("view", null)}
        title={staticText.foundItemDetails}
        maxWidth="lg"
        titlePadding="16px 24px"
        contentPadding="0px 24px"
        fullWidth={true}
        content={
          <Box>
            {/* Item Details Section */}
            <Typography
              variant="h6"
              fontSize="16px"
              color="#4A403A"
              gutterBottom
            >
              {staticText.itemDetails}
            </Typography>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.itemName}
                  </Typography>
                  <Typography component="p" fontSize="14px" color="#4A403A">
                    {state.manage.item?.name}
                  </Typography>
                </Box>

                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.location}
                  </Typography>
                  <Typography
                    component="p"
                    fontSize="14px"
                    color="#4A403A"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {state.manage.item?.location?.name}
                  </Typography>
                </Box>

                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.category}
                  </Typography>
                  <Typography
                    component="p"
                    fontSize="14px"
                    color="#4A403A"
                    sx={{ textTransform: "capitalize" }}
                  >
                    {state.manage.item?._category?.name}
                  </Typography>
                </Box>
              </Grid2>

              <Grid2 size={{ xs: 12, sm: 6 }} sx={{ height: "100%" }}>
                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.name}
                  </Typography>
                  <Typography component="p" fontSize="14px" color="#4A403A">
                    {state.manage.item?._user?.name}
                  </Typography>
                </Box>

                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.emailAddress}
                  </Typography>
                  <Typography component="p" fontSize="14px" color="#4A403A">
                    {state.manage.item?._user?.email || "N/A"}
                  </Typography>
                </Box>

                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.phoneNumber}
                  </Typography>
                  <Typography component="p" fontSize="14px" color="#4A403A">
                    {`+${state.manage.item?._user?.phone?.prefix} ${state.manage.item?._user?.phone?.number}` ||
                      "N/A"}
                  </Typography>
                </Box>

                <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.foundDate}
                  </Typography>
                  <Typography component="p" fontSize="14px" color="#4A403A">
                    {formatDate(state.manage.item?.eventDate)}
                  </Typography>
                </Box>
              </Grid2>
            </Grid2>

            <Box marginBottom="24px">
                  <Typography component="p" fontSize="12px" color="#776E65">
                    {staticText.description}
                  </Typography>
                  <Typography component="p" fontSize="14px" color="#4A403A">
                    {state.manage.item?.description || "N/A"}
                  </Typography>
                </Box>

            <Typography
              variant="h6"
              fontSize="16px"
              color="#4A403A"
              gutterBottom
            >
              {staticText.questionDetails}
            </Typography>
            <Grid2 container spacing={2}>
              <Grid2 size={{ xs: 12, sm: 6 }}>
                {state.manage.item?.questions?.map((question: any) => (
                  <Box key={question._question?._id} marginBottom="24px">
                    <Typography component="p" fontSize="12px" color="#776E65">
                      {language === "en"
                        ? question._question?.label?.en
                        : question._question?.label?.ar}
                    </Typography>
                    <Typography component="p" fontSize="14px" color="#4A403A">
                      {question?.value || "N/A"}
                    </Typography>
                  </Box>
                ))}
              </Grid2>
            </Grid2>

            {/* Uploaded Images Section */}
            {state.manage.item?.images &&
              state.manage.item.images.length > 0 && (
                <Box mt={4}>
                  <Typography
                    variant="h6"
                    fontSize="16px"
                    color="#4A403A"
                    gutterBottom
                  >
                    {staticText.uploadedImages}
                  </Typography>
                  <Box display="flex" gap="8px">
                    {state.manage.item?.images?.map(
                      (imageUrl: string, index: number) => (
                        <img
                          key={index}
                          src={imageUrl}
                          alt={`Item ${index + 1}`}
                          style={{
                            width: "60px",
                            height: "60px",
                            borderRadius: "8px",
                          }}
                        />
                      )
                    )}
                  </Box>
                </Box>
              )}
            <Box
              display="flex"
              gap="16px"
              justifyContent="end"
              width="100%"
              paddingBottom="24px"
            >
              <Button
                sx={{
                  alignSelf: "flex-end",
                }}
                onClick={() => {
                  fetchChatDetails({
                    itemId: state._item,
                    claimToUserId: state.claimToUserId,
                  });
                  handleManage("view", null);
                }}
              >
                {staticText.seeChats}
              </Button>
              <Button
                onClick={() => {
                  handleManage("view", null);
                  getClaimRequest();
                }}
              >
                {staticText.seeClaims}
              </Button>
            </Box>
          </Box>
        }
      />

      <WarningDialog
        isOpen={state.resolveWarning}
        onClose={() => handleWarningDialog(state._item || "")}
        onConfirm={() => handleResolve(state._item)}
        title={staticText.resolveItem}
        description={staticText.areYouSureDoYouWantToResolveThisItem}
      />

      <ChatDetails
        itemId={state._item}
        claimToUserId={state.claimToUserId}
        userList={state.userList}
        showChatListing={showChatListing}
        handleClose={handleClose}
      />

      {claimDetails && claimDetails.length && (
        <ClaimDetails
          showClaimListing={showClaimListing}
          handleClose={closeClaimDetails}
          claimDetails={claimDetails}
        />
      )}
    </>
  );
};

export default withPermission(FoundItems, PERMISSIONS.VIEW_FOUND_ITEMS);
