import "./style.scss";
import { Box, Typography, TextField, Button } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
import { httpRequest } from "../../../services/Network";
import { Controller, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../constants";
import { useSelector } from "react-redux";

const ResetPassword = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      confirmPassword: "",
    },
  });

  const { email, code } = useParams();

  useEffect(() => {
    window.history.replaceState({}, document.title, "/reset-password");
  }, []);

  const handleResetPassword = async () => {
    try {
      const { password, confirmPassword } = getValues();

      await httpRequest("POST", "/user/public/verify-reset-password-code", {
        email,
        code,
        password,
        confirmPassword,
      });

      enqueueSnackbar(staticTexts.passwordResetSuccessfully, {
        variant: "success",
      });

      navigate("/login");
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <div className="center" id="forgot-password">
      <Box className="border forgot-password-box">
        <img
          width={100}
          height={100}
          alt="logo"
          src={Logo}
          style={{ objectFit: "contain" }}
        />

        <Typography className="my-4" variant="h4">
          Reset Password
        </Typography>
        <form className="h-100" onSubmit={handleSubmit(handleResetPassword)}>
          <Controller
            name="password"
            control={control}
            rules={{
              required: staticTexts.passwordIsRequired,
              minLength: {
                value: 6,
                message: staticTexts.passwordMustBeAtLeast8Characters,
              },
            }}
            render={({ field }) => (
              <TextField
                label="Password"
                type="password"
                className="email-adornment"
                error={!!errors.password}
                helperText={errors.password?.message || " "}
                {...field}
                sx={{ marginBottom: "8px" }}
              />
            )}
          />
          <Controller
            name="confirmPassword"
            control={control}
            rules={{
              required: staticTexts.confirmPasswordIsRequired,
              minLength: {
                value: 6,
                message: staticTexts.passwordMustBeAtLeast8Characters,
              },
            }}
            render={({ field }) => (
              <TextField
                label="Confirm Password"
                type="password"
                className="password-adornment"
                error={!!errors.confirmPassword}
                helperText={errors.confirmPassword?.message || " "}
                {...field}
                sx={{ marginBottom: "8px" }}
              />
            )}
          />
          <Button type="submit" sx={{ width: "90%", marginTop: "8px" }}>
            Reset Password
          </Button>
        </form>
      </Box>
    </div>
  );
};

export default ResetPassword;
