import { httpFormRequest, httpRequest } from "../Network"

export const upload = (file: any = {}) => {
  return httpFormRequest("/upload/v1/media/upload", file)
    .then((response: any) => {
      return response.data
    })
    .catch((err) => {
      console.log(err)
    })
};

export const login = (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const login = await httpRequest("POST", "/user/public/login", payload) as any;
    resolve(login?.data);
  } catch (error) {
    reject(error);
  }
})

export const changePassword = (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const change = await httpRequest("POST", "/user/auth/change-password", payload) as any;
    resolve(change?.data);
  } catch (error) {
    reject(error);
  }
});

export const logout = () => new Promise(async (resolve, reject) => {
  try {
    const logout = await httpRequest("POST", "/user/auth/logout") as any;
    resolve(logout?.data);
  } catch (error) {
    reject(error);
  }
});

export const resetPassword = async (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const reset = await httpRequest("POST", "/user/public/reset-password", payload) as any;
    resolve(reset?.data);
  } catch (error) {
    reject(error);
  }
});

export const getCustomers = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const users = await httpRequest("GET", "/user/admin/customers", {}, params) as any;
    resolve(users?.data);
  } catch (error) {
    reject(error);
  }
});

export const handleCharacterLimit = (text: string, maxLength: number) => {
  if (text?.length > maxLength) {
    return text.substring(0, maxLength) + '...';
  }
  return text;
};

export const getImpersonateToken = async (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const token = await httpRequest("POST", "/user/admin/generate-impersonate-token", payload) as any;
    resolve(token?.data);
  } catch (error) {
    reject(error);
  }
});

export const validateEmail = (email: string) => {
  const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return emailRegex.test(email);
};

export const validatePhone = (phone: string) => {
  const phoneRegex = /^[0-9]{10}$/;
  return phoneRegex.test(phone);
};
