import React from "react";
import { Divider, Typography } from "@mui/material";
import { LocationOn, CalendarMonth } from "@mui/icons-material";
import { formatDate } from "../../../services/utils";

interface LocationInfoProps {
  location: Location;
  eventDate: string;
}

const LocationInfo: React.FC<LocationInfoProps> = ({ location, eventDate }) => {
  return (
    <>
      <div className="location-info">
        <LocationOn />
        <Typography
          variant="body2"
          sx={{ wordBreak: "break-word", textTransform: "capitalize" }}
        >
          {(location as any).name}
        </Typography>
      </div>
      <br />
      <div className="location-info">
        <CalendarMonth />
        <Typography variant="body2">{formatDate(eventDate)}</Typography>
      </div>
    </>
  );
};

export default LocationInfo;
