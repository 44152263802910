import { FC, ReactNode } from "react";
import Button from "@mui/material/Button";
import { styled } from "@mui/material/styles";
import Dialog from "@mui/material/Dialog";
import DialogTitle from "@mui/material/DialogTitle";
import DialogContent from "@mui/material/DialogContent";
import DialogActions from "@mui/material/DialogActions";
import IconButton from "@mui/material/IconButton";
import CloseIcon from "@mui/icons-material/Close";
import { getStaticTexts } from "../../../constants";
import STORE from "../../../redux/store/index";

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
  "& .MuiDialogContent-root": {
    padding: theme.spacing(2),
  },
  "& .MuiDialogActions-root": {
    padding: theme.spacing(1),
  },
}));

const language = STORE.getState().profile.defaultLanguage;
const staticTexts = getStaticTexts(language);

export interface DialogTitleProps {
  id: string;
  children?: ReactNode;
  onClose: () => void;
}

function BootstrapDialogTitle(props: DialogTitleProps) {
  const { children, onClose, ...other } = props;
  const language = STORE.getState().profile.defaultLanguage;

  return (
    <DialogTitle sx={{ m: 0, p: 2 }} {...other}>
      {children}
      {onClose ? (
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{
            position: "absolute",
            ...(language === "ar" ? { left: 8 } : { right: 8 }),
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
      ) : null}
    </DialogTitle>
  );
}

interface props {
  size?: "xs" | "sm" | "md" | "lg" | "xl";
  title: string;
  isOpen: boolean;
  disabled?: boolean;
  onEdit?: () => void;
  onClose: () => void;
  onConfirm?: () => void;
  children: ReactNode;
  outlinedBtnText?: string;
  btnText?: string;
  customStyles?: any;
}

export const CustomDialog: FC<props> = ({
  size,
  title,
  isOpen,
  disabled,
  onEdit,
  onClose,
  onConfirm,
  children,
  outlinedBtnText = staticTexts.cancel,
  btnText = staticTexts.saveChanges,
  customStyles,
}) => {
  return (
    <div>
      <BootstrapDialog
        maxWidth={size}
        onClose={() => onClose()}
        aria-labelledby="customized-dialog-title"
        open={isOpen}
        scroll="paper"
        sx={{
          ...customStyles,
        }}
      >
        <BootstrapDialogTitle
          id="customized-dialog-title"
          onClose={() => onClose()}
        >
          {title}
        </BootstrapDialogTitle>
        <DialogContent dividers>{children}</DialogContent>
        {!disabled && (
          <DialogActions>
            <Button variant="outlined" size="medium" onClick={() => onClose()}>
              {outlinedBtnText}
            </Button>
            <Button
              size="medium"
              type="button"
              autoFocus
              onClick={() => onConfirm && onConfirm()}
            >
              {btnText}
            </Button>
          </DialogActions>
        )}
      </BootstrapDialog>
    </div>
  );
};

CustomDialog.defaultProps = {
  size: "md",
  disabled: false,
  onClose: () => {},
  onConfirm: () => {},
};
