import { ChangeEvent, useEffect, useReducer, useState } from "react";
import { Avatar, Box, Typography } from "@mui/material";
import CameraAltIcon from "@mui/icons-material/CameraAlt";
import { Header, CustomTable } from "../../components";
import { withPermission } from "../../components/hoc";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { ChatStates } from "../../interfaces";
import { getChatItems } from "../../services/aggregation";
import { enqueueSnackbar } from "notistack";
import Loader from "../../components/Loader";
import { useSelector } from "react-redux";
import ChatDetails from "../../components/ChatDetails";
import { getChatListing } from "../../services/chats";
import { formatDate } from "../../services/utils";

const Chat = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const chatReducer = (state: ChatStates, action: any): ChatStates => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const fetchChatItems = async (searchQuery: string = "", page: number = 1) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        text: searchQuery,
      };

      const chatItems = (await getChatItems(params)) as any;

      if (!chatItems || !chatItems?.payload?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: chatItems.meta.totalPages },
      });

      const list = chatItems.payload.map((item: any, index: number) =>
        createRow(item, index, page)
      );

      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const [state, dispatch] = useReducer(chatReducer, {
    manage: {
      type: "new",
      isOpen: false,
      item: null,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _item: "",
    list: [],
    selectAll: [],
    loading: true,
    searchQuery: "",
    page: 1,
    totalPages: 1,
    userList: [],
    claimToUserId: "",
  });

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const [showClaimListing, setShowClaimListing] = useState(false);
  const handleClose = () => {
    setShowClaimListing(!showClaimListing);
  };

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchChatItems(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchChatItems(state.searchQuery, page);
  };

  useEffect(() => {
    fetchChatItems(state.searchQuery, state.page);
  }, []);

  const columns = [
    {
      id: "id",
      label: staticTexts.serialNumber,
    },
    {
      id: "name",
      label: staticTexts.name,
    },
    {
      id: "images",
      label: staticTexts.images,
    },
    {
      id: "founderName",
      label: staticTexts.founderName,
    },
    {
      id: "claimChatsCount",
      label: staticTexts.claimChatsCount,
      align: "center",
    },
    {
      id: "foundDate",
      label: staticTexts.foundDate,
    },
  ];
  const createRow = (item: any, index: number, page: number) => {
    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={(e: any) => {
          e.stopPropagation();
          dispatch({
            type: "UPDATE_FIELDS",
            payload: {
              claimToUserId: item._user._id,
              _item: item._item,
              loading: true,
            },
          });
          fetchChatDetails({
            itemId: item._item,
            claimToUserId: item._user._id,
          });
        }}
      >
        {item.itemName}
      </Typography>
    );

    const images =
      item.images?.length > 0 ? (
        <Avatar src={item.images[0]} alt="item" />
      ) : (
        <Avatar sx={{ backgroundColor: "#FFE0B5" }}>
          <CameraAltIcon sx={{ color: "#4A403A" }} />
        </Avatar>
      );

    return {
      id: (page - 1) * itemsPerPage + index + 1,
      name,
      images,
      founderName: item._user.name,
      claimChatsCount: item.claimChatsCount,
      foundDate: formatDate(item.createdAt),
    };
  };

  const fetchChatDetails = async (payload: object) => {
    try {
      const list = await getChatListing(payload);
      if (!list || !list.length) {
        enqueueSnackbar(staticTexts.noChatsFound, {
          variant: "info",
        });
        dispatch({
          type: "UPDATE_FIELDS",
          payload: { loading: false },
        });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { userList: list, loading: false },
      });
      setShowClaimListing(true);
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <>
      {/* Add Data  */}
      {state.loading && <Loader />}
      <Header
        searchPlaceholder={staticTexts.searchByItemName}
        onSearch={handleSearch}
        searchInput
      ></Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.noChatsFound}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <ChatDetails
        itemId={state._item}
        claimToUserId={state.claimToUserId}
        userList={state.userList}
        showChatListing={showClaimListing}
        handleClose={handleClose}
      />
    </>
  );
};

export default withPermission(Chat, PERMISSIONS.VIEW_CHAT);
