import "./style.scss";
import { SyntheticEvent, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Grid2, Typography, Link, TextField } from "@mui/material";
import Logo from "../../../assets/images/logo.png";
import { useForm, Controller } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts, PERMISSIONS } from "../../../constants";
import { useDispatch, useSelector } from "react-redux";
import { login, resetPassword } from "../../../services/common";

const Login = () => {
  const navigate = useNavigate();
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const [isLogin, setIsLogin] = useState(true);
  const [isLoading, setIsLoading] = useState(true);
  const dispatch = useDispatch();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      email: "",
      password: "",
      forgotPassword: false,
    },
  });

  const handleLogin = async () => {
    try {
      const { forgotPassword, ...payload } = getValues();

      if (isLogin) {
        const user = (await login({ ...payload, type: "ADMIN" })) as any;

        dispatch({
          type: "SET_PROFILE_DETAILS",
          payload: {
            name: user.user.name,
            email: user.user.email,
            profile: user.user.profile,
            isSignupNeededVerification: user.user.isSignupNeededVerification,
            phone: {
              prefix: user.user.phone.prefix,
              number: user.user.phone.number,
            },
            coverProfile: user.user.coverProfile,
            type: user.user.type,
          },
        });
        dispatch({
          type: "SET_USER_PERMISSIONS",
          payload: user.user.permissions,
        });
        enqueueSnackbar(staticTexts.loggedInSuccessfully, {
          variant: "success",
        });

        localStorage.setItem("token", user.token);

        localStorage.setItem("lang", user.defaultLanguage);

        if (localStorage.getItem("token")) {
          const permissions = user.user?.permissions;
          if (!Array.isArray(permissions)) {
            return;
          }

          // Extract base permissions
          const basePermissions = permissions.map((p) => p.split(".")[0]);

          // Check permissions and navigate accordingly
          if (basePermissions.includes("analytics")) {
            navigate("/dashboard");
          } else if (
            basePermissions.includes("individual-customer") ||
            basePermissions.includes("business-customer")
          ) {
            navigate("/customers");
          } else if (basePermissions.includes("configuration")) {
            navigate("/configurations");
          } else if (basePermissions.includes("access-control")) {
            navigate("/access-control");
          } else if (
            basePermissions.includes("lost-items") ||
            basePermissions.includes("found-items") ||
            basePermissions.includes("resolve-items")
          ) {
            navigate("/lost-found-items");
          } else if (basePermissions.includes("chat")) {
            navigate("/chat");
          } else if (basePermissions.includes("questionnaire")) {
            navigate("/questionnaire");
          } else if (basePermissions.includes("category")) {
            navigate("/items-category");
          } else {
            console.warn("No matching permission found");
          }
        }

        return;
      }

      await resetPassword({ email: payload.email, type: "ADMIN" });

      enqueueSnackbar(
        staticTexts.passwordResetLinkSentSuccessfullyOnYourEmail,
        {
          variant: "success",
        }
      );
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const userPermissions = useSelector((state: any) => state.permissions);

  useEffect(() => {
    if (localStorage.getItem("token") && Array.isArray(userPermissions) && userPermissions.length > 0) {
      // Define allowed permissions to match
      const allowedPermissions = [
        "individual-customer",
        "business-customer",
        "configuration",
        "access-control",
        "lost-items",
        "found-items",
        "resolve-items",
        "chat",
        "questionnaire",
        "category",
        "analytics",
      ];
  
      // Find the first matching permission
      const matchedPermission = userPermissions.find((perm) => {
        const basePermission = perm.split(".")[0]; // Extract base permission
        return allowedPermissions.includes(basePermission); // Check against allowed list
      });
  
      if (matchedPermission) {
        const permission = matchedPermission.split(".")[0]; // Extract base permission for navigation
  
        switch (permission) {
          case "individual-customer":
          case "business-customer":
            navigate("/customers");
            break;
          case "configuration":
            navigate("/configurations");
            break;
          case "access-control":
            navigate("/access-control");
            break;
          case "lost-items":
          case "found-items":
          case "resolve-items":
            navigate("/lost-found-items");
            break;
          case "chat":
            navigate("/chat");
            break;
          case "questionnaire":
            navigate("/questionnaire");
            break;
          case "category":
            navigate("/items-category");
            break;
          case "analytics":
            navigate("/dashboard");
            break;
          default:
            console.log("No matching navigation found for permission");
        }
      } else {
        console.log("No allowed permission found in user permissions");
      }
    }
    setIsLoading(false);
  }, [userPermissions]);
  
  
  if(isLoading) return null

  return (
    <div className="container center">
      <div className="login-box">
        <form className="h-100" onSubmit={handleSubmit(handleLogin)}>
          <Grid2 className="h-100" container>
            <Grid2
              className="h-100"
              sx={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              size={{ xs: 12, md: 6 }}
            >
              <img alt="Login" id="left-img" src={Logo} width={"50%"} />
            </Grid2>
            <Grid2 size={{ xs: 12, md: 6 }}>
              <div className="credential-box center h-100">
                <div className="mb-5 text-center">
                  <Typography variant="h4">Welcome to</Typography>
                  <Typography variant="h5" color="primary">
                    Lqeet
                  </Typography>
                </div>
                <Controller
                  name="email"
                  control={control}
                  rules={{ required: staticTexts.emailIsRequired }}
                  render={({ field }) => (
                    <TextField
                      label="Email"
                      className="mb-1"
                      {...field}
                      error={!!errors.email}
                      helperText={errors.email?.message || " "}
                    />
                  )}
                />
                {isLogin && (
                  <Controller
                    name="password"
                    control={control}
                    rules={{
                      required: staticTexts.passwordIsRequired,
                      minLength: {
                        value: 6,
                        message: staticTexts.passwordMustBeAtLeast8Characters,
                      },
                    }}
                    render={({ field }) => (
                      <TextField
                        label="Password"
                        className="mb-2"
                        type="password"
                        error={!!errors.password}
                        helperText={errors.password?.message || " "}
                        {...field}
                      />
                    )}
                  />
                )}
                <Link
                  className="mb-3 cursor-pointer"
                  sx={{ color: "#FF6F61" }}
                  width={"100%"}
                  underline="none"
                  color="secondary"
                  textAlign="left"
                  onClick={() => {
                    setIsLogin(!isLogin);
                  }}
                >
                  {isLogin ? " Forgot Password?" : "Back to Login"}
                </Link>

                <Button type="submit" className="mb-3" size="medium" fullWidth>
                  Login
                </Button>
              </div>
            </Grid2>
          </Grid2>
        </form>
      </div>
    </div>
  );
};

export default Login;
