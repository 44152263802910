import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { withPermission } from "../../components/hoc";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { useSelector } from "react-redux";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Questionnaire = () => {
  const [value, setValue] = useState(-1); // Initialize to -1 to prevent initial navigation
  const navigate = useNavigate();
  const location = useLocation();

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const redirectionLinks = ["lost", "found", "claim"];
  const tabs = [
    { label: staticTexts.lost, index: 0 },
    { label: staticTexts.found, index: 1 },
    { label: staticTexts.claim, index: 2 },
  ];

  useEffect(() => {
    const currentPath = location.pathname.split("/")[2];
    const tabIndex = redirectionLinks.indexOf(currentPath);

    if (tabIndex === -1) {
      // If no valid path, navigate to first tab
      navigate(redirectionLinks[0]);
    } else {
      setValue(tabIndex);
    }
  }, [location.pathname, navigate]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              className="tabs-space"
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
      </Box>
      {value >= 0 && <Outlet />}
    </Box>
  );
};

export default withPermission(Questionnaire, PERMISSIONS.VIEW_QUESTIONNAIRE);
