import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";

import { getStaticTexts, PERMISSIONS } from "../../constants";
import { useSelector } from "react-redux";
import { withPermission } from "../../components/hoc";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const LostFoundItems = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();
  const userPermissions = useSelector((state: any) => state.permissions);

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const tabs = [
    userPermissions.includes(PERMISSIONS.VIEW_LOST_ITEMS) && {
      label: staticTexts.lostItems,
      index: 0,
      link: "lost-items",
    },
    userPermissions.includes(PERMISSIONS.VIEW_FOUND_ITEMS) && {
      label: staticTexts.foundItems,
      index: 1,
      link: "found-items",
    },
    userPermissions.includes(PERMISSIONS.VIEW_RESOLVED_ITEMS) && {
      label: staticTexts.resolvedItems,
      index: 2,
      link: "resolved-items",
    },
  ].filter(Boolean);

  useEffect(() => {
    const currentPath = location.pathname.split("/")[2];
    const tabIndex = tabs.findIndex((tab) => tab.link === currentPath);
    setValue(tabIndex >= 0 ? tabIndex : 0);
  }, [location.pathname, tabs]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(tabs[newValue].link);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {tabs.map((tab, key) => (
            <Tab
              key={key}
              className="tabs-space"
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default withPermission(LostFoundItems, PERMISSIONS.VIEW_LOST_ITEMS);
