import { Navigate } from 'react-router-dom';

interface Props {
  element: JSX.Element;
}

export const AuthGuard = ({ element }: Props) => {
  const token = localStorage.getItem('token');
  return <>{token ? element : <Navigate to={'/login'} />}</>;
};
