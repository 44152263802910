import { FC, ChangeEvent, useEffect, useReducer, useState } from "react";
import { CustomDialog } from "../../../../components";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import "./index.scss";
import { getStaticTexts } from "../../../../constants";
import { Controller, FieldError, useForm } from "react-hook-form";
import { upload } from "../../../../services/common";
import { enqueueSnackbar } from "notistack";
import { updateCustomer } from "../../../../services/customer";
import { PhoneNumberInput } from "../../../../components/mui/PhoneNumberInput";
import { useSelector } from "react-redux";
import Loader from "../../../../components/Loader";

interface props {
  isOpen: boolean;
  type: "new" | "edit" | "view";
  user: any;
  onClose: () => void;
  onSave: () => void;
}

const Manage: FC<props> = ({ isOpen, type, user, onClose, onSave }) => {
  const isDisabled = type === "view";

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const [loading, setLoading] = useState(false);

  const theme = useTheme();

  const [profileImageUrl, setProfileImageUrl] = useState(user?.profile || "");
  const [countryCode, setCountryCode] = useState(
    `+${user?.phone?.prefix}` || "+971"
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      prefix: "",
      profile: "",
      gender: "",
      address: "",
      description: "",
      password: "",
      confirmPassword: "",
    },
  });

  const handleSave = () => {
    onSave();
  };

  const uploadImage = async (e: any) => {
    try {
      const file = e.target.files[0];

      setLoading(true);
      // Prepare File to Upload
      const maxSize = 3 * 1024 * 1024; // 3MB
      const maxDimension = 1024; // Max width/height in pixels

      // Show File Selection Error
      if (!file) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile);
        return;
      }

      // Show File Size Error
      const fileSize = file.size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan3MB);
        return;
      }

      // Create a promise to handle image processing
      const processImage = () => {
        return new Promise((resolve, reject) => {
          const img = new Image();
          const objectUrl = URL.createObjectURL(file);

          img.onload = async () => {
            URL.revokeObjectURL(objectUrl);

            try {
              if (img.width > maxDimension || img.height > maxDimension) {
                // Create canvas to resize image
                const canvas = document.createElement("canvas");
                let width = img.width;
                let height = img.height;

                if (width > height) {
                  if (width > maxDimension) {
                    height = Math.round((height * maxDimension) / width);
                    width = maxDimension;
                  }
                } else {
                  if (height > maxDimension) {
                    width = Math.round((width * maxDimension) / height);
                    height = maxDimension;
                  }
                }

                canvas.width = width;
                canvas.height = height;

                const ctx = canvas.getContext("2d");
                ctx?.drawImage(img, 0, 0, width, height);

                // Convert canvas to blob
                canvas.toBlob(
                  async (blob) => {
                    if (blob) {
                      const profileUrl = await upload(blob);
                      if (profileUrl) {
                        setProfileImageUrl(profileUrl);
                        setValue("profile", profileUrl);
                        resolve(true);
                      }
                    } else {
                      reject(new Error("Failed to create blob"));
                    }
                  },
                  "image/jpeg",
                  0.9
                );
              } else {
                const profileUrl = await upload(file);
                if (profileUrl) {
                  setProfileImageUrl(profileUrl);
                  setValue("profile", profileUrl);
                  resolve(true);
                }
              }
            } catch (error) {
              reject(error);
            }
          };

          img.onerror = () => {
            URL.revokeObjectURL(objectUrl);
            reject(new Error("Failed to load image"));
          };

          img.src = objectUrl;
        });
      };

      await processImage();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong
      );
    } finally {
      setLoading(false);
    }
  };

  useEffect(() => {
    setValue("prefix", `+${user?.phone?.prefix}`);
    setValue("phone", user?.phone?.number);
    setValue("name", user?.name);
    setValue("email", user?.email);
    setValue("address", user?.address);
    setValue("profile", user?.profile);
    setValue("description", user?.description);
    setValue("gender", user?.gender);
    setProfileImageUrl(user?.profile);
    setCountryCode(`+${user?.phone?.prefix}`);
  }, [type, user]);

  const handleUpdateProfile = async () => {
    try {
      const payload = {
        name: getValues("name"),
        email: getValues("email"),
        phone: {
          prefix: getValues("prefix").replace(/^\++/, ""),
          number: getValues("phone"),
        },
        description: getValues("description"),
        address: getValues("address"),
        profile: profileImageUrl,
        gender: getValues("gender"),
      };

      (await updateCustomer(user._id, payload)) as any;

      enqueueSnackbar(staticTexts.customerProfileUpdatedSuccessfully, {
        variant: "success",
      });

      // onClose();
      handleSave();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const genderOptions = [
    { label: staticTexts.male, value: "MALE" },
    { label: staticTexts.female, value: "FEMALE" },
    { label: staticTexts.others, value: "OTHERS" },
  ];

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit(handleUpdateProfile)}>
        <CustomDialog
          title={staticTexts.userDetails}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleSubmit(handleUpdateProfile)}
          disabled={isDisabled}
          onEdit={handleUpdateProfile}
        >
          <Box>
            {(type === "edit" || type === "new") && (
              <>
                <Grid2 container spacing={4}>
                  <Grid2 size={{ xs: 12, md: 12 }}>
                    <Box className="center" sx={{ mb: 2 }}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          position: "relative",
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                        component="label"
                      >
                        {profileImageUrl ? (
                          <>
                            <img
                              src={profileImageUrl}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "4px 0",
                              }}
                            >
                              Edit
                            </div>
                          </>
                        ) : (
                          <AccountCircleIcon
                            sx={{
                              width: 100,
                              height: 100,
                              color: theme.palette.primary.main,
                            }}
                          />
                        )}
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => uploadImage(e)}
                        />
                      </Box>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: staticTexts.nameIsRequired,
                      }}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.name}
                          error={!!errors.name}
                          helperText={errors.name?.message || " "}
                          {...field}
                          sx={{ marginBottom: "-30px" }}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: staticTexts.emailIsRequired,
                      }}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.email}
                          error={!!errors.email}
                          helperText={errors.email?.message || " "}
                          sx={{ marginBottom: "-30px" }}
                          {...field}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="gender"
                      control={control}
                      rules={{
                        required: staticTexts.genderIsRequired,
                      }}
                      render={({ field }) => (
                        <FormControl sx={{ width: "100%" }}>
                          <InputLabel id="demo-simple-select-label">
                            {staticTexts.gender}
                          </InputLabel>
                          <Select
                            labelId="demo-simple-select-label"
                            id="demo-simple-select"
                            value={field.value}
                            label={staticTexts.gender}
                            onChange={(e) => field.onChange(e.target.value)}
                            disabled={isDisabled}
                            sx={{
                              marginRight: "8px",
                              maxHeight: "150px",
                              "& .MuiSelect-select": {
                                paddingTop: "8px",
                                color: "#4A403A",
                                fontSize: "14px",
                              },
                            }}
                          >
                            {genderOptions.map((option) => (
                              <MenuItem key={option.value} value={option.value}>
                                {option.label}
                              </MenuItem>
                            ))}
                          </Select>
                          {errors.gender && (
                            <FormHelperText error>
                              {errors.gender.message}
                            </FormHelperText>
                          )}
                        </FormControl>
                      )}
                    />
                  </Grid2>

                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <PhoneNumberInput
                      name="phone"
                      label={staticTexts.phone}
                      helperText={errors.phone?.message as string}
                      countryCode={countryCode}
                      control={control}
                      error={errors.phone as FieldError}
                      handleCountryCodeChange={(e) => {
                        setValue("prefix", e.target.value);
                        setCountryCode(e.target.value);
                      }}
                      validationRules={{
                        required: staticTexts.phoneIsRequired,
                      }}
                    />
                  </Grid2>
                </Grid2>
              </>
            )}
          </Box>
        </CustomDialog>
      </form>
    </>
  );
};

Manage.defaultProps = {
  user: {},
};

export default Manage;
