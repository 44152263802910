import { httpRequest } from '../Network';

export const getChatListing = (payload: object) => {
  return httpRequest('POST', '/chat/admin/claim-chats-on-item', payload)
    .then((response: any) => response.data)
    .catch((error) => {
      throw error;
    });
};

export const getChatMessages = (page: number, limit: number, payload: object) => {

  return httpRequest('POST', `/chat/admin/conversations-history-on-item?page=${page}&limit=${limit}`, payload)
    .then((response: any) => response.data)
    .catch((error) => {
      throw error;
    });
};
