import { Controller, useForm } from "react-hook-form";
import "./style.scss";
import { Box, Button, Grid2, TextField } from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../constants";
import { useNavigate } from "react-router-dom";
import { changePassword } from "../../../services/common";
import { useSelector } from "react-redux";

const ChangePassword = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const navigate = useNavigate();

  const {
    control,
    handleSubmit,
    getValues,
    formState: { errors },
  } = useForm({
    defaultValues: {
      newPassword: "",
      oldPassword: "",
      confirmPassword: "",
    },
  });

  const handleChangePassword = async () => {
    try {
      const { newPassword, oldPassword, confirmPassword } = getValues();

      await changePassword({
        newPassword,
        oldPassword,
        confirmPassword,
      });

      enqueueSnackbar(staticTexts.passwordChangedSuccessfully, {
        variant: "success",
      });

      localStorage.removeItem("token");

      navigate("/login");
    } catch (error: any) {
      console.log(error);
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleChangePassword)}>
      <div id="change-password">
        <Grid2 container spacing={5}>
          <Grid2 size={{ xs: 12, md: 12 }}>
            <Controller
              name="oldPassword"
              control={control}
              rules={{
                required: staticTexts.oldPasswordIsRequired,
                minLength: {
                  value: 8,
                  message: staticTexts.passwordMustBeAtLeast8Characters,
                },
              }}
              render={({ field }) => (
                <TextField
                  label={staticTexts.oldPassword}
                  variant="outlined"
                  error={!!errors.oldPassword}
                  helperText={errors.oldPassword?.message || " "}
                  {...field}
                  sx={{ marginBottom: "-30px" }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 12 }}>
            <Controller
              name="newPassword"
              control={control}
              rules={{
                required: staticTexts.newPasswordIsRequired,
                minLength: {
                  value: 8,
                  message: staticTexts.passwordMustBeAtLeast8Characters,
                },
              }}
              render={({ field }) => (
                <TextField
                  label={staticTexts.newPassword}
                  variant="outlined"
                  error={!!errors.newPassword}
                  helperText={errors.newPassword?.message || " "}
                  {...field}
                  sx={{ marginBottom: "-30px" }}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 12 }}>
            <Controller
              name="confirmPassword"
              control={control}
              rules={{
                required: staticTexts.confirmPasswordIsRequired,
                minLength: {
                  value: 6,
                  message: staticTexts.passwordMustBeAtLeast8Characters,
                },
              }}
              render={({ field }) => (
                <TextField
                  label={staticTexts.confirmPassword}
                  variant="outlined"
                  error={!!errors.confirmPassword}
                  helperText={errors.confirmPassword?.message || " "}
                  {...field}
                />
              )}
            />
          </Grid2>
        </Grid2>

        <Box className="mt-3" textAlign="end">
          <Button type="submit" size="medium">
            {staticTexts.save}
          </Button>
        </Box>
      </div>
    </form>
  );
};

export default ChangePassword;
