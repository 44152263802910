import { FC } from "react";
import { WarningDialog } from "../../../../components";
import { getStaticTexts } from "../../../../constants";
import { useSelector } from "react-redux";

interface props {
  isOpen: boolean;
  onClose: () => void;
  userId: string;
  updateStatusCustomer: (userId: string, status: string) => void;
}

const Approval: FC<props> = ({
  isOpen = true,
  onClose,
  userId,
  updateStatusCustomer,
}) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticText = getStaticTexts(language);

  const onConfirm = () => {
    updateStatusCustomer(userId, "ACTIVE");
    onClose();
  };

  return (
    <WarningDialog
      isOpen={isOpen}
      onClose={onClose}
      onConfirm={onConfirm}
      title={staticText.approveUser}
      description={staticText.approveUserDescription}
    />
  );
};

export default Approval;
