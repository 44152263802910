import { ChangeEvent, useEffect, useReducer, useState } from 'react';
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Tooltip,
  Typography,
  SelectChangeEvent,
  Switch,
  InputLabel,
} from '@mui/material';
import { Header, CustomTable, WarningDialog } from '../../../components';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import PendingActionsIcon from '@mui/icons-material/PendingActionsTwoTone';
import Manage from './manage';
import RejectApproval from './reject-approval';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import { getCustomers, getImpersonateToken, handleCharacterLimit } from '../../../services/common';
import { enqueueSnackbar } from 'notistack';
import { getStaticTexts, PERMISSIONS } from '../../../constants';
import { deleteCustomers, updateCustomerStatus } from '../../../services/customer';
import Loader from '../../../components/Loader';
import { withPermission } from '../../../components/hoc';
import Approval from './approval';
import { useSelector } from 'react-redux';
interface state {
  manage: {
    isOpen: boolean;
    type: 'new' | 'edit' | 'view';
    user: any;
  };
  approvalModal: {
    isOpen: boolean;
  };
  rejectApprovalModal: {
    isOpen: boolean;
  };
  deleteWarning: boolean;
  multiDeleteWarning: boolean;
  _user: string;
  list: {
    all: JSX.Element;
    id: number;
    _id: string;
    name: JSX.Element;
    email: string;
    phone: string;
    verificationStatus: JSX.Element;
    action: JSX.Element;
  }[];
  selectAll: string[];
  loading: boolean;
  searchQuery: string;
  page: number;
  statusFilter: string;
  totalPages: number;
  showStatusDropdown: boolean;
  impersonateWarning: {
    isOpen: boolean;
    userId: string;
    userType: string;
  };
}

const IndividualUsers = () => {
  const individualUserReducer = (state: state, action: any) => {
    switch (action.type) {
      case 'LOADING':
        return { ...state, loading: true };
      case 'LOADED':
        return { ...state, loading: false, list: action.list };
      case 'UPDATE_FIELDS':
        return { ...state, ...action.payload };
      case 'UPDATE_STATUS':
        return { ...state, loading: true };
      case 'UPDATE_LIST':
        const userIds = action.payload;
        const filteredList = state.list.filter((user: any) => !(userIds || []).includes(user._id));
        return {
          ...state,
          list: filteredList,
        };
      case 'UPDATE_CHECKBOX': {
        const { id, isChecked } = action.payload;

        // Update the `selectAll` array
        const updatedSelectAll = isChecked ? [...state.selectAll, id] : state.selectAll.filter((ele) => ele !== id);

        // Update the list to reflect the checkbox state
        const updatedList = state.list.map((users: any) => ({
          ...users,
          all: (
            <Checkbox
              onChange={(e) => handleMultipleSelect(e, state.selectAll, users._id)}
              checked={updatedSelectAll.includes(users._id)}
            />
          ),
        }));

        return {
          ...state,
          selectAll: updatedSelectAll,
          list: updatedList,
        };
      }
      case 'TOGGLE_STATUS':
        const { id, status } = action.payload;
        const updatedList = state.list.map((user: any) =>
          user._id === id
            ? {
                ...user,
                status: (
                  <Switch
                    checked={status === 'ACTIVE'}
                    disabled={isEditIconDisabled}
                    onChange={(e) => {
                      handleStatusChange(user._id, e.target.checked ? 'ACTIVE' : 'INACTIVE');
                    }}
                  />
                ),
              }
            : user
        );
        return {
          ...state,
          list: updatedList,
        };

      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const userPermissions = useSelector((state: any) => state.permissions);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(PERMISSIONS.EDIT_INDIVIDUAL_CUSTOMER);
  const isDeleteIconDisabled = !userPermissions.includes(PERMISSIONS.DELETE_INDIVIDUAL_CUSTOMER);

  const isImpersonatePermissionEnabled = userPermissions.includes(PERMISSIONS.WRITE_IMPERSONATE);

  const fetchIndividualUsers = async (searchQuery: string = '', page: number = 1) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        type: 'INDIVIDUAL',
        text: searchQuery,
      };

      if (state.statusFilter && state.statusFilter !== 'ALL') {
        params.status = state.statusFilter;
      }

      const individualUsers = (await getCustomers(params)) as any;

      if (!individualUsers || !individualUsers.customers?.length) {
        dispatch({ type: 'LOADED', list: [] });
        return;
      }

      dispatch({
        type: 'UPDATE_FIELDS',
        payload: { totalPages: individualUsers.meta.totalPages },
      });

      const list = individualUsers.customers.map((user: any, index: number) =>
        createRow(state.selectAll, user, index, handleManage, handleWarningDialog, handleMultipleSelect, page)
      );

      dispatch({ type: 'LOADED', list });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong, {
        variant: 'error',
      });
    }
  };

  const [state, dispatch] = useReducer(individualUserReducer, {
    manage: {
      type: 'new',
      isOpen: false,
      user: null,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: '',
    list: [],
    selectAll: [],
    approvalModal: {
      isOpen: false,
    },
    rejectApprovalModal: {
      isOpen: false,
    },
    loading: true,
    searchQuery: '',
    page: 1,
    statusFilter: '',
    totalPages: 1,
    showStatusDropdown: false,
    impersonateWarning: {
      isOpen: false,
      userId: '',
      userType: '',
    },
  });

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(null);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchIndividualUsers(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { page },
    });
    fetchIndividualUsers(state.searchQuery, page);
  };

  const handleStatusFilterChange = (e: SelectChangeEvent) => {
    const newStatusFilter = e.target.value;
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        statusFilter: newStatusFilter,
        page: 1,
      },
    });
    fetchIndividualUsers(state.searchQuery, 1);
  };

  const handleStatusFilterClick = () => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: { showStatusDropdown: !state.showStatusDropdown },
    });
  };

  useEffect(() => {
    fetchIndividualUsers(state.searchQuery, state.page);
  }, [state.statusFilter]);

  const handleManage = (type: 'new' | 'edit' | 'view', user: any = null) => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        manage: {
          type,
          isOpen: true,
          user: user || null,
        },
      },
    });
  };

  const handleClose = () => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        manage: {
          ...state.manage,
          isOpen: false,
        },
      },
    });
  };

  const handleStatusChange = async (id: string, status: string, comment?: string) => {
    try {
      await updateCustomerStatus(id, { status, comment });
      dispatch({ type: 'UPDATE_STATUS' });
      enqueueSnackbar(staticTexts.customerStatusUpdatedSuccessfully, {
        variant: 'success',
      });
      // Refresh data after status change
      dispatch({ type: 'TOGGLE_STATUS', payload: { id, status } });
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong, {
        variant: 'error',
      });
    } finally {
      dispatch({ type: 'UPDATE_FIELDS', payload: { loading: false } });
    }
  };

  const handleImpersonateToken = async (userId: string, userType: String) => {
    try {
      const impersonateToken = (await getImpersonateToken({
        userId,
        userType,
      })) as any;
      const token = impersonateToken?.token;
      console.log('usetype', userType);
      window.open(
        `${process.env.REACT_APP_WEBSITE_PANEL}/home?impersonateToken=${encodeURIComponent(
          token
        )}&user-type=${userType}`,
        '_blank'
      );
    } catch (error: any) {
      enqueueSnackbar(error.response?.data?.message || error.message || staticTexts.somethingWentWrong, {
        variant: 'error',
      });
    }
  };

  const handleImpersonateWarning = (userId: string, userType: string) => {
    if (!isImpersonatePermissionEnabled) return;

    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        impersonateWarning: {
          isOpen: true,
          userId,
          userType,
        },
      },
    });
  };

  const handleImpersonateConfirm = () => {
    const { userId, userType } = state.impersonateWarning;
    handleImpersonateToken(userId, userType);
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        impersonateWarning: {
          isOpen: false,
          userId: '',
          userType: '',
        },
      },
    });
  };

  const handleImpersonateCancel = () => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        impersonateWarning: {
          isOpen: false,
          userId: '',
          userType: '',
        },
      },
    });
  };

  const handleRejectApproval = (userId: string) => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        rejectApprovalModal: {
          ...state.rejectApprovalModal,
          isOpen: !state.rejectApprovalModal.isOpen,
        },
        _user: userId,
      },
    });
  };

  const handleApproval = (userId: string) => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        approvalModal: {
          ...state.approvalModal,
          isOpen: !state.approvalModal.isOpen,
        },
        _user: userId,
      },
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const allIds = state.list.map((user: any) => user._id);

    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        selectAll: checked ? allIds : [],
      },
    });

    const updatedList = state.list.map((user: any) => ({
      ...user,
      all: (
        <Checkbox
          onChange={(e) => handleMultipleSelect(e, checked ? allIds : [], user._id)}
          checked={checked}
        />
      ),
    }));

    dispatch({ type: 'LOADED', list: updatedList });
  };

  const handleMultipleSelect = (e: ChangeEvent<HTMLInputElement>, selectAll: string[], id: string) => {
    const isChecked = e.target.checked;
    dispatch({
      type: 'UPDATE_CHECKBOX',
      payload: { id, isChecked },
    });
  };

  useEffect(() => {
    console.log(state.selectAll);
  }, [state.selectAll]);

  const handleDeleteCustomer = async (userIds: Array<string>) => {
    try {
      await deleteCustomers(userIds);
      enqueueSnackbar(staticTexts.customerDeletedSuccessfully, {
        variant: 'success',
      });
      dispatch({ type: 'UPDATE_LIST', payload: userIds });
    } catch (error: any) {
      enqueueSnackbar(error.response.data.message || error.message || staticTexts.somethingWentWrong, {
        variant: 'error',
      });
    }
  };

  const handleDelete = async (userId: string) => {
    await handleDeleteCustomer([userId]);
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        deleteWarning: false,
        _user: '',
      },
    });
  };

  const handleWarningDialog = (_user: string) => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        deleteWarning: !state.deleteWarning,
        _user,
      },
    });
  };

  const handleMutlipleWarningDialog = () => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        multiDeleteWarning: !state.multiDeleteWarning,
        selectAll: state.selectAll,
      },
    });
  };

  const handleMultiDelete = async (userIds: Array<string> = []) => {
    if (userIds.length > 0) {
      await handleDeleteCustomer(userIds);
      dispatch({
        type: 'UPDATE_FIELDS',
        payload: {
          multiDeleteWarning: false,
        },
      });
    }
  };

  const onRejectApprovalClose = () => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        rejectApprovalModal: {
          isOpen: false,
        },
        _user: '',
      },
    });
  };

  const onApprovalClose = () => {
    dispatch({
      type: 'UPDATE_FIELDS',
      payload: {
        approvalModal: {
          isOpen: false,
        },
        _user: '',
      },
    });
  };

  const columns = [
    {
      id: 'all',
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={state.selectAll?.length > 0 && state.list?.length === state.selectAll?.length}
          indeterminate={state.selectAll?.length > 0 && state.list?.length !== state.selectAll?.length}
        />
      ),
    },
    {
      id: 'id',
      label: staticTexts.sNo,
    },
    {
      id: 'name',
      label: staticTexts.name,
    },
    {
      id: 'email',
      label: staticTexts.email,
    },
    {
      id: 'phone',
      label: staticTexts.phone,
    },
    {
      id: 'verificationStatus',
      label: staticTexts.verificationStatus,
    },
    {
      id: 'status',
      label: staticTexts.status,
    },
    {
      id: 'action',
      label: staticTexts.action,
    },
  ];

  const statusFilter = [
    {
      label: staticTexts.all,
      value: 'ALL',
    },
    {
      label: staticTexts.active,
      value: 'ACTIVE',
    },
    {
      label: staticTexts.inactive,
      value: 'INACTIVE',
    },
  ];

  const closeManagerModal = () => {};

  const createRow = (
    selectAll: string[],
    user: any,
    index: number,
    onEdit: any,
    onDelete: any,
    onMultiDelete: any,
    page: number
  ) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, state.selectAll, user._id)}
        checked={state.selectAll.includes(user._id)}
      />
    );

    const name = (
      <Typography
        className='cursor-pointer'
        color='primary'
        variant='body1'
        onClick={() => handleImpersonateWarning(user._id, user.type)}
        sx={{ textTransform: 'capitalize' }}
      >
        {user.name}
      </Typography>
    );

    const verificationStatus =
      user.status === 'REJECTED' ? (
        <IconButton
          disabled={isEditIconDisabled}
          color='error'
          onClick={() => handleApproval(user._id)}
        >
          <HighlightOffIcon />
        </IconButton>
      ) : user.status === 'PENDING' ? (
        <IconButton
          className='d'
          disabled={isEditIconDisabled}
          onClick={() => handleRejectApproval(user._id)}
        >
          <PendingActionsIcon />
        </IconButton>
      ) : (
        <IconButton>
          <CheckCircleIcon color='primary' />
        </IconButton>
      );

    const status =
      user.status === 'ACTIVE' || user.status === 'INACTIVE' ? (
        <Switch
          checked={user.status === 'ACTIVE'}
          disabled={isEditIconDisabled}
          onChange={(e) => {
            handleStatusChange(user._id, e.target.checked ? 'ACTIVE' : 'INACTIVE');
          }}
        />
      ) : null;

    const action = (
      <>
        <Tooltip title='Edit'>
          <IconButton
            onClick={() => onEdit('edit', user)}
            color='primary'
            disabled={isEditIconDisabled}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='Delete'>
          <IconButton
            onClick={() => handleWarningDialog(user._id)}
            color='error'
            disabled={isDeleteIconDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: (page - 1) * itemsPerPage + index + 1,
      _id: user._id,
      name,
      email: handleCharacterLimit(user.email, 30),
      phone:
        language === 'ar'
          ? '+' + user.phone.prefix + ' ' + user.phone.number.split('').reverse().join('')
          : '+' + user.phone.prefix + ' ' + user.phone.number,
      status,
      verificationStatus,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      {state.loading && <Loader />}
      <Header
        isEditIconDisabled={isEditIconDisabled}
        isDeleteIconDisabled={isDeleteIconDisabled}
        searchPlaceholder={staticTexts.searchByUserNameEmailAndPhone}
        onSearch={handleSearch}
        onBtnClick={() => handleManage('new')}
        onDelete={(e) => handleMutlipleWarningDialog()}
        isDeleteDisable={state.selectAll.length ? false : true}
        searchInput
      >
        <FormControl
          className='ml-2'
          size='small'
        >
          <Select
            size='small'
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === '') {
                return staticTexts.statusFilter;
              }
              if (selected === 'ALL') {
                return staticTexts.all;
              }
              return selected === 'ALL'
                ? staticTexts.all
                : selected === 'ACTIVE'
                ? staticTexts.active
                : staticTexts.inactive;
            }}
            onChange={(e) => {
              dispatch({
                type: 'UPDATE_FIELDS',
                payload: { statusFilter: e.target.value, page: 1 },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem
                key={filter.value}
                value={filter.value}
              >
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop='10px'>
        <CustomTable
          columns={columns}
          rows={state.list}
          height='calc(100vh - 245px)'
          errorMessage={staticTexts.addUserToSeeDataHere}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        user={state.manage.user}
        onClose={handleClose}
        onSave={() => {
          fetchIndividualUsers(state.searchQuery, state.page);
          handleClose();
        }}
      />

      {/* Reject/Approve User  */}
      <RejectApproval
        isOpen={state.rejectApprovalModal.isOpen}
        onClose={onRejectApprovalClose}
        userId={state._user}
        updateStatusCustomer={handleStatusChange}
      />

      <Approval
        isOpen={state.approvalModal.isOpen}
        onClose={onApprovalClose}
        userId={state._user}
        updateStatusCustomer={handleStatusChange}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleWarningDialog(state._user || '')}
        onConfirm={() => handleDelete(state._user)}
        title={staticTexts.deleteUser}
        description={staticTexts.areYouSureYouWantToDeleteThisUser}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMutlipleWarningDialog()}
        onConfirm={() => handleMultiDelete(state.selectAll)}
        title={staticTexts.deleteUsers}
        description={staticTexts.areYouSureYouWantToDeleteThisUsers}
      />

      {/* Impersonate Warning Dialog */}
      <WarningDialog
        isOpen={state.impersonateWarning.isOpen}
        onClose={handleImpersonateCancel}
        onConfirm={handleImpersonateConfirm}
        title={staticTexts.impersonateUser}
        description={staticTexts.areYouSureYouWantToImpersonateThisUser}
      />
    </>
  );
};

export default withPermission(IndividualUsers, PERMISSIONS.VIEW_INDIVIDUAL_CUSTOMER);
