import { FC } from "react";
import {
  Box,
  IconButton,
  Typography,
  useTheme,
  Divider,
  Grid,
} from "@mui/material";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { enqueueSnackbar } from "notistack";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getStaticTexts } from "../../../constants";
import { useSelector } from "react-redux";

interface Props {
  report: {
    _reportedUser: {
      name: string;
      email: string;
      profile?: string;
      phone: {
        prefix: string;
        number: string;
      };
    };
    _reportedBy: {
      name: string;
      email: string;
      phone: {
        prefix: string;
        number: string;
      };
    };
    remark: string;
    comment: string;
    status: string;
    createdAt: string;
    updatedAt: string;
  };
}

const Details: FC<Props> = ({ report }) => {
  const theme = useTheme();
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const handleCopy = (text: string, type: "email" | "phone") => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(
      `${type === "email" ? staticTexts.email : staticTexts.phone} ${
        staticTexts.copied
      }!`,
      {
        variant: "success",
      }
    );
  };

  const UserDetails = ({
    title,
    user,
  }: {
    title: string;
    user: {
      name: string;
      email: string;
      profile?: string;
      phone: {
        prefix: string;
        number: string;
      };
    };
  }) => (
    <Box
      sx={{
        padding: 2,
        borderRadius: 2,
        boxShadow: theme.shadows[1],
        backgroundColor: theme.palette.background.paper,
        textAlign: "center",
      }}
    >
      <Typography
        variant="subtitle1"
        sx={{
          fontWeight: 600,
          marginBottom: 1,
          color: theme.palette.text.primary,
        }}
      >
        {title}
      </Typography>

      <Box sx={{ mb: 2 }}>
        {user.profile ? (
          <img
            src={user.profile}
            alt="Profile"
            style={{
              width: 80,
              height: 80,
              objectFit: "cover",
              borderRadius: "50%",
              border: `2px solid ${theme.palette.primary.main}`,
            }}
          />
        ) : (
          <AccountCircleIcon
            sx={{
              width: 80,
              height: 80,
              color: theme.palette.primary.main,
            }}
          />
        )}
      </Box>

      <Typography
        variant="h6"
        sx={{ mb: 1, color: theme.palette.text.primary }}
      >
        {user.name}
      </Typography>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {user.phone.prefix && user.phone.number
            ? `+${user.phone.prefix} ${user.phone.number}`
            : staticTexts.phoneNotAvailable}
        </Typography>
        {user.phone.prefix && user.phone.number && (
          <IconButton
            size="small"
            onClick={() =>
              handleCopy(`${user.phone.prefix}${user.phone.number}`, "phone")
            }
            sx={{ color: theme.palette.primary.main }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
      </Box>

      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "center",
          gap: 1,
        }}
      >
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {user.email || staticTexts.emailNotAvailable}
        </Typography>
        {user.email && (
          <IconButton
            size="small"
            onClick={() => handleCopy(user.email, "email")}
            sx={{ color: theme.palette.primary.main }}
          >
            <ContentCopyIcon fontSize="small" />
          </IconButton>
        )}
      </Box>
    </Box>
  );

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2,
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Grid container spacing={2}>
        <Grid item xs={12} md={6}>
          <UserDetails
            title={staticTexts.reportedUser}
            user={report._reportedUser}
          />
        </Grid>

        <Grid item xs={12} md={6}>
          <UserDetails
            title={staticTexts.reportedBy}
            user={report._reportedBy}
          />
        </Grid>
      </Grid>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            marginBottom: 1,
            color: theme.palette.text.primary,
          }}
        >
          {staticTexts.comment}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {report.comment}
        </Typography>
      </Box>

      <Divider sx={{ my: 2 }} />

      <Box>
        <Typography
          variant="subtitle1"
          sx={{
            fontWeight: 600,
            marginBottom: 1,
            color: theme.palette.text.primary,
          }}
        >
          {staticTexts.remark}
        </Typography>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary }}
        >
          {report.remark}
        </Typography>
      </Box>
    </Box>
  );
};

export default Details;
