import { FC, ChangeEvent, useEffect, useReducer, useState } from "react";
import {
  Box,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import Details from "../details";
import { enqueueSnackbar } from "notistack";
import { Controller, useForm, FieldError, set } from "react-hook-form";
import { getStaticTexts } from "../../../constants";
import { upload } from "../../../services/common";
import { CustomDialog } from "../../../components";
import { useSelector } from "react-redux";

interface props {
  isOpen: boolean;
  type: "new" | "edit" | "view";
  report: any;
  onClose: () => void;
  userType: "reportedUser" | "reportedBy";
}

const Manage: FC<props> = ({ isOpen, type, report, userType, onClose }) => {
  const isDisabled = type === "view" ? true : false;

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const user =
    userType === "reportedUser" ? report?._reportedUser : report?._reportedBy;

  const [countryCode, setCountryCode] = useState(
    `+${user?.phone?.prefix}` || "+971"
  );

  const { setValue } = useForm({
    defaultValues: {
      name: "",
      email: "",
      phone: "",
      prefix: "",
      profile: "",
      coverProfile: "",
      address: "",
      description: "",
    },
  });

  useEffect(() => {
    if (type === "view" || type === "edit") {
      setValue("prefix", `+${user?.phone?.prefix}`);
      setValue("phone", user?.phone?.number);
      setValue("name", user?.name);
      setValue("email", user?.email);
      setValue("profile", user?.profile);
      setCountryCode(`+${user?.phone?.prefix}`);
    }
  }, [type, user]);

  return (
    <CustomDialog
      title={staticTexts.reportDetail}
      isOpen={isOpen}
      onClose={onClose}
      disabled={isDisabled}
      customStyles={{
        "& .css-gewopa": {
          boxShadow: "none",
          padding: "0px",
        },
      }}
    >
      {type === "view" && <Details report={report} />}
    </CustomDialog>
  );
};

export default Manage;
