
import moment from 'moment';

export const sanitizeChatData = (data: any[]): any[] => {
  return data.map((user, index) => ({
    claimedBy: user._userClaimBy,
    id: index + 1,
    name: user.claimedUserName || 'NA',
    item: user.claimedUserProfile || 'NA',
    lastMessage: user.lastMessage || '',
    status: 'Offline',
    date: new Date(user.createdAt).toLocaleDateString('en-GB'),
  }));
};

export const formatDate = (dateString: string): string => {
  return moment(dateString).format('DD MMM YYYY');
};

export const formatPhone = (prefix: string, number: string): string => {
  return `+${prefix} ${number}`;
};