import React from "react";
import {
  Typography,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import { Questions } from "../../../interfaces";
import { ArrowDownward } from "@mui/icons-material";
import "./index.scss";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../../constants";

interface QuestionListProps {
  questions: Questions[];
}

const QuestionList: React.FC<QuestionListProps> = ({ questions }) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  if (!questions || !questions.length) {
    return null;
  }

  console.log("questions", questions);

  return (
    <div className="questions-section">
      <Typography variant="h6" className="questions-title" color="#4A403A">
        {staticTexts.questionsAnswers}
      </Typography>
      <div className="questions-list">
        {questions.map((qa) => (
          <Accordion key={qa._question._id} className="question-accordion">
            <AccordionSummary
              expandIcon={<ArrowDownward />}
              aria-controls={`question-${qa._question._id}-content`}
              id={`question-${qa._question._id}-header`}
            >
              <Typography className="question-text">
                {language === "en"
                  ? qa._question.label.en
                  : qa._question.label.ar}
              </Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Typography className="answer-text">{qa.value}</Typography>
            </AccordionDetails>
          </Accordion>
        ))}
      </div>
    </div>
  );
};

export default QuestionList;
