import { httpRequest } from "../Network";

export const createAgent = (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const create = await httpRequest('POST', '/user/admin/agent', payload) as any;
    resolve(create?.data);
  } catch (error) {
    reject(error);
  }
});

export const getAgents = async (params: any) => new Promise(async (resolve, reject) => {

  try {
    const agents = await httpRequest('GET', '/user/admin/agents', {}, params) as any;
    resolve(agents?.data);
  } catch (error:any) {
    reject(error);
  }
});

export const updateAgent = (agentId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/agent/${agentId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const deleteAgents = async (agentIds: Array<string>) => new Promise(async (resolve, reject) => {
  try {
    const deleteAgent = await httpRequest('DELETE', `/user/admin/agent`, { agentIds }) as any;
    resolve(deleteAgent?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateAgentStatus = async (agentId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/update-status-agent/${agentId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const createRole = (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const create = await httpRequest('POST', '/user/admin/role', payload) as any;
    resolve(create?.data);
  } catch (error) {
    reject(error);
  }
});

export const getRoles = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const roles = await httpRequest('GET', '/user/admin/roles', {}, params) as any;
    resolve(roles?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateRole = (roleId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/role/${roleId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateRoleStatus = async (roleId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/update-status-role/${roleId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const deleteRoles = async (roleIds: Array<string>) => new Promise(async (resolve, reject) => {
  try {
    const deleteRole = await httpRequest('DELETE', `/user/admin/role`, { roleIds }) as any;
    resolve(deleteRole?.data);
  } catch (error) {
    reject(error);
  }
});

export const getPermissions = async () => new Promise(async (resolve, reject) => {
  try {
    const permissions = await httpRequest('GET', '/user/permissions') as any;
    resolve(permissions?.data);
  } catch (error) {
    reject(error);
  }
});