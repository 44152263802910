import { ChangeEvent, useEffect, useState } from 'react';
import { Box, Checkbox, FormControl, IconButton, MenuItem, Select, Switch, Tooltip } from '@mui/material';
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from './manage';
import { CustomTable, Header, WarningDialog } from '../../components';

const columns = [
  {
    id: 'id',
    label: 'Sno'
  },
  {
    id: 'name',
    label: 'Name'
  },
  {
    id: 'email',
    label: 'Email'
  },
  {
    id: 'role',
    label: 'Role'
  },
  {
    id: 'status',
    label: 'Status'
  },
  {
    id: 'action',
    label: 'Action'
  },
];

interface state {
  manage: {
    isOpen: boolean,
    type: 'new' | 'edit' | 'view';
  },
  deleteWarning: boolean;
  _user: string;
  list: {
    id: number;
    name: JSX.Element;
    email: string;
    role: string;
    status: JSX.Element;
    action: JSX.Element;
  }[]
}

const Admin = () => {
  const [state, setState] = useState<state>({
    manage: {
      type: 'new',
      isOpen: false
    },
    deleteWarning: false,
    _user: '',
    list: []
  });

  useEffect(() => {
    const data = [
      {
        name: 'Tony',
        email: 'Tony@relinns.com',
        role: 'Admin',
        status: 'ACTIVE'
      },
      {
        name: 'Panther',
        email: 'Panther@relinns.com',
        role: 'Agent',
        status: 'INACTIVE'
      }
    ];

    const list = data.map((user, index) => createRow(user, index, handleManage, handleDelete));
    setState(prevState => {
      return {
        ...prevState,
        list: list
      }
    });
  }, []);

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {

  }

  const onPageChange = (e: ChangeEvent<unknown>) => {

  }

  const handleManage = (type: 'new' | 'edit' | 'view') => {
    setState(prevState => {
      return {
        ...prevState,
        manage: {
          ...prevState.manage,
          isOpen: !prevState.manage.isOpen,
          type
        }
      }
    });
  }

  const handleDelete = (_user: string = '') => {
    setState(prevState => {
      return {
        ...prevState,
        deleteWarning: !prevState.deleteWarning,
        _user
      }
    });
  }

  const onDelete = () => {

  }

  const createRow = (user: any, index: number, onEdit: any, onDelete: any) => {
    return {
      all: <Checkbox defaultChecked={false} />,
      id: index + 1,
      name: user.name,
      email: user.email,
      role: user.role,
      status: <Switch defaultChecked={user.status === 'ACTIVE'} />,
      action: <>
        <Tooltip title='Edit'>
          <IconButton onClick={() => onEdit('edit')} color="primary">
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title='delete'>
          <IconButton onClick={() => onDelete(index)} color="error">
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    }
  }

  return (
    <div>
      {/* Add Data  */}
      <Header
        searchPlaceholder="Search by user name, email"
        btnText="Add User"
        onSearch={handleSearch}
        onBtnClick={() => handleManage('new')}
       searchInput
      >
        <FormControl className="ml-2" size='small'>
          <Select
            size='small'
            defaultValue='all'
          >
            <MenuItem value='all'>All Status</MenuItem>
            <MenuItem value='OFFER'>Active</MenuItem>
            <MenuItem value='FINDER'>In-Active</MenuItem>
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop='10px'>
        <CustomTable
          columns={columns}
          rows={state.list}
          height='calc(100vh - 193px)'
          errorMessage="Add user to see the data here"
          pagination={{
            page: 3,
            totalPages: 5
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleDelete()}
        onConfirm={onDelete}
        title="Delete User"
        description="Are you sure do you want to delete this user?"
      />
    </div>
  )
}

export default Admin;