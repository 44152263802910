import { SET_PROFILE_DETAILS } from '../../actions';

const initialState = {
  name: "",
  email: "",
  profile: "",
  isSignupNeededVerification: false,
  phone: {
    prefix: "",
    number: ""
  },
  coverProfile: "",
  type: "",
  defaultLanguage: "en",
};

export const profileReducer = (state = initialState, action: any) => {
  switch (action.type) {

    case SET_PROFILE_DETAILS:
      state = { ...action.payload };
      break;
  }
  return state;
};
