import { Box, Drawer, IconButton, Divider, Typography } from "@mui/material";
import React, { useState, useEffect, useRef } from "react";
import ChatList from "./ChatList";
import ChatWindow from "./ChatWindow";
import { getChatMessages } from "../../services/chats";
import { enqueueSnackbar } from "notistack";
import CloseIcon from "@mui/icons-material/Close";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../constants";

const ChatDetails = (props: any) => {
  const { showChatListing, handleClose, userList, itemId, claimToUserId } =
    props;

  const [selectedUser, setSelectedUser] = useState<any | null>(null);
  const [messages, setMessages] = useState<any>([]);
  const [page, setPage] = useState(1);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [scrollToBottom, setScrollToBottom] = useState(true);
  const [showChatWindow, setShowChatWindow] = useState(false);
  const MESSAGES_PER_PAGE = 20;
  const scrollPositionRef = useRef<number>();

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const getChatOfUser = async (
    payload: object,
    pageNum: number,
    append: boolean = false
  ) => {
    if (isLoading || (!hasMore && append)) return;

    try {
      setIsLoading(true);
      const chatWindow = document.querySelector(".chat-window");
      if (append && chatWindow) {
        scrollPositionRef.current = chatWindow.scrollHeight;
      }

      const response = await getChatMessages(
        pageNum,
        MESSAGES_PER_PAGE,
        payload
      );

      if (response.length < MESSAGES_PER_PAGE) {
        setHasMore(false);
      }

      if (append) {
        setScrollToBottom(false);
        setMessages((prevMessages: any) => [
          ...response.reverse(),
          ...prevMessages,
        ]);
      } else {
        setScrollToBottom(true);
        setMessages(response.reverse());
        setHasMore(true);
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    } finally {
      setIsLoading(false);
    }
  };

  const handleScroll = async (event: React.UIEvent<HTMLDivElement>) => {
    if (!event.currentTarget) return;

    const { scrollTop } = event.currentTarget;

    if (scrollTop < 100 && hasMore && !isLoading && selectedUser) {
      const nextPage = page + 1;
      setPage(nextPage);
      // await getChatOfUser(
      //   {
      //     itemId,
      //     claimToUserId,
      //     claimByUserId: selectedUser._userClaimBy,
      //   },
      //   nextPage,
      //   true
      // );
    }
  };

  useEffect(() => {
    const chatWindow = document.querySelector(".chat-window");
    if (chatWindow) {
      if (scrollToBottom) {
        chatWindow.scrollTop = chatWindow.scrollHeight;
      } else if (scrollPositionRef.current) {
        const newScrollHeight = chatWindow.scrollHeight;
        const scrollDiff = newScrollHeight - scrollPositionRef.current;
        chatWindow.scrollTop = scrollDiff;
      }
    }
  }, [messages, scrollToBottom]);

  useEffect(() => {
    if (selectedUser) {
      getChatOfUser(
        {
          itemId,
          claimToUserId,
          claimByUserId: selectedUser._userClaimBy,
        },
        page,
        true
      );
    }
  }, [page]);

  const handleCloseChatWindow = () => {
    setShowChatWindow(false);
    setSelectedUser(null);
  };

  const list = () => {
    return (
      userList?.length > 0 ? (
        <Box
          sx={{   height: "100vh" }}
          display="flex"
          padding="0px"
          role="presentation"
        >
          <ChatList
            users={userList}
            onSelectUser={(user: any) => {
              setSelectedUser(user);
              setPage(1);
              setHasMore(true);
              setShowChatWindow(true);
              getChatOfUser(
                {
                  itemId,
                  claimToUserId,
                  claimByUserId: user._userClaimBy,
                },
                page
              );
            }}
            className="h-full w-full"
          />
        </Box>
      ) : (
        <Box>
          <Typography>{staticTexts.noChatsFound}</Typography>
        </Box>
      )
    );
  };

  return (
    <>
      <Drawer
        anchor={"right"}
        open={showChatListing}
        onClose={handleClose}
        sx={{
          overflowY: "scroll",
          "& .MuiDrawer-paper": {
            width: "30%",
            // minWidth: "40%",
          },
        }}
      >
        <IconButton
          onClick={handleClose}
          style={{
            position: "absolute",
            top: 8,
            right: 8,
            zIndex: 1,
          }}
        >
          <CloseIcon />
        </IconButton>
        <Divider />
        {list()}
      </Drawer>

      <Drawer
        anchor={"right"}
        open={showChatWindow}
        onClose={handleCloseChatWindow}
      >
        {selectedUser && (
          <ChatWindow
            user={selectedUser}
            onBack={handleCloseChatWindow}
            className="h-full chat-window"
            messages={messages}
            onScroll={handleScroll}
            isLoading={isLoading}
          />
        )}
      </Drawer>
    </>
  );
};

export default ChatDetails;
