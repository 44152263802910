import englishTexts from './en/en.json';
import arabicTexts from './ar/ar.json';

const getStaticTexts = (language = 'en') => {
  switch (language) {
    case 'en':
      return englishTexts;
    default:
      return arabicTexts;
  }
}

export { getStaticTexts };
