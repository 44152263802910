import { ChangeEvent, useEffect, useReducer, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Header, CustomTable, WarningDialog } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from "./manage";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts, PERMISSIONS } from "../../../constants";
import {
  getCustomers,
  getImpersonateToken,
  handleCharacterLimit,
} from "../../../services/common";
import {
  deleteCustomers,
  updateCustomerStatus,
} from "../../../services/customer";
import Loader from "../../../components/Loader";
import { withPermission } from "../../../components/hoc";
import { useSelector } from "react-redux";

interface state {
  manage: {
    isOpen: boolean;
    type: "new" | "edit" | "view";
    user: any;
  };
  deleteWarning: boolean;
  multiDeleteWarning: boolean;
  _user: string;
  list: {
    all: JSX.Element;
    id: number;
    _id: string;
    name: JSX.Element;
    email: string;
    phone: string;
    preference: string;
    status: JSX.Element;
    action: JSX.Element;
  }[];
  selectAll: string[];
  loading: boolean;
  searchQuery: string;
  page: number;
  statusFilter: string;
  totalPages: number;
  impersonateWarning: {
    isOpen: boolean;
    userId: string;
    userType: string;
  };
}

const BusinessUsers = () => {
  const businessUserReducer = (state: state, action: any): state => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
        case 'UPDATE_LIST':
        const userIds = action.payload;
        const filteredList = state.list.filter((user: any) => !(userIds || []).includes(user._id));
        return {
          ...state,
          list: filteredList,
        };
        case "UPDATE_CHECKBOX": {
          const { id, isChecked } = action.payload;
    
          // Update the `selectAll` array
          const updatedSelectAll = isChecked
            ? [...state.selectAll, id]
            : state.selectAll.filter((ele) => ele !== id);
    
          // Update the list to reflect the checkbox state
          const updatedList = state.list.map((category: any) => ({
            ...category,
            all: (
              <Checkbox
                onChange={(e) => handleMultipleSelect(e, state.selectAll, category._id)}
                checked={updatedSelectAll.includes(category._id)}
              />
            ),
          }));
          return {
            ...state,
            selectAll: updatedSelectAll,
            list: updatedList,
          };
        }
        case 'TOGGLE_STATUS':
          const { id, status } = action.payload;
          const updatedList = state.list.map((user: any) =>
            user._id === id
              ? {
                  ...user,
                  status: (
                    <Switch
                      checked={status === "ACTIVE"}
                      disabled={isEditIconDisabled}
                      onChange={(e) => {
                        handleStatusChange(
                          user._id,
                          e.target.checked ? "ACTIVE" : "INACTIVE",
                        );
                      }}
                    />
                  ),
                }
              : user
          );
          return {
            ...state,
            list: updatedList,
          };
        
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const userPermissions = useSelector((state: any) => state.permissions);
  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(
    PERMISSIONS.EDIT_BUSINESS_CUSTOMER
  );
  const isDeleteIconDisabled = !userPermissions.includes(
    PERMISSIONS.DELETE_BUSINESS_CUSTOMER
  );

  const isImpersonatePermissionEnabled = userPermissions.includes(
    PERMISSIONS.WRITE_IMPERSONATE
  );

  const fetchBusinessUsers = async (
    searchQuery: string = "",
    page: number = 1
  ) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        type: "BUSINESS",
        text: searchQuery,
      };

      if (state.statusFilter !== "ALL") {
        params.status = state.statusFilter;
      }

      const businessUsers = (await getCustomers(params)) as any;

      if (!businessUsers || !businessUsers.customers?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: businessUsers.meta.totalPages },
      });

      const list = businessUsers.customers.map((user: any, index: number) =>
        createRow(
          state.selectAll,
          user,
          index,
          handleManage,
          handleWarningDialog,
          handleMultipleSelect,
          page
        )
      );

      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const [state, dispatch] = useReducer(businessUserReducer, {
    manage: {
      type: "new",
      isOpen: false,
      user: null,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _user: "",
    list: [],
    selectAll: [],
    loading: true,
    searchQuery: "",
    page: 1,
    statusFilter: "",
    totalPages: 1,
    impersonateWarning: {
      isOpen: false,
      userId: "",
      userType: "",
    },
  });

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchBusinessUsers(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchBusinessUsers(state.searchQuery, page);
  };

  useEffect(() => {
    fetchBusinessUsers(state.searchQuery, state.page);
  }, [state.statusFilter]);

  const handleManage = (type: "new" | "edit" | "view", user: any = null) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: true,
          user,
        },
      },
    });
  };

  const handleClose = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          isOpen: false,
        },
      },
    });
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      await updateCustomerStatus(id, { status });
      dispatch({ type: "UPDATE_STATUS", _id: id, status });
      enqueueSnackbar(staticTexts.customerStatusUpdatedSuccessfully, {
        variant: "success",
      });

      dispatch({ type: "TOGGLE_STATUS", payload: { id, status } });
      
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      dispatch({ type:'UPDATE_FIELDS', payload: { loading: false } });
    }
  };

  const handleImpersonateToken = async (userId: string, userType: String) => {
    try {
      const impersonateToken = (await getImpersonateToken({
        userId,
        userType,
      })) as any;
      const token = impersonateToken?.token;
      window.open(
        `${
          process.env.REACT_APP_WEBSITE_PANEL
        }/organization-page?impersonateToken=${encodeURIComponent(
          token
        )}&user-type=${userType}`,
        "_blank"
      );
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleImpersonateWarning = (userId: string, userType: string) => {
    if (!isImpersonatePermissionEnabled) return;

    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        impersonateWarning: {
          isOpen: true,
          userId,
          userType,
        },
      },
    });
  };

  const handleImpersonateConfirm = () => {
    const { userId, userType } = state.impersonateWarning;
    handleImpersonateToken(userId, userType);
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        impersonateWarning: {
          isOpen: false,
          userId: "",
          userType: "",
        },
      },
    });
  };

  const handleImpersonateCancel = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        impersonateWarning: {
          isOpen: false,
          userId: "",
          userType: "",
        },
      },
    });
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const allIds = state.list.map((user: any) => user._id);

    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        selectAll: checked ? allIds : [],
      },
    });

    const updatedList = state.list.map((user: any) => ({
      ...user,
      all: (
        <Checkbox
          onChange={(e) =>
            handleMultipleSelect(e, checked ? allIds : [], user._id)
          }
          checked={checked}
        />
      ),
    }));

    dispatch({ type: "LOADED", list: updatedList });
  };

  const handleMultipleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    selectAll: string[],
    id: string
  ) => {
    const isChecked = e.target.checked;
    dispatch({
      type: "UPDATE_CHECKBOX",
      payload: { id, isChecked },
    });
  };

  const handleDeleteCustomer = async (userIds: Array<string>) => {
    try {
      await deleteCustomers(userIds);
      enqueueSnackbar(staticTexts.customerDeletedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: 'UPDATE_LIST', payload: userIds });
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDelete = async (userId: string) => {
    await handleDeleteCustomer([userId]);
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: false,
        _user: "",
      },
    });
  };

  const handleMultiDelete = async (userIds: Array<string> = []) => {
    if (userIds.length > 0) {
      await handleDeleteCustomer(userIds);
      dispatch({
        type: "UPDATE_FIELDS",
        payload: {
          multiDeleteWarning: false,
        },
      });
    }
  };

  const handleWarningDialog = (_user: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: !state.deleteWarning,
        _user,
      },
    });
  };

  const handleMutlipleWarningDialog = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        multiDeleteWarning: !state.multiDeleteWarning,
        selectAll: state.selectAll,
      },
    });
  };

  const statusFilter = [
    {
      label: staticTexts.all,
      value: "ALL",
    },
    {
      label: staticTexts.active,
      value: "ACTIVE",
    },
    {
      label: staticTexts.inactive,
      value: "INACTIVE",
    },
  ];

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={
            state.selectAll?.length > 0 &&
            state.list?.length === state.selectAll?.length
          }
          indeterminate={
            state.selectAll?.length > 0 &&
            state.list?.length !== state.selectAll?.length
          }
        />
      ),
    },
    {
      id: "id",
      label: staticTexts.sNo,
    },
    {
      id: "name",
      label: staticTexts.name,
    },
    {
      id: "email",
      label: staticTexts.email,
    },
    {
      id: "phone",
      label: staticTexts.phone,
    },
    {
      id: "status",
      label: staticTexts.status,
    },
    {
      id: "action",
      label: staticTexts.action,
    },
  ];

  const createRow = (
    selectAll: string[],
    user: any,
    index: number,
    onEdit: any,
    onDelete: any,
    onMultiDelete: any,
    page: number
  ) => {
    const all = (
      <Checkbox
        onChange={(e) => onMultiDelete(e, selectAll, user._id)}
        checked={selectAll.includes(user._id) ? true : false}
      />
    );

    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => handleImpersonateWarning(user._id, user.type)}
        sx={{ textTransform: "capitalize" }}
      >
        {user.name}
      </Typography>
    );

    const status = (
      <Switch
        checked={user.status === "ACTIVE"}
        disabled={isEditIconDisabled}
        onChange={(e) => {
          handleStatusChange(
            user._id,
            e.target.checked ? "ACTIVE" : "INACTIVE"
          );
        }}
      />
    );

    const action = (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onEdit("edit", user)}
            color="primary"
            disabled={isEditIconDisabled}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => onDelete(user._id)}
            color="error"
            disabled={isDeleteIconDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: (page - 1) * itemsPerPage + index + 1,
      _id: user._id,
      name,
      email: handleCharacterLimit(user.email, 30),
      phone:
      language === 'ar'
        ? '+' + user.phone.prefix + ' ' + user.phone.number.split('').reverse().join('')
        : '+' + user.phone.prefix + ' ' + user.phone.number,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      {state.loading && <Loader />}
      <Header
        isEditIconDisabled={isEditIconDisabled}
        isDeleteIconDisabled={isDeleteIconDisabled}
        searchPlaceholder={staticTexts.searchByUserNameEmailAndPhone}
        onSearch={handleSearch}
        onBtnClick={() => handleManage("new")}
        onDelete={(e) => handleMutlipleWarningDialog()}
        isDeleteDisable={state.selectAll.length ? false : true}
        btnText={staticTexts.addBusinessUser}
        searchInput
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === "") {
                return staticTexts.statusFilter;
              }
              if (selected === "ALL") {
                return staticTexts.all;
              }
              return selected === "ALL"
                ? staticTexts.all
                : selected === "ACTIVE"
                ? staticTexts.active
                : staticTexts.inactive;
            }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_FIELDS",
                payload: { statusFilter: e.target.value, page: 1 },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.addUserToSeeDataHere}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        user={state.manage.user}
        onClose={handleClose}
        onSave={() => {
          fetchBusinessUsers(state.searchQuery, state.page);
          handleClose();
        }}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleWarningDialog(state._user || "")}
        onConfirm={() => handleDelete(state._user)}
        title="Delete User"
        description={staticTexts.areYouSureYouWantToDeleteThisUser}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMutlipleWarningDialog()}
        onConfirm={() => handleMultiDelete(state.selectAll)}
        title={staticTexts.deleteUsers}
        description={staticTexts.areYouSureYouWantToDeleteThisUsers}
      />

      {/* Impersonate Warning Dialog */}
      <WarningDialog
        isOpen={state.impersonateWarning.isOpen}
        onClose={handleImpersonateCancel}
        onConfirm={handleImpersonateConfirm}
        title={staticTexts.impersonateUser}
        description={staticTexts.areYouSureYouWantToImpersonateThisUser}
      />
    </>
  );
};

export default withPermission(
  BusinessUsers,
  PERMISSIONS.VIEW_BUSINESS_CUSTOMER
);
