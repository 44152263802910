import { httpRequest } from "../Network";

export const getReports = async (params: any) => new Promise(async (resolve, reject) => {
  try {
    const reports = await httpRequest('GET', '/user/admin/reports', {}, params) as any;
    resolve(reports.data);
  } catch (error) {
    reject(error);
  }
});
export const updateReportStatus = async (reportId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/report/${reportId}`, payload) as any;
    resolve(update.data);
  } catch (error) {
    reject(error);
  }
});

