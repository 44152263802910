import { FC } from 'react';
import { Editor } from '@tinymce/tinymce-react';

interface props {
    value: string;
    onChange: (value: string) => void;
    height?: string;
}

const TinyEditor: FC<props> = ({ value, height, onChange }) => {
    const uploadFile = (type: any) => new Promise((resolve, reject) => {
        const myfile = document.getElementById('myfile') as HTMLElement;
        myfile.click();

        myfile.addEventListener('change', (e: any) => {
            const validate = type === "file" ? (/.*.(pdf|doc|docx|xls|xlsx)/i).test(e.target.value) : (/.*.(jpg|jpeg|png)$/i).test(e.target.value)
            if (validate) {
                const files = e.target.files;
                const formdata = new FormData();
                if (files?.length) {

                    // const fileName = files[0].name
                    formdata.append("file", files[0]);
                    // careerService.upload(formdata)
                    //     .then((response) => {
                    //         const payload = {
                    //             fileName,
                    //             url: response.data
                    //         }
                    //         resolve(payload)
                    //     })
                    //     .catch((error) => {
                    //         reject(error)
                    //         console.log(error);
                    //     })
                }
            }
        })
    })

    return (
        <>
            <input type="file" id='myfile' hidden />
            <Editor
                onEditorChange={onChange}
                apiKey='skxa7v51z5447kqvwrn2537ip8zluwdrut616101llvcaj8m'
                value={value}
                init={{
                    // images_upload_handler: upload,
                    height: height ? height : 300,
                    disabled: false,
                    document_base_url: 'http://www.example.com/path1/',
                    link_assume_external_targets: true,
                    plugins: [
                        'advlist', 'autolink', 'lists', 'link', 'image', 'charmap', 'preview',
                        'anchor', 'searchreplace', 'visualblocks', 'code', 'fullscreen',
                        'insertdatetime', 'table', 'code', 'help', 'wordcount'
                    ],
                    toolbar: `candidate  undo redo | blocks | 
                        bold italic forecolor | alignleft aligncenter 
                        alignright alignjustify | bullist numlist outdent indent | 
                        removeformat | help`,

                    file_picker_callback: function (callback, value, meta) {
                        // Provide file and text for the link dialog
                        if (meta.filetype === 'file') {
                            uploadFile("file")
                                .then((file: any) => {
                                    callback(file.url, { text: file.fileName });
                                })
                                .catch((error) => console.log(error))
                        }

                        if (meta.filetype === 'image') {
                            uploadFile('img')
                                .then((file: any) => {
                                    callback(file.url, { text: file.fileName });
                                })
                                .catch((error) => console.log(error))
                        }
                    },

                    setup: (editor) => {
                        var toggleState = false;
                        editor.ui.registry.addMenuButton('candidate', {
                            text: 'My button',
                            fetch: function (callback) {
                                var items = [
                                    {
                                        type: 'togglemenuitem',
                                        text: 'Toggle menu item',
                                        onAction: function () {
                                            toggleState = !toggleState;
                                            editor.insertContent('&nbsp;<em>You toggled a menuitem ' + (toggleState ? 'on' : 'off') + '</em>');
                                        },
                                        onSetup: function (api: any) {
                                            api.setActive(toggleState);
                                            return function () { };
                                        }
                                    }
                                ];
                                callback(items as any);
                            }
                        });
                    },

                    content_style: 'body { font-family:Helvetica,Arial,sans-serif; font-size:14px }'
                }}
            />
        </>
    )
}

export default TinyEditor