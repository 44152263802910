import { FC, useReducer } from "react";
import { TextField, Typography } from "@mui/material";
import { getStaticTexts } from "../../../constants";
import { WarningDialog } from "../../../components";
import { useSelector } from "react-redux";
import { enqueueSnackbar } from "notistack";

interface props {
  isOpen: boolean;
  isDisabled?: boolean;
  onClose: () => void;
  reportId: string;
  actionType: string;
  updateStatusReport: (
    reportId: string,
    status: string,
    remark?: string
  ) => void;
}

const ManageReport: FC<props> = ({
  isOpen = true,
  onClose,
  actionType,
  reportId,
  updateStatusReport,
}) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const approveUserReducer = (state: any, action: any) => {
    switch (action.type) {
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(approveUserReducer, {
    remark: "",
  });

  const handleRemarkChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { remark: event.target.value },
    });
  };

  const onConfirm = () => {
    if (!state.remark) {
      enqueueSnackbar(staticTexts.reasonIsRequired, {
        variant: "error",
      });
      return;
    }

    updateStatusReport(reportId, actionType, state.remark || "NA");
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { remark: "" },
    });
    onClose();
  };

  return (
    <WarningDialog
      isOpen={isOpen}
      onClose={() => {
        dispatch({
          type: "UPDATE_FIELDS",
          payload: {
            remark: "",
          },
        });
        onClose();
      }}
      onConfirm={onConfirm}
      title=""
    >
      <TextField
        label={staticTexts.explainReason}
        sx={{ margin: "12px 0" }}
        variant="outlined"
        multiline
        rows={4}
        fullWidth
        value={state.remark}
        onChange={handleRemarkChange}
        required
      />
    </WarningDialog>
  );
};

export default ManageReport;
