import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from "@mui/material";
import { FC, MouseEvent, ReactNode } from "react";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../../constants";

interface props {
  isOpen: boolean;
  title: string;
  description?: string;
  children?: ReactNode;
  showRejectButton?: boolean;
  onClose: (e: MouseEvent<HTMLElement>) => void;
  onConfirm: (e: MouseEvent<HTMLElement>) => void;
  onReject?: (e: MouseEvent<HTMLElement>) => void;
}

export const WarningDialog: FC<props> = ({
  isOpen,
  title,
  description,
  children,
  showRejectButton = false,
  onClose,
  onConfirm,
  onReject,
}) => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  return (
    <Dialog
      open={isOpen}
      onClose={onClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          {description}
        </DialogContentText>
        {children}
      </DialogContent>
      <DialogActions>
        <Button variant="outlined" onClick={onClose}>
          {staticTexts.cancel}
        </Button>
        {showRejectButton && (
          <Button variant="outlined" onClick={onReject} autoFocus color="error">
            {staticTexts.reject}
          </Button>
        )}
        <Button onClick={onConfirm} autoFocus>
          {staticTexts.confirm}
        </Button>
      </DialogActions>
    </Dialog>
  );
};
