import { createTheme } from "@mui/material";

const theme = createTheme({
  palette: {
    primary: {
      // "50": '#EFFBF5',
      // "100": '#DFF6EA',
      // "200": '#BFEED5',
      // "300": '#9EE5C1',
      // "400": '#7EDDAC',
      // "500": '#29995F',
      // "600": '#35C57B',
      // "700": '#5ED497',
      // "800": '#1D6D44',
      // "900": '#114028',
      // A100: "#e2ffc6",
      // A200: "#c9ff93",
      // A400: "#afff60",
      // A700: "#a2ff47",
      main: '#FF6F61',
      contrastText: "#ffffff"
    },
    secondary: {
      "50": '#C6E4FE',
      "100": '#B2DAFE',
      "200": '#8AC6FE',
      "300": '#62B3FD',
      "400": '#399FFD',
      "500": '#118CFC',
      "600": '#036FD2',
      "700": '#02529B',
      "800": '#013563',
      "900": '#01172C',
      A100: "#118CFC",
      A200: "#118CFC",
      A400: "#118CFC",
      A700: "#036FD2",
      contrastText: "#fff"
    },
  },

  components: {
    // Overriding default Text Field
    MuiTextField: {
      defaultProps: {
        size: 'small'
      },
      styleOverrides: {
        root: {
          width: "100%",
        },

      },
    },

    MuiSelect: {
      styleOverrides: {
        root: {
          '& .MuiInputBase-input': {
            padding: '8.5px 14px',
          },
        },
      },
    },


    MuiButton: {
      defaultProps: {
        size: "small",
        variant: "contained",
      },
    },

    MuiDialog: {
      defaultProps: {
        fullWidth: true,
      },
    },

    MuiDialogTitle: {
      styleOverrides: {
        root: {
          fontWeight: 'bold',
          padding: "1rem",
        },
      },
    },
    MuiDialogContent: {
      styleOverrides: {
        root: {
          padding: "0 1rem",
        },
      },
    },
    MuiDialogActions: {
      styleOverrides: {
        root: {
          padding: "0 1rem 1rem 1rem",
        },
      },
    },
  },
});

export default theme;
