import { httpRequest } from "../Network";

export const getAnalytics = async () => new Promise(async (resolve, reject) => {

    try {
        const analytics = await httpRequest("GET", "/user/admin/analytics") as any;
        resolve(analytics?.data);
    } catch (error) {
        reject(error);
    }
});

export const getCustomerAnalytics = async (params: any) => new Promise(async (resolve, reject) => {

    try {
        const analytics = await httpRequest("GET", "/user/admin/customers/analytics", {}, params) as any;
        resolve(analytics?.data);
    } catch (error) {
        reject(error);
    }
})