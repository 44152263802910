export const PERMISSIONS = {
  // Dashboard permissions
  VIEW_DASHBOARD: 'analytics.read',

  // Customers permissions
  ADD_BUSINESS_CUSTOMER: 'business-customer.write',
  VIEW_BUSINESS_CUSTOMER: 'business-customer.read',
  EDIT_BUSINESS_CUSTOMER: 'business-customer.write',
  DELETE_BUSINESS_CUSTOMER: 'business-customer.remove',

  ADD_INDIVIDUAL_CUSTOMER: 'individual-customer.write',
  VIEW_INDIVIDUAL_CUSTOMER: 'individual-customer.read',
  EDIT_INDIVIDUAL_CUSTOMER: 'individual-customer.write',
  DELETE_INDIVIDUAL_CUSTOMER: 'individual-customer.remove',

  // Items Category permissions
  VIEW_ITEMS_CATEGORY: 'category.read',
  EDIT_ITEMS_CATEGORY: 'category.write',
  DELETE_ITEMS_CATEGORY: 'category.remove',

  // Questionnaire permissions
  VIEW_QUESTIONNAIRE: 'questionnaire.read',
  EDIT_QUESTIONNAIRE: 'questionnaire.write',
  DELETE_QUESTIONNAIRE: 'questionnaire.remove',

  // Chat permissions
  VIEW_CHAT: 'chat.read',

  // Lost/Found Items permissions
  VIEW_LOST_ITEMS: 'lost-items.read',
  EDIT_LOST_ITEMS: 'lost-items.write',
  DELETE_LOST_ITEMS: 'lost-items.remove',

  // Found Items permissions
  VIEW_FOUND_ITEMS: 'found-items.read',
  EDIT_FOUND_ITEMS: 'found-items.write',
  DELETE_FOUND_ITEMS: 'found-items.remove',

  VIEW_RESOLVED_ITEMS: 'resolve-items.read',

  // Access Control permissions
  VIEW_ACCESS_CONTROL: 'access-control.read',
  EDIT_ACCESS_CONTROL: 'access-control.write',
  DELETE_ACCESS_CONTROL: 'access-control.remove',
  // ASSIGN_ROLES: 'access-control.',
  // REMOVE_ROLES: 'access-control.removeRoles',
  // VIEW_USER_PERMISSIONS: 'access-control.viewUserPermissions',
  // EDIT_USER_PERMISSIONS: 'access-control.editUserPermissions',

  // Payment permissions
  VIEW_PAYMENT: 'payment.read',

  // Configurations permissions
  VIEW_CONFIGURATION: 'configuration.read',
  EDIT_CONFIGURATION: 'configuration.write',

  // Admin User permissions
  VIEW_ADMIN_USERS: 'adminUsers.read',

  // Report permissions
  VIEW_REPORT: 'report.read',
  EDIT_REPORT: 'report.write',
  DELETE_REPORT: 'report.remove',

  WRITE_IMPERSONATE: 'impersonate.write',
};
