import { ChangeEvent, useEffect, useReducer, useState } from "react";
import {
  Box,
  Checkbox,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Switch,
  Tooltip,
  Typography,
} from "@mui/material";
import { Header, CustomTable, WarningDialog } from "../../../components";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import Manage from "./manage";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts, PERMISSIONS } from "../../../constants";
import { handleCharacterLimit } from "../../../services/common";
import Loader from "../../../components/Loader";
import { withPermission } from "../../../components/hoc";
import {
  deleteQuestionnaire,
  getQuestionnaire,
  updateQuestionnaireStatus,
} from "../../../services/aggregation";
import { useSelector } from "react-redux";

interface state {
  manage: {
    isOpen: boolean;
    type: "new" | "edit" | "view";
    question: any;
  };
  deleteWarning: boolean;
  multiDeleteWarning: boolean;
  _question: string;
  list: {
    all: JSX.Element;
    id: number;
    _id: string;
    name: JSX.Element;
    email: string;
    phone: string;
    preference: string;
    status: JSX.Element;
    action: JSX.Element;
  }[];
  selectAll: string[];
  loading: boolean;
  searchQuery: string;
  page: number;
  statusFilter: string;
  categoryFilter: string;
  totalPages: number;
}

const Lost = () => {
  const lostQuestionnaireReducer = (state: state, action: any): state => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
      case "UPDATE_CHECKBOX": {
          const { id, isChecked } = action.payload;
    
          // Update the `selectAll` array
          const updatedSelectAll = isChecked
            ? [...state.selectAll, id]
            : state.selectAll.filter((ele) => ele !== id);
    
          // Update the list to reflect the checkbox state
          const updatedList = state.list.map((questions: any) => ({
            ...questions,
            all: (
              <Checkbox
                onChange={(e) => handleMultipleSelect(e, state.selectAll, questions._id)}
                checked={updatedSelectAll.includes(questions._id)}
              />
            ),
          }));
          return {
            ...state,
            selectAll: updatedSelectAll,
            list: updatedList,
          };
        }
        case 'UPDATE_LIST':
          const userIds = action.payload;
          const filteredList = state.list.filter((user: any) => !(userIds || []).includes(user._id));
          return {
            ...state,
            list: filteredList,
          };
        case 'TOGGLE_STATUS':
          const { id, status } = action.payload;
          const updatedList = state.list.map((user: any) =>
            user._id === id
              ? {
                  ...user,
                  status: (
                    <Switch
                      checked={status === "ACTIVE"}
                      disabled={isEditIconDisabled}
                      onChange={(e) => {
                        handleStatusChange(
                          user._id,
                          e.target.checked ? "ACTIVE" : "INACTIVE",
                        );
                      }}
                    />
                  ),
                }
              : user
          );
          return {
            ...state,
            list: updatedList,
          };
        
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const category = useSelector((state: any) => state.category);

  const userPermissions = useSelector((state: any) => state.permissions);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(
    PERMISSIONS.EDIT_QUESTIONNAIRE
  );
  const isDeleteIconDisabled = !userPermissions.includes(
    PERMISSIONS.DELETE_QUESTIONNAIRE
  );

  const fetchLostQuestions = async (
    searchQuery: string = "",
    page: number = 1
  ) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        type: "LOST",
        text: searchQuery,
      };

      if (
        state.statusFilter === "ACTIVE" ||
        state.statusFilter === "INACTIVE"
      ) {
        params.status = state.statusFilter;
      }

      if (state.categoryFilter && state.categoryFilter !== "ALL") {
        params.categoryId = state.categoryFilter;
      }

      const questions = (await getQuestionnaire(params)) as any;

      if (!questions || !questions?.questions?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }
      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: questions.meta.totalPages },
      });

      const list = questions.questions.map((question: any, index: number) =>
        createRow(
          state.selectAll,
          question,
          index,
          handleManage,
          handleWarningDialog,
          handleMultipleSelect,
          page
        )
      );

      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const [state, dispatch] = useReducer(lostQuestionnaireReducer, {
    manage: {
      type: "new",
      isOpen: false,
      question: null,
    },
    deleteWarning: false,
    multiDeleteWarning: false,
    _question: "",
    list: [],
    selectAll: [],
    loading: true,
    searchQuery: "",
    page: 1,
    statusFilter: "",
    categoryFilter: "",
    totalPages: 1,
  });

  const [debounceTimeout, setDebounceTimeout] = useState<NodeJS.Timeout | null>(
    null
  );

  const handleSearch = (e: ChangeEvent<HTMLElement>) => {
    const searchQuery = (e.target as HTMLInputElement).value;
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { searchQuery, page: 1 },
    });

    if (debounceTimeout) {
      clearTimeout(debounceTimeout);
    }

    const timeout = setTimeout(() => {
      fetchLostQuestions(searchQuery, 1);
    }, 500);

    setDebounceTimeout(timeout);
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchLostQuestions(state.searchQuery, page);
  };

  useEffect(() => {
    fetchLostQuestions(state.searchQuery, state.page);
  }, [state.statusFilter, state.categoryFilter]);

  const handleManage = (
    type: "new" | "edit" | "view",
    question: any = null
  ) => {
    console.log("question", question);
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: !state.manage.isOpen,
          question,
        },
      },
    });
  };

  const handleStatusChange = async (id: string, status: string) => {
    try {
      await updateQuestionnaireStatus(id, { status });
      dispatch({ type: "UPDATE_STATUS", _id: id, status });
      enqueueSnackbar(staticTexts.questionnaireStatusUpdatedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: "TOGGLE_STATUS", payload: { id, status } });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    } finally {
      dispatch({ type: "UPDATE_FIELDS", payload: {loading : false}})
    }
  };

  const handleSelectAll = (e: ChangeEvent<HTMLInputElement>) => {
    const checked = e.target.checked;
    const allIds = state.list.map((question: any) => question._id);

    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        selectAll: checked ? allIds : [],
      },
    });

    const updatedList = state.list.map((question: any) => ({
      ...question,
      all: (
        <Checkbox
          onChange={(e) =>
            handleMultipleSelect(e, state.selectAll, question._id)
          }
          checked={checked}
        />
      ),
    }));

    dispatch({ type: "LOADED", list: updatedList });
  };

  const handleMultipleSelect = (
    e: ChangeEvent<HTMLInputElement>,
    selectAll: string[],
    id: string
  ) => {
    const isChecked = e.target.checked;
    dispatch({
      type: "UPDATE_CHECKBOX",
      payload: { id, isChecked },
    });
  };

  const handleDeleteQuestionnaire = async (questionnaireIds: Array<string>) => {
    try {
      await deleteQuestionnaire(questionnaireIds);
      enqueueSnackbar(staticTexts.questionnaireDeletedSuccessfully, {
        variant: "success",
      });
      dispatch({ type: 'UPDATE_LIST', payload: questionnaireIds });
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleDelete = async (questionId: string) => {
    await handleDeleteQuestionnaire([questionId]);
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: false,
        _question: "",
      },
    });
  };

  const handleMultiDelete = async (questionnaireIds: Array<string> = []) => {
    if (questionnaireIds?.length > 0) {
      await handleDeleteQuestionnaire(questionnaireIds);
      dispatch({
        type: "UPDATE_FIELDS",
        payload: {
          multiDeleteWarning: false,
          selectAll: [],
        },
      });
    }
  };

  const handleWarningDialog = (_question: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        deleteWarning: !state.deleteWarning,
        _question,
      },
    });
  };

  const handleMutlipleWarningDialog = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        multiDeleteWarning: !state.multiDeleteWarning,
        selectAll: state.selectAll,
      },
    });
  };

  const statusFilter = [
    {
      label: staticTexts.all,
      value: "ALL",
    },
    {
      label: staticTexts.active,
      value: "ACTIVE",
    },
    {
      label: staticTexts.inactive,
      value: "INACTIVE",
    },
  ];

  const columns = [
    {
      id: "all",
      label: (
        <Checkbox
          onChange={handleSelectAll}
          checked={
            state.selectAll?.length > 0 &&
            state.list?.length === state.selectAll?.length
          }
          indeterminate={
            state.selectAll?.length > 0 &&
            state.list?.length !== state.selectAll?.length
          }
        />
      ),
    },
    {
      id: "id",
      label: staticTexts.sNo,
    },
    {
      id: "name",
      label: staticTexts.name,
    },
    {
      id: "category",
      label: staticTexts.category,
    },
    {
      id: "status",
      label: staticTexts.status,
    },
    {
      id: "action",
      label: staticTexts.action,
    },
  ];

  const createRow = (
    selectAll: string[],
    question: any,
    index: number,
    onEdit: any,
    onDelete: any,
    onMultiDelete: any,
    page: number
  ) => {
    const all = (
      <Checkbox
        data-id={question._id}
        onChange={(e) => onMultiDelete(e, question._id)}
        checked={selectAll.includes(question._id)}
      />
    );

    const name = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => onEdit("view", question)}
        sx={{ textTransform: "capitalize" }}
      >
        {handleCharacterLimit(
          language === "en" ? question.label.en : question.label.ar,
          30
        )}
      </Typography>
    );

    const category = (
      <Typography
        variant="body1"
        color="text.secondary"
        sx={{ textTransform: "capitalize" }}
      >
        {question?._category?.name}
      </Typography>
    );

    const status = (
      <Switch
        checked={question.status === "ACTIVE"}
        disabled={isEditIconDisabled}
        onChange={(e) => {
          handleStatusChange(
            question._id,
            e.target.checked ? "ACTIVE" : "INACTIVE"
          );
        }}
      />
    );

    const action = (
      <>
        <Tooltip title="Edit">
          <IconButton
            onClick={() => onEdit("edit", question)}
            color="primary"
            disabled={isEditIconDisabled}
          >
            <EditIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Delete">
          <IconButton
            onClick={() => onDelete(question._id)}
            color="error"
            disabled={isDeleteIconDisabled}
          >
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      </>
    );

    return {
      all,
      id: (page - 1) * itemsPerPage + index + 1,
      _id: question._id,
      name,
      category,
      status,
      action,
    };
  };

  return (
    <>
      {/* Add Data  */}
      {state.loading && <Loader />}
      <Header
        isEditIconDisabled={isEditIconDisabled}
        isDeleteIconDisabled={isDeleteIconDisabled}
        searchPlaceholder={staticTexts.searchByQuestion}
        onSearch={handleSearch}
        onBtnClick={() => handleManage("new")}
        onDelete={(e) => handleMutlipleWarningDialog()}
        isDeleteDisable={state?.selectAll?.length ? false : true}
        btnText={staticTexts.addQuestion}
        searchInput
      >
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === "") {
                return staticTexts.statusFilter;
              }
              if (selected === "ALL") {
                return staticTexts.all;
              }
              return selected === "ALL"
                ? staticTexts.all
                : selected === "ACTIVE"
                ? staticTexts.active
                : staticTexts.inactive;
            }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_FIELDS",
                payload: { statusFilter: e.target.value, page: 1 },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        {category && category.length > 0 && (
          <FormControl className="ml-2" size="small">
            <Select
              size="small"
              displayEmpty
              value={state.categoryFilter}
              renderValue={(selected) => {
                if (!selected || selected === "") {
                  return staticTexts.categoryFilter;
                }
                if (selected === "all") {
                  return staticTexts.allCategory;
                }
                const selectedCategory = category?.find(
                  (cat: any) => cat._id === selected
                );
                return selectedCategory?.name
                  .split(" ")
                  .map(
                    (word: string) =>
                      word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()
                  )
                  .join(" ");
              }}
              onChange={(e) =>
                dispatch({
                  type: "UPDATE_FIELDS",
                  payload: { categoryFilter: e.target.value, page: 1 },
                })
              }
            >
              {(category || []).map((category: any) => (
                <MenuItem key={category._id} value={category._id}>
                  {category.name
                    .split(" ")
                    .map(
                      (word: string) =>
                        word.charAt(0).toUpperCase() +
                        word.slice(1).toLowerCase()
                    )
                    .join(" ")}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        )}
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.addQuestionsToSeeDataHere}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      {/* Manage Data  */}
      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        question={state.manage.question}
        onClose={() => handleManage(state.manage.type)}
      />

      {/* Delete Data  */}
      <WarningDialog
        isOpen={state.deleteWarning}
        onClose={() => handleWarningDialog(state._question || "")}
        onConfirm={() => handleDelete(state._question)}
        title={staticTexts.deleteQuestion}
        description={staticTexts.deleteQuestionDescription}
      />

      {/* Multi Delete Data  */}
      <WarningDialog
        isOpen={state.multiDeleteWarning}
        onClose={() => handleMutlipleWarningDialog()}
        onConfirm={() => handleMultiDelete(state.selectAll)}
        title={staticTexts.deleteQuestions}
        description={staticTexts.deleteQuestionsDescription}
      />
    </>
  );
};

export default withPermission(Lost, PERMISSIONS.VIEW_QUESTIONNAIRE);
