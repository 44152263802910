import { FC, useEffect, useState } from 'react';
import {CustomDialog} from '../../../../components';
import { Box, Grid, TextField } from '@mui/material';

interface props {
    isOpen: boolean;
    onClose: () => void;
}

const Manage: FC<props> = ({ isOpen, onClose }) => {
    const [state, setState] = useState({
        info: {
            registration_id: '',
            trip_id: '',
            driver_name: '',
            email: '',
            phone: '',
            total_distance: ''
        }
    });

    useEffect(() => {
        return () => {
            return setState(prevState => {
                return {
                    ...prevState,
                    info: {
                        ...prevState.info,
                        registration_id: '',
                        trip_id: '',
                        driver_name: '',
                        email: '',
                        phone: '',
                        total_distance: ''
                    }
                }
            });
        }
    }, [isOpen]);

    return (
        <CustomDialog
            title={'Payment Detail'}
            disabled={true}
            isOpen={isOpen}
            onClose={onClose}
        >
            <Box>
                <Grid container spacing={4}>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Payment ID'
                            name='name'
                            value={"state.info.registration_id"}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Trip ID'
                            name='trip_id'
                            value={state.info.trip_id}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Driver Name'
                            name='name'
                            value={state.info.driver_name}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Email'
                            name='email'
                            value={state.info.email}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Phone'
                            name='phone'
                            value={state.info.phone}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Total Amount'
                            name='phone'
                            value={state.info.total_distance}
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Payment Method'
                            name='phone'
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Payment Date'
                            name='phone'
                            disabled={true}
                        />
                    </Grid>
                    <Grid item xs={12} md={6}>
                        <TextField
                            label='Payment Status'
                            name='phone'
                            disabled={true}
                        />
                    </Grid>
                </Grid>
            </Box>
        </CustomDialog>
    )
}

export default Manage;