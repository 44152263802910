import { CLEAR_USER_PERMISSIONS, SET_USER_PERMISSIONS } from '../../actions';

const initialState: any = [];

export const permissionReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_USER_PERMISSIONS:
      return action.payload;
  }
  return state;
};
