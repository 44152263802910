import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../constants";
import { stat } from "fs";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const redirectionLinks = ["profile", "change-password"];

const Settings = () => {
  const [value, setValue] = useState(0);
  const navigate = useNavigate();
  const location = useLocation();

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  useEffect(() => {
    setValue(redirectionLinks.indexOf(location.pathname.split("/")[2]));
  }, [location.pathname]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(redirectionLinks[newValue]);
  };

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          <Tab
            className="tabs-space start-tab-space"
            label={staticTexts.profileDetail}
            {...a11yProps(0)}
          />
          <Tab
            className="tabs-space"
            label={staticTexts.changePassword}
            {...a11yProps(1)}
          />
        </Tabs>
      </Box>
      <Outlet />
    </Box>
  );
};

export default Settings;
