import { FC, useEffect } from "react";
import { CustomDialog } from "../../../../components";
import {
  Box,
  FormControl,
  FormHelperText,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../../constants";
import { Controller, useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import {
  createQuestionnaire,
  updateQuestionnaire,
} from "../../../../services/aggregation";

interface props {
  isOpen: boolean;
  type: "new" | "edit" | "view";
  question: any;
  onClose: () => void;
}

const Manage: FC<props> = ({ isOpen, type, question, onClose }) => {
  const isDisabled = type === "view" ? true : false;

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      nameEnglish: "",
      nameArabic: "",
      category: "",
    },
  });

  const categories = useSelector((state: any) => state.category);

  useEffect(() => {
    if (type === "view" || type === "edit") {
      setValue("nameEnglish", question?.label?.en);
      setValue("nameArabic", question?.label?.ar);
      setValue("category", question?._category?._id || "");
    }
  }, [type, question]);

  const handleCreateQuestion = async () => {
    try {
      const payload = {
        label: {
          en: getValues("nameEnglish"),
          ar: getValues("nameArabic"),
        },
        categoryId: getValues("category"),
        type: "FOUND",
      };

      await createQuestionnaire(payload);

      enqueueSnackbar(staticTexts.questionCreatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateQuestion = async () => {
    try {
      const payload = {
        label: {
          en: getValues("nameEnglish"),
          ar: getValues("nameArabic"),
        },
        categoryId: getValues("category"),
      };

      (await updateQuestionnaire(question._id, payload)) as any;

      enqueueSnackbar(staticTexts.customerProfileUpdatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitButton = async () => {
    try {
      if (type === "new") {
        await handleCreateQuestion();
      } else {
        await handleUpdateQuestion();
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleSubmitButton)}>
      <CustomDialog
        title={staticTexts.questionDetail}
        isOpen={isOpen}
        onClose={onClose}
        onConfirm={handleSubmit(handleSubmitButton)}
        disabled={isDisabled}
      >
        <Box>
          {(type === "edit" || type === "new" || type === "view") && (
            <>
              <Grid2 container spacing={4}>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="nameEnglish"
                    control={control}
                    rules={{
                      required: staticTexts.questionTitleIsRequired,
                    }}
                    render={({ field }) => (
                      <TextField
                        label={staticTexts.questionTitle}
                        error={!!errors.nameEnglish}
                        helperText={errors.nameEnglish?.message || " "}
                        {...field}
                        sx={{ marginBottom: "-30px" }}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="nameArabic"
                    control={control}
                    rules={{
                      required: staticTexts.questionTitleInArabicIsRequired,
                    }}
                    render={({ field }) => (
                      <TextField
                        label={staticTexts.questionTitleInArabic}
                        error={!!errors.nameArabic}
                        helperText={errors.nameArabic?.message || " "}
                        {...field}
                        sx={{ marginBottom: "-30px" }}
                        disabled={isDisabled}
                      />
                    )}
                  />
                </Grid2>
                <Grid2 size={{ xs: 12, md: 6 }}>
                  <Controller
                    name="category"
                    control={control}
                    rules={{
                      required: staticTexts.categoryIsRequired,
                    }}
                    render={({ field }) => (
                      <FormControl fullWidth error={!!errors.category}>
                        <InputLabel sx={{ top: "-6px" }}>
                          {staticTexts.category}
                        </InputLabel>
                        <Select
                          {...field}
                          label="Category"
                          disabled={isDisabled}
                          onChange={(e) => {
                            setValue("category", e.target.value);
                          }}
                        >
                          {categories?.map((category: any) =>
                            category._id !== "ALL" ? (
                              <MenuItem
                                key={category._id}
                                value={category._id}
                                sx={{ textTransform: "capitalize" }}
                              >
                                <Box sx={{ textTransform: "capitalize" }}>
                                  {category.name}
                                </Box>
                              </MenuItem>
                            ) : null
                          )}
                        </Select>
                        <FormHelperText>
                          {errors.category?.message || " "}
                        </FormHelperText>
                      </FormControl>
                    )}
                  />
                </Grid2>
              </Grid2>
            </>
          )}
        </Box>
      </CustomDialog>
    </form>
  );
};

export default Manage;
