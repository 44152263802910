import "./style.scss";
import { Box, Button, Tooltip } from "@mui/material";
import { FC, MouseEvent, ChangeEvent, ReactNode } from "react";
import SearchIcon from "@mui/icons-material/Search";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
// import FilterAltIcon from '@mui/icons-material/FilterAlt';

interface props {
  isEditIconDisabled?: boolean;
  isDeleteIconDisabled?: boolean;
  onSearch?: (e: ChangeEvent<HTMLElement>) => void;
  searchPlaceholder?: string;
  btnText?: string;
  onBtnClick?: (e: MouseEvent<HTMLElement>) => void;
  onDelete?: (e: MouseEvent<HTMLElement>) => void;
  isDeleteDisable?: boolean;
  className?: string;
  style?: any;
  children?: ReactNode;
  searchInput?: boolean;
}

export const Header: FC<props> = ({
  isEditIconDisabled = false,
  isDeleteIconDisabled = false,
  onSearch,
  searchPlaceholder,
  btnText,
  onBtnClick,
  onDelete,
  isDeleteDisable,
  className,
  style,
  children,
  searchInput,
}) => {
  return (
    <Box
      id="header"
      justifyContent="space-between"
      className={`center ${className}`}
      style={style}
    >
      <Box className="h-100">
        {btnText?.length && (
          <Button
            color="primary"
            variant="contained"
            className="add-btn"
            startIcon={<AddIcon />}
            onClick={onBtnClick}
            disabled={isEditIconDisabled}
          >
            {btnText}
          </Button>
        )}
        {onDelete && (
          <Tooltip title="Delete">
            <Button
              color="error"
              variant="outlined"
              className="ml-2 h-100"
              disabled={isDeleteIconDisabled || isDeleteDisable}
              onClick={onDelete}
            >
              <DeleteIcon />
            </Button>
          </Tooltip>
        )}
        {/* <Tooltip title='Filter'>
                    <Button
                        color='primary'
                        variant='outlined'
                        className='ml-2 h-100'
                    >
                        <FilterAltIcon />
                    </Button>
                </Tooltip> */}
        {children}
      </Box>
      {searchInput && (
        <Box className="search-box">
          <SearchIcon className="search-icon" />
          <input
            className="search-input"
            name="search"
            placeholder={searchPlaceholder}
            onChange={onSearch}
          />
        </Box>
      )}
    </Box>
  );
};

Header.defaultProps = {
  className: "",
  onDelete: undefined,
  isDeleteDisable: true,
  style: {},
};
