import { httpRequest } from "../Network";

export const createCustomer = (payload: any) => new Promise(async (resolve, reject) => {
  try {
    const create = await httpRequest('POST', '/user/admin/customer', payload) as any;
    resolve(create?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateCustomer = (customerId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/customer/${customerId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateCustomerStatus = (customerId: string, payload: any) => new Promise(async (resolve, reject) => {
  try {
    const update = await httpRequest('PUT', `/user/admin/update-status-customer/${customerId}`, payload) as any;
    resolve(update?.data);
  } catch (error) {
    reject(error);
  }
});

export const deleteCustomers = (customerIds: Array<string>) => new Promise(async (resolve, reject) => {
  try {
    const deleteCustomer = await httpRequest('DELETE', `/user/admin/customer`, { userIds: customerIds }) as any;
    resolve(deleteCustomer?.data);
  } catch (error) {
    reject(error);
  }
});