import { ChangeEvent, useEffect, useState } from "react";
import "./style.scss";
import {
  Box,
  Button,
  Grid2,
  TextField,
  useTheme,
  MenuItem,
} from "@mui/material";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../constants";
import { Controller, useForm, FieldError } from "react-hook-form";
import { PhoneNumberInput } from "../../../components/mui/PhoneNumberInput";
import { upload } from "../../../services/common";
import { updateAdminProfile } from "../../../services/admin";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";

const Profile = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const userProfile = useSelector((state: any) => state.profile);
  const [coutryCode, setCountryCode] = useState("+966");

  const theme = useTheme();

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: "",
      email: "",
      prefix: "",
      number: "",
      profile: "",
      role: "",
      coverProfile: "",
      defaultLanguage: "",
    },
  });

  useEffect(() => {
    setValue("name", userProfile.name || "");
    setValue("email", userProfile.email || "");
    setValue("prefix", userProfile.phone.prefix || "");
    setValue("number", userProfile.phone.number || "");
    setValue("profile", userProfile.profile || "");
    setValue("role", userProfile.type || "");
    setValue("coverProfile", userProfile.coverProfile || "");
    setValue("defaultLanguage", userProfile.defaultLanguage || "ar");
    setCountryCode(`+${userProfile.phone.prefix}`);
    setProfileImageUrl(userProfile.profile);
  }, [userProfile, setValue]);

  const [profileImageUrl, setProfileImageUrl] = useState(
    userProfile?.profile || ""
  );

  const uploadImage = async (e: ChangeEvent<HTMLInputElement> | any) => {
    try {
      const files = e.target.files;

      const maxSize = 3 * 1024 * 1024; // 3MB
      const maxDimension = 1024; // Max width/height in pixels

      if (!files || !files[0]) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile, {
          variant: "error",
        });
        return;
      }

      const fileSize = files[0].size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan3MB, {
          variant: "error",
        });
        return;
      }

      // Check image dimensions
      const img = new Image();
      const objectUrl = URL.createObjectURL(files[0]);

      img.onload = async () => {
        URL.revokeObjectURL(objectUrl);

        if (img.width > maxDimension || img.height > maxDimension) {
          // Create canvas to resize image
          const canvas = document.createElement("canvas");
          let width = img.width;
          let height = img.height;

          if (width > height) {
            if (width > maxDimension) {
              height = Math.round((height * maxDimension) / width);
              width = maxDimension;
            }
          } else {
            if (height > maxDimension) {
              width = Math.round((width * maxDimension) / height);
              height = maxDimension;
            }
          }

          canvas.width = width;
          canvas.height = height;

          const ctx = canvas.getContext("2d");
          ctx?.drawImage(img, 0, 0, width, height);

          // Convert canvas to blob
          canvas.toBlob(
            async (blob) => {
              if (blob) {
                const profileUrl = await upload(blob);
                if (profileUrl) {
                  setProfileImageUrl(profileUrl);
                  setValue("profile", profileUrl);
                }
              }
            },
            "image/jpeg",
            0.9
          );
        } else {
          const profileUrl = await upload(files[0]);
          if (profileUrl) {
            setProfileImageUrl(profileUrl);
            setValue("profile", profileUrl);
          }
        }
      };

      img.src = objectUrl;
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const { name, profile, coverProfile, defaultLanguage } = getValues();

      await updateAdminProfile({
        name: name,
        profile: profile,
        coverProfile: coverProfile,
        defaultLanguage: defaultLanguage,
      });

      enqueueSnackbar(staticTexts.profileUpdatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  return (
    <form onSubmit={handleSubmit(handleUpdateProfile)}>
      <div id="profile-info">
        <Box className="center" sx={{ mb: 2 }}>
          <Box
            sx={{
              cursor: "pointer",
              position: "relative",
            }}
            component="label"
          >
            {profileImageUrl ? (
              <img
                src={profileImageUrl}
                alt="Profile"
                style={{
                  width: 100,
                  height: 100,
                  objectFit: "cover",
                  borderRadius: "50%",
                  border: `2px solid ${theme.palette.primary.main}`,
                }}
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  width: 100,
                  height: 100,
                  color: theme.palette.primary.main,
                }}
              />
            )}
            <input
              hidden
              accept="image/*"
              type="file"
              onChange={(e) => uploadImage(e)}
            />
          </Box>
        </Box>

        <Grid2 container spacing={5}>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Controller
              name="name"
              control={control}
              render={({ field }) => (
                <TextField
                  label={staticTexts.name}
                  variant="outlined"
                  {...field}
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Controller
              name="email"
              control={control}
              render={({ field }) => (
                <TextField
                  label={staticTexts.email}
                  variant="outlined"
                  {...field}
                  disabled
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Controller
              name="role"
              control={control}
              render={({ field }) => (
                <TextField
                  label={staticTexts.role}
                  {...field}
                  variant="outlined"
                  disabled
                />
              )}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <PhoneNumberInput
              name="number"
              label={staticTexts.phoneNumber}
              phoneNumber={getValues("number")}
              helperText={errors.number?.message as string}
              countryCode={coutryCode}
              control={control}
              error={errors.number as FieldError}
              handlePhoneNumberChange={() => {}}
              handleCountryCodeChange={() => {}}
              isDisabled={true}
              validationRules={{
                required: staticTexts.phoneIsRequired,
              }}
            />
          </Grid2>
          <Grid2 size={{ xs: 12, md: 6 }}>
            <Controller
              name="defaultLanguage"
              control={control}
              render={({ field }) => (
                <TextField
                  select
                  label={staticTexts.defaultLanguage}
                  variant="outlined"
                  fullWidth
                  {...field}
                >
                  <MenuItem value="ar">عربي</MenuItem>
                  <MenuItem value="en">English</MenuItem>
                </TextField>
              )}
            />
          </Grid2>
        </Grid2>
        <Box className="mt-3" textAlign="end">
          <Button type="submit" size="medium">
            {staticTexts.save}
          </Button>
        </Box>
      </div>
    </form>
  );
};

export default Profile;
