import { useState, SyntheticEvent, useEffect } from "react";
import { useNavigate, useLocation, Outlet } from "react-router-dom";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import { withPermission } from "../../components/hoc";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { useSelector } from "react-redux";

function a11yProps(index: number) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const Customers = () => {
  const [value, setValue] = useState(-1);
  const navigate = useNavigate();
  const location = useLocation();
  const userPermissions = useSelector((state: any) => state.permissions);

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const redirectionLinks = ["individual-users", "business-users"];
  const tabs = [
    {
      label: staticTexts.users,
      index: 0,
      permission: PERMISSIONS.VIEW_INDIVIDUAL_CUSTOMER,
    },
    {
      label: staticTexts.businessUsers,
      index: 1,
      permission: PERMISSIONS.VIEW_BUSINESS_CUSTOMER,
    },
  ];

  const availableTabs = tabs.filter((tab) =>
    userPermissions.includes(tab.permission)
  );

  const availableRedirectionLinks = availableTabs.map(
    (_, index) => redirectionLinks[index]
  );

  useEffect(() => {
    const currentPath = location.pathname.split("/")[2];
    const tabIndex = availableRedirectionLinks.indexOf(currentPath);

    if (tabIndex === -1 && availableRedirectionLinks.length > 0) {
      // If no valid path and there are available tabs, navigate to first available tab
      navigate(availableRedirectionLinks[0]);
    } else if (availableRedirectionLinks.length > 0) {
      setValue(tabIndex);
    }
  }, [location.pathname, navigate, availableRedirectionLinks]);

  const handleChange = (event: SyntheticEvent, newValue: number) => {
    navigate(availableRedirectionLinks[newValue]);
  };

  // if (availableTabs.length === 0) {
  //   return <p>Access Denied</p>;
  // }

  return (
    <Box width="100%">
      <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
        <Tabs
          value={value}
          onChange={handleChange}
          aria-label="basic tabs example"
        >
          {availableTabs.map((tab, key) => (
            <Tab
              key={key}
              className="tabs-space"
              label={tab.label}
              {...a11yProps(tab.index)}
            />
          ))}
        </Tabs>
      </Box>
      {value >= 0 && <Outlet />}
    </Box>
  );
};

export default Customers;
