import React, { FC } from "react";
import {
  Box,
  Typography,
  IconButton,
  useTheme,
  Grid,
  Divider,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../../constants";
import { useSelector } from "react-redux";

interface Props {
  agent: {
    name?: string;
    email?: string;
    profile?: string;
    phone?: {
      prefix?: string;
      number?: string;
    };
    status?: string;
    roleName?: string; // Added roleName field
  };
}

const Details: FC<Props> = ({ agent }) => {
  const theme = useTheme();
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const handleCopy = (text: string, type: "email" | "phone") => {
    navigator.clipboard.writeText(text);
    enqueueSnackbar(
      `${type === "email" ? staticTexts.email : staticTexts.phone} ${
        staticTexts.copied
      }!`,
      {
        variant: "success",
      }
    );
  };

  return (
    <Box
      sx={{
        padding: 3,
        borderRadius: 2,
        boxShadow: theme.shadows[3],
        backgroundColor: theme.palette.background.default,
      }}
    >
      <Grid container spacing={3} alignItems="center">
        {/* Profile Section */}
        <Grid item xs={12} md={4} sx={{ textAlign: "center" }}>
          {agent?.profile ? (
            <img
              src={agent.profile}
              alt={`${agent.name || "Agent"}'s Profile`}
              style={{
                width: 100,
                height: 100,
                objectFit: "cover",
                borderRadius: "50%",
                border: `3px solid ${theme.palette.primary.main}`,
                marginTop: theme.spacing(2),
              }}
            />
          ) : (
            <AccountCircleIcon
              sx={{
                width: 100,
                height: 100,
                color: theme.palette.primary.main,
                marginTop: theme.spacing(2),
              }}
            />
          )}
        </Grid>

        {/* Details Section */}
        <Grid item xs={12} md={8}>
          <Typography
            variant="h5"
            sx={{ fontWeight: 600, color: theme.palette.text.primary, mb: 1 }}
          >
            {agent?.name || staticTexts.noNameProvided}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <strong>{staticTexts.email}:</strong>&nbsp;
            {agent?.email || staticTexts.emailNotAvailable}
            {agent?.email && (
              <IconButton
                size="small"
                onClick={() => handleCopy(agent?.email!, "email")}
                sx={{ color: theme.palette.primary.main, ml: 1 }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            )}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
              display: "flex",
              alignItems: "center",
              mb: 1,
            }}
          >
            <strong>{staticTexts.phone}:</strong>&nbsp;
            +{agent?.phone?.prefix || ""} {agent?.phone?.number || "N/A"}
            {agent?.phone?.number && (
              <IconButton
                size="small"
                onClick={() =>
                  handleCopy(
                    `${agent?.phone?.number}`,
                    "phone"
                  )
                }
                sx={{ color: theme.palette.primary.main, ml: 1 }}
              >
                <ContentCopyIcon fontSize="small" />
              </IconButton>
            )}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color: theme.palette.text.secondary,
              mb: 1,
            }}
          >
            <strong>{staticTexts.role}:</strong>&nbsp;
            {agent?.roleName || staticTexts.roleNotAvailable}
          </Typography>

          <Typography
            variant="body1"
            sx={{
              color:
                agent?.status === "ACTIVE"
                  ? theme.palette.success.main
                  : theme.palette.error.main,
            }}
          >
            <strong>{staticTexts.status}:</strong>&nbsp;
            {agent?.status || staticTexts.unknown}
          </Typography>
        </Grid>
      </Grid>

      {/* <Divider sx={{ my: 3 }} />

      <Box>
        <Typography
          variant="body2"
          sx={{ color: theme.palette.text.secondary, fontStyle: "italic" }}
        >
          {`${staticTexts.note}: ${staticTexts.ensureAllInformationIsUpToDate}`}
        </Typography>
      </Box> */}
    </Box>
  );
};

export default Details;
