import React, { useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { useSelector } from "react-redux";
import { getStaticTexts } from "../../../constants";
import { useTheme } from "@mui/material/styles";
import { handleCharacterLimit } from "../../../services/common";

interface User {
  id: number;
  name: string;
  lastMessage: string;
  item: string;
  status: "Online" | "Offline";
  date: string;
  claimedBy: string;
}

interface ChatListProps {
  users: User[];
  onSelectUser: (user: User) => void;
  className?: string;
  handleListItemClick?: () => void;
}

const ChatList: React.FC<ChatListProps> = ({
  users,
  onSelectUser,
  className,
  handleListItemClick,
}) => {
  const language = useSelector((state: any) => state.language);
  const staticTexts = getStaticTexts(language);
  const theme = useTheme();

  return (
    <div
      className={`${className} border-r border-gray-200`}
      style={{
        width: "100%",
        // borderRight: "1px solid #776E65",
        padding: "16px",
      }}
    >
      <Typography variant="h6" className="!mb-2" fontWeight={700}>
        {staticTexts.chat}
      </Typography>

      <List>
        {(users || []).map((user: any, index: number) => (
          <ListItem
            key={`${index}`}
            sx={{
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#fff7e6",
                cursor: "pointer",
              },
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #ECE4D5",
              padding: "12px 12px 12px 0px",
            }}
            onClick={() => {
              if (handleListItemClick) handleListItemClick();
              onSelectUser(user);
            }}
          >
            {user?.claimedUserProfile ? (
              <Avatar
                src={user.claimedUserProfile}
                alt="item"
                sx={{ width: 40, height: 40 }}
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  width: 40,
                  height: 40,
                  color: theme.palette.primary.main,
                }}
              />
            )}
            <Box flex={1} marginInlineStart="16px">
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  color="#4A403A"
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                >
                  {user?.claimedUserName}
                </Typography>
                <Typography
                  variant="body2"
                  color="#B5B3B0"
                  sx={{ fontSize: "12px" }}
                >
                  {user.date}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="#6F6D6B"
                sx={{
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {handleCharacterLimit(user?.lastMessage, 60)}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ChatList;
