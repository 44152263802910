import {
  Box,
  Button,
  FormControl,
  MenuItem,
  Select,
  Tooltip,
  Typography,
} from "@mui/material";
import { getStaticTexts, PERMISSIONS } from "../../constants";
import { withPermission } from "../../components/hoc";
import { enqueueSnackbar } from "notistack";
import { ChangeEvent, useEffect, useReducer, useState } from "react";
import { getReports, updateReportStatus } from "../../services/report";
import { handleCharacterLimit } from "../../services/common";
import Loader from "../../components/Loader";
import { CustomTable, Header, WarningDialog } from "../../components";
import Manage from "./manage";
import ManageReport from "./manage-report";
import { useSelector } from "react-redux";
import { formatDate } from "../../services/utils";

const Report = () => {
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const reportReducer = (state: any, action: any) => {
    switch (action.type) {
      case "LOADING":
        return { ...state, loading: true };
      case "LOADED":
        return { ...state, loading: false, list: action.list };
      case "UPDATE_FIELDS":
        return { ...state, ...action.payload };
      case "UPDATE_STATUS":
        return { ...state, loading: true };
      default:
        throw new Error(`Unhandled action type: ${action.type}`);
    }
  };

  const [state, dispatch] = useReducer(reportReducer, {
    _report: "",
    actiontype: "",
    list: [],
    manage: {
      isOpen: false,
      type: "",
      report: null,
    },
    manageApprovalModal: {
      isOpen: false,
    },
    selectAll: [],
    loading: true,
    searchQuery: "",
    page: 1,
    statusFilter: "",
    totalPages: 1,
  });

  const userPermissions = useSelector((state: any) => state.permissions);

  const [itemsPerPage, setItemsPerPage] = useState(10);

  const isEditIconDisabled = !userPermissions.includes(PERMISSIONS.EDIT_REPORT);

  // const isDeleteIconDisabled = !userPermissions.includes(
  //   PERMISSIONS.DELETE_REPORT
  // );

  const handleManage = (
    type: "new" | "edit" | "view",
    report: any = null,
    userType: "reportedUser" | "reportedBy"
  ) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          type,
          isOpen: !state.manage.isOpen,
          report,
          userType,
        },
      },
    });
  };

  const handleWarningDialog = (_report: string, status: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manage: {
          ...state.manage,
          isOpen: false,
          report: null,
          userType: "",
        },
        resolveWarning: !state.resolveWarning,
        _report,
        status,
      },
    });
  };

  const fetchReports = async (searchQuery: string = "", page: number = 1) => {
    try {
      const params: any = {
        page,
        limit: itemsPerPage,
        text: searchQuery,
      };

      if (state.statusFilter !== "ALL") {
        params.status = state.statusFilter;
      }

      const reports = (await getReports(params)) as any;

      if (!reports || !reports.reports?.length) {
        dispatch({ type: "LOADED", list: [] });
        return;
      }

      dispatch({
        type: "UPDATE_FIELDS",
        payload: { totalPages: reports.meta.totalPages },
      });

      const list = reports.reports.map((report: any, index: number) => {
        return createRow(
          report,
          index,
          handleManage,
          handleWarningDialog,
          page
        );
      });

      dispatch({ type: "LOADED", list });
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const onPageChange = (e: ChangeEvent<unknown>, page: number) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: { page },
    });
    fetchReports(state.searchQuery, page);
  };

  useEffect(() => {
    fetchReports(state.searchQuery, state.page);
  }, [state.statusFilter]);

  const handleStatusChange = async (
    id: string,
    status: string,
    remark?: string
  ) => {
    try {
      await updateReportStatus(id, { status, remark });
      dispatch({ type: "UPDATE_STATUS", _id: id, status, remark });
      enqueueSnackbar(staticTexts.reportStatusUpdatedSuccessfully, {
        variant: "success",
      });
      fetchReports(state.searchQuery, state.page);
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleResolve = async (reportId: string, status: string) => {
    try {
      await updateReportStatus(reportId, { status });
      enqueueSnackbar(staticTexts.itemResolvedSuccessfully, {
        variant: "success",
      });
      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const onManageApprovalClose = () => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manageApprovalModal: {
          isOpen: false,
        },
        _report: "",
      },
    });
  };

  const handleManageApproval = (reportId: string, actionType: string) => {
    dispatch({
      type: "UPDATE_FIELDS",
      payload: {
        manageApprovalModal: {
          ...state.manageApprovalModal,
          isOpen: true,
        },
        _report: reportId,
        actionType,
      },
    });
  };

  const columns = [
    {
      id: "id",
      label: staticTexts.sNo,
    },
    {
      id: "reportedUser",
      label: staticTexts.reportedUser,
    },
    {
      id: "reportedBy",
      label: staticTexts.reportedBy,
    },
    {
      id: "comment",
      label: staticTexts.comment,
    },
    {
      id: "createdAt",
      label: staticTexts.createdAt,
    },
    {
      id: "action",
      label: staticTexts.action,
    },
    {
      id: "remark",
      label: staticTexts.remark,
    },
  ];

  const statusFilter = [
    {
      label: staticTexts.all,
      value: "ALL",
    },
    {
      label: staticTexts.pending,
      value: "PENDING",
    },
    {
      label: staticTexts.resolved,
      value: "RESOLVED",
    },
    {
      label: staticTexts.rejected,
      value: "REJECTED",
    },
  ];

  const createRow = (
    report: any,
    index: number,
    onEdit: any,
    handleWarningDialog: any,
    page: number
  ) => {
    const reportedUser = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => onEdit("view", report, "reportedUser")}
        sx={{ textTransform: "capitalize" }}
      >
        {report._reportedUser?.name}
      </Typography>
    );

    const reportedBy = (
      <Typography
        className="cursor-pointer"
        color="primary"
        variant="body1"
        onClick={() => onEdit("view", report, "reportedBy")}
        sx={{ textTransform: "capitalize" }}
      >
        {report?._reportedBy?.name}
      </Typography>
    );

    const comment = (
      <Typography variant="body2">
        {handleCharacterLimit(report.comment || "", 30)}
      </Typography>
    );

    const remark = (
      <Typography variant="body2">
        {handleCharacterLimit(report.remark || "", 30) || "NA"}
      </Typography>
    );

    const action = (
      <>
        {report.status === "PENDING" && (
          <Box sx={{ display: "flex", gap: 1 }}>
            <Tooltip title={staticTexts.resolve}>
              <Button
                variant="outlined"
                color="primary"
                disabled={isEditIconDisabled}
                size="small"
                sx={{
                  minWidth: "auto",
                  padding: "4px 8px",
                  textTransform: "capitalize",
                }}
                onClick={() => handleManageApproval(report._id, "RESOLVED")}
              >
                {staticTexts.resolve}
              </Button>
            </Tooltip>

            <Tooltip title={staticTexts.reject}>
              <Button
                variant="outlined"
                color="error"
                size="small"
                sx={{
                  minWidth: "auto",
                  padding: "4px 8px",
                  textTransform: "capitalize",
                }}
                onClick={() => handleManageApproval(report._id, "REJECTED")}
              >
                {staticTexts.reject}
              </Button>
            </Tooltip>
          </Box>
        )}

        {report.status === "REJECTED" && (
          <Typography
            variant="body2"
            sx={{
              color: "error.main",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {staticTexts.rejected}
          </Typography>
        )}

        {report.status === "RESOLVED" && (
          <Typography
            variant="body2"
            sx={{
              color: "success.main",
              fontWeight: "bold",
              textTransform: "uppercase",
            }}
          >
            {staticTexts.resolved}
          </Typography>
        )}
      </>
    );

    return {
      id: (page - 1) * itemsPerPage + index + 1,
      reportedUser,
      reportedBy,
      comment,
      createdAt: formatDate(report.createdAt),
      action,
      remark,
    };
  };

  return (
    <>
      {state.loading && <Loader />}
      <Header>
        <FormControl className="ml-2" size="small">
          <Select
            size="small"
            displayEmpty
            value={state.statusFilter}
            renderValue={(selected) => {
              if (!selected || selected === "") {
                return staticTexts.statusFilter;
              }
              if (selected === "ALL") {
                return staticTexts.allStatus;
              }
              return selected === "ALL"
                ? staticTexts.allStatus
                : selected === "PENDING"
                ? staticTexts.pending
                : staticTexts.resolved;
            }}
            onChange={(e) => {
              dispatch({
                type: "UPDATE_FIELDS",
                payload: { statusFilter: e.target.value, page : 1 },
              });
            }}
          >
            {statusFilter.map((filter) => (
              <MenuItem key={filter.value} value={filter.value}>
                {filter.label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Header>

      {/* Show Data  */}
      <Box marginTop="10px">
        <CustomTable
          columns={columns}
          rows={state.list}
          height="calc(100vh - 193px)"
          errorMessage={staticTexts.noReportsFound}
          pagination={{
            page: state.page,
            totalPages: state.totalPages,
          }}
          onPageChange={onPageChange}
        />
      </Box>

      <Manage
        isOpen={state.manage.isOpen}
        type={state.manage.type}
        report={state.manage.report}
        userType={state.manage.userType}
        onClose={() =>
          handleManage(
            state.manage.type,
            state.manage.report,
            state.manage.userType
          )
        }
      />

      <ManageReport
        isOpen={state.manageApprovalModal.isOpen}
        actionType={state.actionType}
        onClose={onManageApprovalClose}
        reportId={state._report}
        updateStatusReport={handleStatusChange}
      />

      <WarningDialog
        isOpen={state.resolveWarning}
        onClose={() => handleWarningDialog(state._report || "", state.status)}
        onConfirm={() => handleResolve(state._report, state.status)}
        title={
          state.status === "RESOLVED"
            ? staticTexts.resolveReport
            : staticTexts.rejectReport
        }
        description={
          state.status === "RESOLVED"
            ? staticTexts.resolveReportDescription
            : staticTexts.rejectReportDescription
        }
      />
    </>
  );
};

export default withPermission(Report, PERMISSIONS.VIEW_REPORT);
