import { SET_CATEGORY_DETAILS } from '../../actions';

const initialState: any = [];

export const categoryReducer = (state = initialState, action: any) => {
  switch (action.type) {
    case SET_CATEGORY_DETAILS:
      return action.payload;
  }
  return state;
};
