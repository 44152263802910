import { FC, ChangeEvent, useEffect, useState } from "react";
import { CustomDialog } from "../../../../components";
import {
  Box,
  FormControl,
  Grid2,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  useTheme,
} from "@mui/material";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import EditIcon from "@mui/icons-material/Edit";
import { Controller, FieldError, useForm } from "react-hook-form";
import { enqueueSnackbar } from "notistack";
import { getStaticTexts } from "../../../../constants";
import {
  createAgent,
  getRoles,
  updateAgent,
} from "../../../../services/access-control";
import { upload } from "../../../../services/common";
import { PhoneNumberInput } from "../../../../components/mui/PhoneNumberInput";
import Details from "../details";
import { useSelector } from "react-redux";
import { stat } from "node:fs/promises";
import Loader from "../../../../components/Loader";

interface props {
  isOpen: boolean;
  type: "new" | "edit" | "view";
  isDisabled?: boolean;
  agent: any;
  onClose: () => void;
  onSave: () => void;
}

const Manage: FC<props> = ({ isOpen, type, agent, onClose, onSave }) => {
  const isDisabled = type === "view" ? true : false;
  const theme = useTheme();

  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);

  const [loading, setLoading] = useState(false);

  const [profileImageUrl, setProfileImageUrl] = useState(agent?.profile || "");
  const [countryCode, setCountryCode] = useState(
    `+${agent?.phone?.prefix}` || "+971"
  );

  const {
    control,
    handleSubmit,
    getValues,
    setValue,
    formState: { errors },
  } = useForm({
    defaultValues: {
      agent: {},
      name: "",
      email: "",
      phone: "",
      prefix: "",
      profile: "",
      password: "",
      confirmPassword: "",
      role: "",
      roles: [],
    },
  });

  const handleSave = () => {
    onSave();
    onClose();
  };

  const fetchRoles = async () => {
    try {
      const roles = (await getRoles({ limit: 50 })) as any;
      setValue("roles", roles.roles);
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  useEffect(() => {
    fetchRoles();
  }, []);

  useEffect(() => {
    if (type === "view" || type === "edit") {
      setValue("agent", agent);
      setValue("prefix", `+${agent?.phone?.prefix}`);
      setValue("phone", agent?.phone?.number);
      setValue("name", agent?.name);
      setValue("email", agent?.email);
      setValue("profile", agent?.profile);
      setValue("role", agent?._role || "");
      setCountryCode(`+${agent?.phone?.prefix}`);
      setProfileImageUrl(agent?.profile);
    }
  }, [type, agent]);

  const uploadImage = async (e: ChangeEvent<HTMLInputElement> | any) => {
    try {
      setLoading(true);
      const files = e.target.files;

      const maxSize = 3 * 1024 * 1024; // 3MB
      const maxDimension = 1024; // Max width/height in pixels

      if (!files || !files[0]) {
        enqueueSnackbar(staticTexts.pleaseSelectAnImageFile, {
          variant: "error",
        });
        setLoading(false);
        return;
      }

      const fileSize = files[0].size;
      if (fileSize > maxSize) {
        enqueueSnackbar(staticTexts.fileSizeShouldBeLessThan3MB, {
          variant: "error",
        });
        setLoading(false);
        return;
      }

      // Check image dimensions
      const img = new Image();
      const objectUrl = URL.createObjectURL(files[0]);

      img.onload = async () => {
        try {
          URL.revokeObjectURL(objectUrl);

          if (img.width > maxDimension || img.height > maxDimension) {
            // Create canvas to resize image
            const canvas = document.createElement("canvas");
            let width = img.width;
            let height = img.height;

            if (width > height) {
              if (width > maxDimension) {
                height = Math.round((height * maxDimension) / width);
                width = maxDimension;
              }
            } else {
              if (height > maxDimension) {
                width = Math.round((width * maxDimension) / height);
                height = maxDimension;
              }
            }

            canvas.width = width;
            canvas.height = height;

            const ctx = canvas.getContext("2d");
            ctx?.drawImage(img, 0, 0, width, height);

            // Convert canvas to blob
            await new Promise((resolve, reject) => {
              canvas.toBlob(
                async (blob) => {
                  try {
                    if (blob) {
                      const profileUrl = await upload(blob);
                      if (profileUrl) {
                        setProfileImageUrl(profileUrl);
                        setValue("profile", profileUrl);
                      }
                      resolve(true);
                    }
                  } catch (error) {
                    reject(error);
                  }
                },
                "image/jpeg",
                0.9
              );
            });
          } else {
            const profileUrl = await upload(files[0]);
            if (profileUrl) {
              setProfileImageUrl(profileUrl);
              setValue("profile", profileUrl);
            }
          }
        } catch (error) {
          throw error;
        } finally {
          setLoading(false);
        }
      };

      img.src = objectUrl;
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
      setLoading(false);
    }
  };

  const handleCreateAgent = async () => {
    try {
      const payload = {
        name: getValues("name"),
        email: getValues("email"),
        phone: {
          prefix: getValues("prefix").replace(/^\++/, ""),
          number: getValues("phone"),
        },
        profile: profileImageUrl,
        password: getValues("password"),
        confirmPassword: getValues("confirmPassword"),
        _role: getValues("role"),
      };

      await createAgent(payload);

      enqueueSnackbar(staticTexts.agentCreatedSuccessfully, {
        variant: "success",
      });

      window.location.reload();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleUpdateProfile = async () => {
    try {
      const payload = {
        name: getValues("name"),
        email: getValues("email"),
        phone: {
          prefix: getValues("prefix").replace(/^\++/, ""),
          number: getValues("phone"),
        },
        profile: profileImageUrl,
        roleId: getValues("role"),
      };

      await updateAgent(agent._id, payload);

      enqueueSnackbar(staticTexts.agentProfileUpdatedSuccessfully, {
        variant: "success",
      });

      handleSave();
    } catch (error: any) {
      enqueueSnackbar(
        error.response.data.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        {
          variant: "error",
        }
      );
    }
  };

  const handleSubmitButton = async () => {
    try {
      if (type === "new") {
        await handleCreateAgent();
      } else {
        await handleUpdateProfile();
      }
    } catch (error: any) {
      enqueueSnackbar(
        error.response?.data?.message ||
          error.message ||
          staticTexts.somethingWentWrong,
        { variant: "error" }
      );
    }
  };

  return (
    <>
      {loading && <Loader />}
      <form onSubmit={handleSubmit(handleSubmitButton)}>
        <CustomDialog
          title={staticTexts.agentDetail}
          isOpen={isOpen}
          onClose={onClose}
          onConfirm={handleSubmit(handleSubmitButton)}
          disabled={isDisabled}
        >
          <Box>
            {type === "view" && <Details agent={agent} />}
            {(type === "edit" || type === "new") && (
              <>
                <Grid2 container spacing={4}>
                  <Grid2 size={{ xs: 12, md: 12 }}>
                    <Box className="center" sx={{ mb: 2 }}>
                      <Box
                        sx={{
                          cursor: "pointer",
                          position: "relative",
                          width: 100,
                          height: 100,
                          borderRadius: "50%",
                          overflow: "hidden",
                        }}
                        component="label"
                      >
                        {profileImageUrl ? (
                          <>
                            <img
                              src={profileImageUrl}
                              alt="Profile"
                              style={{
                                width: "100%",
                                height: "100%",
                                objectFit: "cover",
                              }}
                            />
                            <div
                              style={{
                                position: "absolute",
                                bottom: 0,
                                width: "100%",
                                backgroundColor: "rgba(0, 0, 0, 0.5)",
                                color: "white",
                                textAlign: "center",
                                fontSize: "12px",
                                padding: "4px 0",
                              }}
                            >
                              Edit
                            </div>
                          </>
                        ) : (
                          <AccountCircleIcon
                            sx={{
                              width: 100,
                              height: 100,
                              color: theme.palette.primary.main,
                            }}
                          />
                        )}
                        <input
                          hidden
                          accept="image/*"
                          type="file"
                          onChange={(e) => uploadImage(e)}
                        />
                      </Box>
                    </Box>
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="name"
                      control={control}
                      rules={{
                        required: staticTexts.nameIsRequired,
                      }}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.name}
                          error={!!errors.name}
                          helperText={errors.name?.message || " "}
                          {...field}
                          sx={{ marginBottom: "-30px",
                            "& .MuiFormHelperText-root": {
                              marginBottom: "8px",
                            },
                           }}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="email"
                      control={control}
                      rules={{
                        required: staticTexts.emailIsRequired,
                      }}
                      render={({ field }) => (
                        <TextField
                          label={staticTexts.email}
                          error={!!errors.email}
                          helperText={errors.email?.message || " "}
                          sx={{ marginBottom: "-30px" }}
                          {...field}
                          disabled={isDisabled}
                        />
                      )}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <PhoneNumberInput
                      name="phone"
                      label={staticTexts.phone}
                      helperText={errors.phone?.message as string}
                      countryCode={countryCode}
                      control={control}
                      error={errors.phone as FieldError}
                      handleCountryCodeChange={(e) => {
                        setValue("prefix", e.target.value);
                        setCountryCode(e.target.value);
                      }}
                      validationRules={{
                        required: staticTexts.phoneIsRequired,
                      }}
                    />
                  </Grid2>
                  <Grid2 size={{ xs: 12, md: 6 }}>
                    <Controller
                      name="role"
                      control={control}
                      rules={{
                        required: staticTexts.roleIsRequired,
                      }}
                      render={({ field }) => (
                        <FormControl
                          fullWidth
                          error={!!errors.role}
                          sx={{ marginBottom: "-30px" }}
                        >
                          <InputLabel sx={{ top: "-6px" }}>
                            {staticTexts.role}
                          </InputLabel>
                          <Select
                            {...field}
                            label={staticTexts.role}
                            disabled={isDisabled}
                            sx={{
                              marginRight: "8px",
                              maxHeight: "150px",
                              "& .MuiSelect-select": {
                                paddingTop: "8px",
                                color: "#4A403A",
                                fontSize: "14px",
                              },
                            }}
                            MenuProps={{
                              PaperProps: {
                                style: {
                                  maxHeight: "200px",
                                  overflowY: "auto",
                                },
                              },
                            }}
                          >
                            <MenuItem disabled value="">
                              {staticTexts.selectRole}
                            </MenuItem>
                            {getValues("roles")?.map((roleItem: any) => (
                              <MenuItem key={roleItem._id} value={roleItem._id}>
                                {roleItem.name}
                              </MenuItem>
                            ))}
                          </Select>
                        </FormControl>
                      )}
                    />
                  </Grid2>
                  {type === "new" && (
                    <>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <Controller
                          name="password"
                          control={control}
                          rules={{
                            required: staticTexts.passwordIsRequired,
                            minLength: {
                              value: 8,
                              message:
                                staticTexts.passwordMustBeAtLeast8Characters,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              label={staticTexts.password}
                              type="password"
                              error={!!errors.password}
                              helperText={errors.password?.message || " "}
                              sx={{ marginBottom: "-30px" }}
                              {...field}
                              disabled={isDisabled}
                            />
                          )}
                        />
                      </Grid2>
                      <Grid2 size={{ xs: 12, md: 6 }}>
                        <Controller
                          name="confirmPassword"
                          control={control}
                          rules={{
                            required: staticTexts.confirmPasswordIsRequired,
                            minLength: {
                              value: 8,
                              message:
                                staticTexts.passwordMustBeAtLeast8Characters,
                            },
                          }}
                          render={({ field }) => (
                            <TextField
                              label={staticTexts.confirmPassword}
                              type="password"
                              error={!!errors.confirmPassword}
                              helperText={
                                errors.confirmPassword?.message || " "
                              }
                              {...field}
                              disabled={isDisabled}
                            />
                          )}
                        />
                      </Grid2>
                    </>
                  )}
                </Grid2>
              </>
            )}
          </Box>
        </CustomDialog>
      </form>
    </>
  );
};

export default Manage;
