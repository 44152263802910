import { httpRequest } from "../Network";

export const getUserProfile = async () => new Promise(async (resolve, reject) => {

  try {
    const admin = await httpRequest("GET", "/user/admin/profile") as any;
    resolve(admin?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateAdminProfile = async (payload: any) => new Promise(async (resolve, reject) => {

  try {
    const admin = await httpRequest("PUT", "/user/admin/profile", payload) as any;
    resolve(admin?.data);
  } catch (error) {
    reject(error);
  }
});

export const updateConfiguration = async (payload: any) => new Promise(async (resolve, reject) => {

  try {
    const admin = await httpRequest("PUT", "/user/admin/configuration", payload) as any;
    resolve(admin?.data);
  } catch (error) {
    reject(error);
  }
});