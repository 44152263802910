import React, { Fragment, useEffect, useState } from "react";
import {
  Avatar,
  Box,
  List,
  ListItem,
  Typography,
  IconButton,
  Menu,
  MenuItem,
  TextField,
  useTheme,
} from "@mui/material";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { useSelector } from "react-redux";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { getStaticTexts } from "../../../constants";
import { formatDate } from "../../../services/utils";
import { handleCharacterLimit } from "../../../services/common";
interface User {
  id: number;
  name: string;
  lastMessage: string;
  item: string;
  status: "Online" | "Offline";
  date: string;
  claimedBy: string;
}

interface ClaimListProps {
  claims: any[];
  onSelectUser: (user: User) => void;
  className?: string;
  handleListItemClick?: () => void;
}

const ClaimList: React.FC<ClaimListProps> = ({
  claims,
  onSelectUser,
  className,
  handleListItemClick,
}) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const userList = claims.map((claim: any) => claim._userClaimBy);
  const language = useSelector((state: any) => state.profile.defaultLanguage);
  const staticTexts = getStaticTexts(language);
  const theme = useTheme();

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleDeleteChat = () => {
    // Your delete chat logic here
    console.log("Chat deleted");
    handleClose();
  };

  return (
    <div
      className={`${className} border-r border-gray-200`}
      style={{
        width: "100%",
        borderRight: "1px solid #776E65",
        paddingTop: "16px",
      }}
    >
      <Typography variant="h6" className="!mb-2" fontWeight={700}>
        {staticTexts.claimedUsers}
      </Typography>

      <List>
        {(userList || []).map((user: any, index: number) => (
          <ListItem
            key={index}
            sx={{
              borderRadius: "4px",
              "&:hover": {
                backgroundColor: "#fff7e6",
                cursor: "pointer",
              },
              display: "flex",
              alignItems: "center",
              borderBottom: "1px solid #ECE4D5",
              padding: "12px 12px 12px 0px",
            }}
            onClick={handleListItemClick}
          >
            {user?.profile ? (
              <img
                src={user?.profile}
                alt="Profile"
                style={{
                  width: 35,
                  height: 35,
                  objectFit: "cover",
                  borderRadius: "50%",
                }}
              />
            ) : (
              <AccountCircleIcon
                sx={{
                  width: 40,
                  height: 40,
                  color: theme.palette.primary.main,
                }}
              />
            )}
            <Box
              flex={1}
              marginInlineStart="16px"
              onClick={() => onSelectUser(user)}
            >
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  color="#4A403A"
                  sx={{ fontSize: "14px", fontWeight: "500" }}
                >
                  {user.name}
                </Typography>
                <Typography
                  variant="body2"
                  color="#B5B3B0"
                  sx={{ fontSize: "12px", marginRight: "13px" }}
                >
                  {formatDate(claims[index].createdAt)}
                </Typography>
              </Box>
              <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
              >
                <Typography
                  variant="body2"
                  color="#6F6D6B"
                  sx={{
                    fontSize: "12px",
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    whiteSpace: "nowrap",
                  }}
                >
                  {handleCharacterLimit(claims[index].description, 50)}
                </Typography>
              </Box>
              <Typography
                variant="body2"
                color="#6F6D6B"
                sx={{
                  fontSize: "12px",
                  overflow: "hidden",
                  textOverflow: "ellipsis",
                  whiteSpace: "nowrap",
                }}
              >
                {user.lastMessage}
              </Typography>
            </Box>
          </ListItem>
        ))}
      </List>
    </div>
  );
};

export default ClaimList;
